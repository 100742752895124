export interface ProposedRecipientDto {
  recipients: Recipient[]
}

export interface Recipient {
  userId: string
  firstName: string
  lastName: string
  emails: string[]
  orgName: string
  titleAndGrantStatusList: TitleAndGrantStatus[]
}

export interface TitleAndGrantStatus {
  title: string
  shortTitle: string
  grantStatus: string
}

export interface TimeSlot {
  start?: string
  end?: string
}

export interface Invitee {
  userId: string
  firstName: string
  lastName: string
  emails: string[]
  orgName: string
  recipient?: boolean
  titleAndGrantStatusList: TitleAndGrantStatus[]
}

interface Attendee {
  userId: string
  name: string
}

export interface OorTitle {
  title?: string
  shortTitle?: string
  elfCode?: string
  country?: string
}

export enum RecipientStatus {
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETED',
  REJECTED = 'REJECTED'
}
