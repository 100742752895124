/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * User proposed for role. If user is onboarded, userId can be set. If it is unknown or user is not onboarded, email should be set. The user will be invited to onboard
 * @export
 * @interface ProposedUserDto
 */
export interface ProposedUserDto {
    /**
     * User Id
     * @type {string}
     * @memberof ProposedUserDto
     */
    userId?: string;
    /**
     * Country code of user's telephone number
     * @type {string}
     * @memberof ProposedUserDto
     */
    countryCode?: string;
    /**
     * User's telephone number without country code
     * @type {string}
     * @memberof ProposedUserDto
     */
    phoneNumber?: string;
    /**
     * User's email. Used to define users, send notifications and invite to onboard and take part in the Workflow
     * @type {string}
     * @memberof ProposedUserDto
     */
    email?: string;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof ProposedUserDto
     */
    firstName?: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof ProposedUserDto
     */
    lastName?: string;
}

/**
 * Check if a given object implements the ProposedUserDto interface.
 */
export function instanceOfProposedUserDto(value: object): boolean {
    return true;
}

export function ProposedUserDtoFromJSON(json: any): ProposedUserDto {
    return ProposedUserDtoFromJSONTyped(json, false);
}

export function ProposedUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposedUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
    };
}

export function ProposedUserDtoToJSON(value?: ProposedUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'countryCode': value['countryCode'],
        'phoneNumber': value['phoneNumber'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
    };
}

