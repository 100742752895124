import CoOrdinatorStatus from '@/enums/coordinatorStatus'
import DocVerifiedStatus from '@/enums/docVerifiedStatus'
import SchedulingStatus from '@/enums/schedulingStatus'
import TaskStatus from '@/enums/taskStatus'
import { StateCreator } from 'zustand'
import type { ResearchGleifResponseDto } from '@/api/origin-org-svc'
import {
  type UserPhoneNumberDto,
  WorkflowInstanceDto
} from '@/api/origin-workflow-svc'
import { commonWorkflowService } from '@/utils/apis'
import { IStore } from '@/state/store'

export interface Doc {
  assignedTaskID?: string
  id?: string
  lei?: string
  legalCompanyName?: string
  dbaOrBrandName?: string
  legalEntityName?: string
  website?: string
  altBusiness?: string
  address?: string
  city?: string
  country?: string
  state?: string
  postalCode?: string
  ein?: string
  contactPhone?: string
  contactEmail?: string
  stockExchange?: string
  stockSymbol?: string
  vertical_served?: string
  publicRecordLinks?: string[]
  status: DocVerifiedStatus
  parameters?: {
    [key: string]: string | null
  }
}

interface UserInfo {
  userId?: string
  firstName?: string
  lastName?: string
  middleName?: string
  email?: string
  orgName?: string
  role?: string
  phoneNumber?: string
  countryCode?: string
}

interface IRepresentativeError {
  firstName?: string
  lastName?: string
  middleName?: string
  email?: string
  orgName?: string
  role?: string
  phoneNumber?: string
  countryCode?: string
}
export interface AttendeesInfo {
  id: string
  name: string
  selectedSlots?: string[]
  status?: SchedulingStatus
}
export interface Representative {
  userId?: string
  status: CoOrdinatorStatus
  userInfo: UserInfo
  owner?: boolean
  editable?: boolean
  fetched?: boolean
  error?: IRepresentativeError | null
}

export enum SELECT_COORDINATOR_OPTIONS {
  DM = 'DM',
  DO = 'DO'
}

export interface OrganizationType {
  value: string
}

export interface Coordinator {
  assignedTaskID?: string
  selectedCoOrdinator: SELECT_COORDINATOR_OPTIONS | null
  status: TaskStatus
  userInfo: UserInfo
}
export interface IdentityVerification {
  assignedTaskID?: string
  status: TaskStatus
  userInfo: UserInfo
  isDocReady: boolean
}

export interface QarOrgVerification {
  assignedTaskID?: string
  status: TaskStatus
  userInfo: UserInfo
  isDocReady: boolean
}

interface VLEI {
  workflow: WorkflowInstanceDto
  orgId?: string
  workflowInstanceId?: string
  intro: {
    assignedTaskID?: string
    status?: TaskStatus
  } | null
  doc: ResearchGleifResponseDto
  coordinator: Coordinator
  identityVerification: IdentityVerification
  qarOrgVerification: QarOrgVerification
  representatives: {
    status: TaskStatus
    assignedTaskID?: string
    items: Representative[]
  }
  scheduling: {
    status: TaskStatus
    assignedTaskID?: string
    qarTimeSlot: {
      start: string
      end: string
    }[]
    attendees: AttendeesInfo[]
    ceremonyTime?: string
  }
  phoneNumberUsedInIdentityVerification: UserPhoneNumberDto
}

export interface IVLEIStore {
  vlei: VLEI
  resetVleiState?: () => void
  setOrgID?: (id: string) => void
  setWorkflowInstanceID?: (id: string) => void
  setWorkflow?: (workflow: WorkflowInstanceDto) => void
  setTaskIDs?: (
    introTaskID: string,
    orgDocumentationTaskID: string,
    coordinatorTaskID: string,
    qarOrgVerificationTaskID?: string,
    identityVerificationTaskID?: string,
    representativesTaskID?: string,
    issuanceCeremonyTaskID?: string
  ) => void
  setTasksStatus?: (
    introTaskStatus: TaskStatus,
    orgDocumentationTaskStatus: DocVerifiedStatus,
    coordinatorTaskStatus: TaskStatus,
    qarOrgVerificationTaskStatus: TaskStatus,
    idVerificationTaskStatus: TaskStatus,
    representativesStatus: TaskStatus,
    issuanceCeremonyStatus: TaskStatus
  ) => void
  setDocData?: (data: ResearchGleifResponseDto) => void
  setDocVerifiedStatus?: (status: DocVerifiedStatus) => void
  setCoordinator?: (coordinator: Coordinator) => void
  setSelectedCoOrdinator?: (
    selectedCoOrdinator: SELECT_COORDINATOR_OPTIONS
  ) => void
  setIdentityVerificationDocReady?: (checked: boolean) => void
  setCoordinatorStatus?: (status: TaskStatus) => void
  setIdentityVerificationStatus?: (status: TaskStatus) => void
  setIdentityVerificationUserInfo?: (
    phoneNumber: string,
    countryCode: string,
    email: string
  ) => void
  setUserInfo?: (userInfo: UserInfo) => void
  setRepresentatives?: (items: Representative[]) => void
  setPublicRecords?: (publicRecords: string[]) => void
  setQarTimeSlot?: (qarTimeSlot: { start: string; end: string }[]) => void
  setCeremonyTime?: (ceremonyTime: string) => void
  setIssuanceCeremonyStatus?: (status: TaskStatus) => void
  setAttendeesSelectedSlotsByID?: (id: string, selectedSlots: string[]) => void
  setAttendees?: (attendees: AttendeesInfo[]) => void
  updatePhoneNumberUsedInIdentityVerification: (
    workflowId: string,
    userId: string
  ) => void
  resetVleiStore: () => void
}

// Initial state
const initialState: IVLEIStore = {
  vlei: {
    workflow: null,
    intro: null,
    org: null,
    doc: {
      id: '',
      lei: '',
      legalCompanyName: '',
      // todo talk with BE team to update swagger docs
      // @ts-ignore
      publicRecordLinks: [],
      status: DocVerifiedStatus.NOT_STARTED
    },
    coordinator: {
      selectedCoOrdinator: SELECT_COORDINATOR_OPTIONS.DM,
      status: TaskStatus.NOT_STARTED,
      userInfo: {
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        orgName: '',
        role: '',
        phoneNumber: ''
      }
    },
    qarOrgVerification: {
      status: TaskStatus.ASSIGNED,
      userInfo: {
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        orgName: '',
        role: '',
        phoneNumber: ''
      },
      isDocReady: false
    },
    identityVerification: {
      status: TaskStatus.ASSIGNED,
      userInfo: {
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        orgName: '',
        role: '',
        phoneNumber: ''
      },
      isDocReady: false
    },
    representatives: {
      status: TaskStatus.ASSIGNED,
      items: []
    },
    scheduling: {
      status: TaskStatus.ASSIGNED,
      qarTimeSlot: [],
      attendees: []
    },
    phoneNumberUsedInIdentityVerification: null
  }
}

const vleiStore: StateCreator<IStore, [], [], IVLEIStore> = (set, get) => ({
  ...initialState,
  // Actions
  resetVleiState: () =>
    set(() => ({
      vlei: initialState.vlei
    })),
  setOrgID: (orgId) => {
    set((state) => ({
      vlei: { ...state.vlei, orgId }
    }))
  },
  setWorkflowInstanceID: (id: string) =>
    set((state) => ({
      vlei: { ...state.vlei, workflowInstanceId: id }
    })),
  setTaskIDs: (
    introTaskID: string,
    orgDocumentationTaskID: string,
    coordinatorTaskID: string,
    qarOrgVerificationTaskID: string,
    identityVerificationTaskID?: string,
    representativesTaskID?: string,
    issuanceCeremonyTaskID?: string
  ) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        intro: { ...state.vlei.intro, assignedTaskID: introTaskID },
        doc: { ...state.vlei.doc, assignedTaskID: orgDocumentationTaskID },
        coordinator: {
          ...state.vlei.coordinator,
          assignedTaskID: coordinatorTaskID
        },
        qarOrgVerification: {
          ...state.vlei.qarOrgVerification,
          assignedTaskID: qarOrgVerificationTaskID
        },
        identityVerification: {
          ...state.vlei.identityVerification,
          assignedTaskID: identityVerificationTaskID
        },
        representatives: {
          ...state.vlei.representatives,
          assignedTaskID: representativesTaskID
        },
        scheduling: {
          ...state.vlei.scheduling,
          assignedTaskID: issuanceCeremonyTaskID
        }
      }
    })),
  setTasksStatus: (
    introTaskStatus: TaskStatus,
    orgDocumentationTaskStatus: DocVerifiedStatus,
    coordinatorTaskStatus: TaskStatus,
    qarOrgVerificationTaskStatus: TaskStatus,
    idVerificationTaskStatus: TaskStatus,
    representativesStatus: TaskStatus,
    issuanceCeremonyStatus: TaskStatus
  ) => {
    return set((state) => ({
      vlei: {
        ...state.vlei,
        intro: { ...state.vlei.intro, status: introTaskStatus },
        doc: {
          ...state.vlei.doc,
          status: orgDocumentationTaskStatus
        },
        coordinator: {
          ...state.vlei.coordinator,
          status: coordinatorTaskStatus
        },
        identityVerification: {
          ...state.vlei.identityVerification,
          status: idVerificationTaskStatus || TaskStatus.ASSIGNED
        },
        qarOrgVerification: {
          ...state.vlei.qarOrgVerification,
          status: qarOrgVerificationTaskStatus || TaskStatus.ASSIGNED
        },
        representatives: {
          ...state.vlei.representatives,
          status: representativesStatus || TaskStatus.ASSIGNED
        },
        issuanceCeremonyStatus: {
          ...state.vlei.scheduling,
          status: issuanceCeremonyStatus || TaskStatus.ASSIGNED
        }
      }
    }))
  },
  setDocData: (data) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        doc: { ...state.vlei.doc, ...data }
      }
    })),
  setDocVerifiedStatus: (status: DocVerifiedStatus) =>
    set((state) => ({
      vlei: { ...state.vlei, doc: { ...state.vlei.doc, doc_verified: status } }
    })),
  setCoordinator: (coordinator: Coordinator) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        coordinator: {
          ...state.vlei.coordinator,
          ...coordinator
        }
      }
    })),
  setSelectedCoOrdinator: (selectedCoOrdinator: SELECT_COORDINATOR_OPTIONS) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        coordinator: { ...state.vlei.coordinator, selectedCoOrdinator }
      }
    })),
  setIdentityVerificationDocReady: (checked: boolean) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        identityVerification: {
          ...state.vlei.identityVerification,
          isDocReady: checked
        }
      }
    })),
  setCoordinatorStatus: (status: TaskStatus) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        coordinator: { ...state.vlei.coordinator, status }
      }
    })),
  setIdentityVerificationStatus: (status: TaskStatus) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        identityVerification: { ...state.vlei.identityVerification, status }
      }
    })),
  setIdentityVerificationUserInfo: (
    phoneNumber: string,
    countryCode: string,
    email: string
  ) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        identityVerification: {
          ...state.vlei.identityVerification,
          userInfo: { phoneNumber, countryCode, email }
        }
      }
    })),
  setUserInfo: (userInfo: UserInfo) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        coordinator: { ...state.vlei.coordinator, userInfo }
      }
    })),
  setRepresentatives: (items: Representative[]) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        representatives: { ...state.vlei.representatives, items }
      }
    })),
  setPublicRecords: (publicRecords: string[]) => {
    set((state) => ({
      vlei: {
        ...state.vlei,
        doc: { ...state.vlei.doc, publicRecordLinks: publicRecords }
      }
    }))
  },
  setQarTimeSlot: (qarTimeSlot: { start: string; end: string }[]) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        scheduling: {
          ...state.vlei.scheduling,
          qarTimeSlot: qarTimeSlot
        }
      }
    })),
  setCeremonyTime: (ceremonyTime: string) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        scheduling: {
          ...state.vlei.scheduling,
          ceremonyTime: ceremonyTime
        }
      }
    })),
  setIssuanceCeremonyStatus: (status: TaskStatus) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        scheduling: { ...state.vlei.scheduling, status }
      }
    })),
  setAttendeesSelectedSlotsByID: (id: string, selectedSlots: string[]) =>
    set((state) => {
      const updatedAttendees = state.vlei.scheduling.attendees.map(
        (attendee) => {
          if (attendee.id === id) {
            return {
              ...attendee,
              selectedSlots: selectedSlots
            }
          }
          return attendee
        }
      )

      return {
        vlei: {
          ...state.vlei,
          scheduling: {
            ...state.vlei.scheduling,
            attendees: updatedAttendees
          }
        }
      }
    }),
  setAttendees: (attendees: AttendeesInfo[]) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        scheduling: { ...state.vlei.scheduling, attendees: [...attendees] }
      }
    })),
  setWorkflow: (workflow) =>
    set((state) => ({
      vlei: {
        ...state.vlei,
        workflow
      }
    })),
  updatePhoneNumberUsedInIdentityVerification: async (workflowId, user) => {
    const phoneNumberUsedInIdentityVerification =
      await commonWorkflowService.idVerificationPhoneNumber({
        workflow: workflowId,
        user
      })

    set((state) => ({
      vlei: {
        ...state.vlei,
        phoneNumberUsedInIdentityVerification
      }
    }))
  },
  resetVleiStore: () => {
    set(initialState)
  }
})

export default vleiStore
