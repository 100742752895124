/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EcrResentNotificationRequestDto
 */
export interface EcrResentNotificationRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof EcrResentNotificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * User Id
     * @type {string}
     * @memberof EcrResentNotificationRequestDto
     */
    userId?: string;
    /**
     * User's email. Used to define users, send notifications and invite to onboard and take part in the Workflow
     * @type {string}
     * @memberof EcrResentNotificationRequestDto
     */
    email?: string;
}

/**
 * Check if a given object implements the EcrResentNotificationRequestDto interface.
 */
export function instanceOfEcrResentNotificationRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    return true;
}

export function EcrResentNotificationRequestDtoFromJSON(json: any): EcrResentNotificationRequestDto {
    return EcrResentNotificationRequestDtoFromJSONTyped(json, false);
}

export function EcrResentNotificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcrResentNotificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function EcrResentNotificationRequestDtoToJSON(value?: EcrResentNotificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'userId': value['userId'],
        'email': value['email'],
    };
}

