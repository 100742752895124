/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KeriaAgentDto,
  ServiceProviderDto,
} from '../models/index';
import {
    KeriaAgentDtoFromJSON,
    KeriaAgentDtoToJSON,
    ServiceProviderDtoFromJSON,
    ServiceProviderDtoToJSON,
} from '../models/index';

export interface FindAllByServiceType1Request {
    serviceType: string;
}

export interface FindById1Request {
    serviceProviderId: string;
}

export interface FindByOrgId1Request {
    orgId: string;
}

export interface FindByOrgIdAndServiceType1Request {
    orgId: string;
    serviceType: string;
}

export interface FindServiceProvidersForClient1Request {
    clientOrgId: string;
    serviceType: string;
}

/**
 * 
 */
export class ServiceProviderControllerInternalApi extends runtime.BaseAPI {

    /**
     * Find all service providers for specified serviceType
     */
    async findAllByServiceType1Raw(requestParameters: FindAllByServiceType1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceProviderDto>>> {
        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findAllByServiceType1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/service-type/{service-type}`.replace(`{${"service-type"}}`, encodeURIComponent(String(requestParameters['serviceType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceProviderDtoFromJSON));
    }

    /**
     * Find all service providers for specified serviceType
     */
    async findAllByServiceType1(requestParameters: FindAllByServiceType1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceProviderDto> | null | undefined > {
        const response = await this.findAllByServiceType1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Retrieves all info about service provider and its org
     * Find service provider by id
     */
    async findById1Raw(requestParameters: FindById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceProviderDto>> {
        if (requestParameters['serviceProviderId'] == null) {
            throw new runtime.RequiredError(
                'serviceProviderId',
                'Required parameter "serviceProviderId" was null or undefined when calling findById1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/{service-provider-id}`.replace(`{${"service-provider-id"}}`, encodeURIComponent(String(requestParameters['serviceProviderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProviderDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves all info about service provider and its org
     * Find service provider by id
     */
    async findById1(requestParameters: FindById1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceProviderDto | null | undefined > {
        const response = await this.findById1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find all service providers by service provider\'s orgId
     */
    async findByOrgId1Raw(requestParameters: FindByOrgId1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceProviderDto>>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findByOrgId1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/org/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceProviderDtoFromJSON));
    }

    /**
     * Find all service providers by service provider\'s orgId
     */
    async findByOrgId1(requestParameters: FindByOrgId1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceProviderDto> | null | undefined > {
        const response = await this.findByOrgId1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find service provider by service provider\'s orgId and serviceType
     */
    async findByOrgIdAndServiceType1Raw(requestParameters: FindByOrgIdAndServiceType1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceProviderDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findByOrgIdAndServiceType1().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findByOrgIdAndServiceType1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/by-org-id/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProviderDtoFromJSON(jsonValue));
    }

    /**
     * Find service provider by service provider\'s orgId and serviceType
     */
    async findByOrgIdAndServiceType1(requestParameters: FindByOrgIdAndServiceType1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceProviderDto | null | undefined > {
        const response = await this.findByOrgIdAndServiceType1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find service providers for client by serviceType and client\'s orgId
     */
    async findServiceProvidersForClient1Raw(requestParameters: FindServiceProvidersForClient1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceProviderDto>>> {
        if (requestParameters['clientOrgId'] == null) {
            throw new runtime.RequiredError(
                'clientOrgId',
                'Required parameter "clientOrgId" was null or undefined when calling findServiceProvidersForClient1().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findServiceProvidersForClient1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/by-client-org-id/{client-org-id}`.replace(`{${"client-org-id"}}`, encodeURIComponent(String(requestParameters['clientOrgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceProviderDtoFromJSON));
    }

    /**
     * Find service providers for client by serviceType and client\'s orgId
     */
    async findServiceProvidersForClient1(requestParameters: FindServiceProvidersForClient1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceProviderDto> | null | undefined > {
        const response = await this.findServiceProvidersForClient1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find all keria agents available at the current cell.
     */
    async keriaAgents1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KeriaAgentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/.internal/keria-agents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KeriaAgentDtoFromJSON));
    }

    /**
     * Find all keria agents available at the current cell.
     */
    async keriaAgents1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KeriaAgentDto> | null | undefined > {
        const response = await this.keriaAgents1Raw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
