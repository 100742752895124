/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserPhoneNumberDto } from './UserPhoneNumberDto';
import {
    UserPhoneNumberDtoFromJSON,
    UserPhoneNumberDtoFromJSONTyped,
    UserPhoneNumberDtoToJSON,
} from './UserPhoneNumberDto';
import type { WorkflowTaskActorStatus } from './WorkflowTaskActorStatus';
import {
    WorkflowTaskActorStatusFromJSON,
    WorkflowTaskActorStatusFromJSONTyped,
    WorkflowTaskActorStatusToJSON,
} from './WorkflowTaskActorStatus';

/**
 * 
 * @export
 * @interface UserInOrgAndGrantResponseDto
 */
export interface UserInOrgAndGrantResponseDto {
    /**
     * User Id
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    userId?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    userName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInOrgAndGrantResponseDto
     */
    emails?: Array<string>;
    /**
     * 
     * @type {Array<UserPhoneNumberDto>}
     * @memberof UserInOrgAndGrantResponseDto
     */
    phoneNumbers?: Array<UserPhoneNumberDto>;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    orgId?: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    orgName?: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    grant?: string;
    /**
     * Is proposal accepted?
     * @type {boolean}
     * @memberof UserInOrgAndGrantResponseDto
     */
    isAccepted?: boolean;
    /**
     * User Id
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    approvedByUser?: string;
    /**
     * User Id
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    proposedByUser?: string;
    /**
     * 
     * @type {WorkflowTaskActorStatus}
     * @memberof UserInOrgAndGrantResponseDto
     */
    idVerificationStatus?: WorkflowTaskActorStatus;
    /**
     * AID for the related credential
     * @type {string}
     * @memberof UserInOrgAndGrantResponseDto
     */
    aid?: string;
}

/**
 * Check if a given object implements the UserInOrgAndGrantResponseDto interface.
 */
export function instanceOfUserInOrgAndGrantResponseDto(value: object): boolean {
    return true;
}

export function UserInOrgAndGrantResponseDtoFromJSON(json: any): UserInOrgAndGrantResponseDto {
    return UserInOrgAndGrantResponseDtoFromJSONTyped(json, false);
}

export function UserInOrgAndGrantResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInOrgAndGrantResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'emails': json['emails'] == null ? undefined : json['emails'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(UserPhoneNumberDtoFromJSON)),
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'orgName': json['orgName'] == null ? undefined : json['orgName'],
        'grant': json['grant'] == null ? undefined : json['grant'],
        'isAccepted': json['isAccepted'] == null ? undefined : json['isAccepted'],
        'approvedByUser': json['approvedByUser'] == null ? undefined : json['approvedByUser'],
        'proposedByUser': json['proposedByUser'] == null ? undefined : json['proposedByUser'],
        'idVerificationStatus': json['idVerificationStatus'] == null ? undefined : WorkflowTaskActorStatusFromJSON(json['idVerificationStatus']),
        'aid': json['aid'] == null ? undefined : json['aid'],
    };
}

export function UserInOrgAndGrantResponseDtoToJSON(value?: UserInOrgAndGrantResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'userName': value['userName'],
        'emails': value['emails'],
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(UserPhoneNumberDtoToJSON)),
        'orgId': value['orgId'],
        'orgName': value['orgName'],
        'grant': value['grant'],
        'isAccepted': value['isAccepted'],
        'approvedByUser': value['approvedByUser'],
        'proposedByUser': value['proposedByUser'],
        'idVerificationStatus': WorkflowTaskActorStatusToJSON(value['idVerificationStatus']),
        'aid': value['aid'],
    };
}

