/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindMeetingOrgParametersResponseDto
 */
export interface FindMeetingOrgParametersResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof FindMeetingOrgParametersResponseDto
     */
    workflowInstanceId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof FindMeetingOrgParametersResponseDto
     */
    orgId?: string;
    /**
     * Org lei
     * @type {string}
     * @memberof FindMeetingOrgParametersResponseDto
     */
    lei?: string;
}

/**
 * Check if a given object implements the FindMeetingOrgParametersResponseDto interface.
 */
export function instanceOfFindMeetingOrgParametersResponseDto(value: object): boolean {
    return true;
}

export function FindMeetingOrgParametersResponseDtoFromJSON(json: any): FindMeetingOrgParametersResponseDto {
    return FindMeetingOrgParametersResponseDtoFromJSONTyped(json, false);
}

export function FindMeetingOrgParametersResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindMeetingOrgParametersResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'lei': json['lei'] == null ? undefined : json['lei'],
    };
}

export function FindMeetingOrgParametersResponseDtoToJSON(value?: FindMeetingOrgParametersResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'lei': value['lei'],
    };
}

