/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * State of id verification for this user. Any user can pass id verification only once for all workflows
 * @export
 */
export const WorkflowTaskActorStatus = {
    PreAssigned: 'PRE_ASSIGNED',
    Assigned: 'ASSIGNED',
    InProgress: 'IN_PROGRESS',
    QarRejected: 'QAR_REJECTED',
    Rejected: 'REJECTED',
    Failed: 'FAILED',
    Complete: 'COMPLETE'
} as const;
export type WorkflowTaskActorStatus = typeof WorkflowTaskActorStatus[keyof typeof WorkflowTaskActorStatus];


export function instanceOfWorkflowTaskActorStatus(value: any): boolean {
    return Object.values(WorkflowTaskActorStatus).includes(value);
}

export function WorkflowTaskActorStatusFromJSON(json: any): WorkflowTaskActorStatus {
    return WorkflowTaskActorStatusFromJSONTyped(json, false);
}

export function WorkflowTaskActorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTaskActorStatus {
    return json as WorkflowTaskActorStatus;
}

export function WorkflowTaskActorStatusToJSON(value?: WorkflowTaskActorStatus | null): any {
    return value as any;
}

