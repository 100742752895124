/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CellDto } from './CellDto';
import {
    CellDtoFromJSON,
    CellDtoFromJSONTyped,
    CellDtoToJSON,
} from './CellDto';
import type { OrgDto } from './OrgDto';
import {
    OrgDtoFromJSON,
    OrgDtoFromJSONTyped,
    OrgDtoToJSON,
} from './OrgDto';

/**
 * 
 * @export
 * @interface ServiceProviderDto
 */
export interface ServiceProviderDto {
    /**
     * Service provider id. One org can have several service provider roles
     * @type {string}
     * @memberof ServiceProviderDto
     */
    id?: string;
    /**
     * A time when the service provider did or will begin offering the service
     * @type {string}
     * @memberof ServiceProviderDto
     */
    startDate?: string;
    /**
     * A time when the service provider did or will stop offering the service
     * @type {string}
     * @memberof ServiceProviderDto
     */
    endDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProviderDto
     */
    geos?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProviderDto
     */
    langs?: Array<string>;
    /**
     * Whether this org is the default provider of the specified service type in the specified cell
     * @type {boolean}
     * @memberof ServiceProviderDto
     */
    isCellDefault?: boolean;
    /**
     * 
     * @type {CellDto}
     * @memberof ServiceProviderDto
     */
    cell?: CellDto;
    /**
     * 
     * @type {OrgDto}
     * @memberof ServiceProviderDto
     */
    org?: OrgDto;
    /**
     * Service provider type
     * @type {string}
     * @memberof ServiceProviderDto
     */
    serviceType?: string;
    /**
     * Brand which is in use by organization or service provider
     * @type {string}
     * @memberof ServiceProviderDto
     */
    brandId?: string;
    /**
     * Email for group notifications for the whole organization
     * @type {string}
     * @memberof ServiceProviderDto
     */
    notificationEmail?: string;
}

/**
 * Check if a given object implements the ServiceProviderDto interface.
 */
export function instanceOfServiceProviderDto(value: object): boolean {
    return true;
}

export function ServiceProviderDtoFromJSON(json: any): ServiceProviderDto {
    return ServiceProviderDtoFromJSONTyped(json, false);
}

export function ServiceProviderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'geos': json['geos'] == null ? undefined : json['geos'],
        'langs': json['langs'] == null ? undefined : json['langs'],
        'isCellDefault': json['isCellDefault'] == null ? undefined : json['isCellDefault'],
        'cell': json['cell'] == null ? undefined : CellDtoFromJSON(json['cell']),
        'org': json['org'] == null ? undefined : OrgDtoFromJSON(json['org']),
        'serviceType': json['serviceType'] == null ? undefined : json['serviceType'],
        'brandId': json['brandId'] == null ? undefined : json['brandId'],
        'notificationEmail': json['notificationEmail'] == null ? undefined : json['notificationEmail'],
    };
}

export function ServiceProviderDtoToJSON(value?: ServiceProviderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'geos': value['geos'],
        'langs': value['langs'],
        'isCellDefault': value['isCellDefault'],
        'cell': CellDtoToJSON(value['cell']),
        'org': OrgDtoToJSON(value['org']),
        'serviceType': value['serviceType'],
        'brandId': value['brandId'],
        'notificationEmail': value['notificationEmail'],
    };
}

