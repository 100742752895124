import {
  CreateMultisigOperationRequest,
  CreateMultisigRequest,
  CreateNativeAidRequest,
  FindAllNativeAidsRequest,
  SaveNativeAid1Request
} from '@/api/origin-agent-svc'
import { agentAIDService, agentMultisigService } from '@/utils/apis'

export const saveIdentifierToAgent = async (
  payload: CreateNativeAidRequest
) => {
  return await agentAIDService.saveNativeAid1({
    createNativeAidRequest: payload
  })
}

export const saveMultisigToAgent = async (payload: CreateMultisigRequest) => {
  return await agentMultisigService.createMultisig({
    createMultisigRequest: payload
  })
}

export const findAllNativeAidsFromAgent = async (
  payload: FindAllNativeAidsRequest
) => {
  return await agentAIDService.findAllNativeAids(payload)
}
