/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSlotDto } from './TimeSlotDto';
import {
    TimeSlotDtoFromJSON,
    TimeSlotDtoFromJSONTyped,
    TimeSlotDtoToJSON,
} from './TimeSlotDto';

/**
 * 
 * @export
 * @interface ScheduleEcrCeremonyRequestDto
 */
export interface ScheduleEcrCeremonyRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof ScheduleEcrCeremonyRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof ScheduleEcrCeremonyRequestDto
     */
    timeSlot: TimeSlotDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleEcrCeremonyRequestDto
     */
    lars: Array<string>;
    /**
     * User Id
     * @type {string}
     * @memberof ScheduleEcrCeremonyRequestDto
     */
    moderatorUserId?: string;
}

/**
 * Check if a given object implements the ScheduleEcrCeremonyRequestDto interface.
 */
export function instanceOfScheduleEcrCeremonyRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('timeSlot' in value)) return false;
    if (!('lars' in value)) return false;
    return true;
}

export function ScheduleEcrCeremonyRequestDtoFromJSON(json: any): ScheduleEcrCeremonyRequestDto {
    return ScheduleEcrCeremonyRequestDtoFromJSONTyped(json, false);
}

export function ScheduleEcrCeremonyRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleEcrCeremonyRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'timeSlot': TimeSlotDtoFromJSON(json['timeSlot']),
        'lars': json['lars'],
        'moderatorUserId': json['moderatorUserId'] == null ? undefined : json['moderatorUserId'],
    };
}

export function ScheduleEcrCeremonyRequestDtoToJSON(value?: ScheduleEcrCeremonyRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'timeSlot': TimeSlotDtoToJSON(value['timeSlot']),
        'lars': value['lars'],
        'moderatorUserId': value['moderatorUserId'],
    };
}

