/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowTimeoutCheckResponseDto
 */
export interface WorkflowTimeoutCheckResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowTimeoutCheckResponseDto
     */
    workflowInstanceIds?: Array<string>;
}

/**
 * Check if a given object implements the WorkflowTimeoutCheckResponseDto interface.
 */
export function instanceOfWorkflowTimeoutCheckResponseDto(value: object): boolean {
    return true;
}

export function WorkflowTimeoutCheckResponseDtoFromJSON(json: any): WorkflowTimeoutCheckResponseDto {
    return WorkflowTimeoutCheckResponseDtoFromJSONTyped(json, false);
}

export function WorkflowTimeoutCheckResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTimeoutCheckResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceIds': json['workflowInstanceIds'] == null ? undefined : json['workflowInstanceIds'],
    };
}

export function WorkflowTimeoutCheckResponseDtoToJSON(value?: WorkflowTimeoutCheckResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceIds': value['workflowInstanceIds'],
    };
}

