/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QarAcceptsOrgRequestDto
 */
export interface QarAcceptsOrgRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof QarAcceptsOrgRequestDto
     */
    workflowInstanceId: string;
}

/**
 * Check if a given object implements the QarAcceptsOrgRequestDto interface.
 */
export function instanceOfQarAcceptsOrgRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    return true;
}

export function QarAcceptsOrgRequestDtoFromJSON(json: any): QarAcceptsOrgRequestDto {
    return QarAcceptsOrgRequestDtoFromJSONTyped(json, false);
}

export function QarAcceptsOrgRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QarAcceptsOrgRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
    };
}

export function QarAcceptsOrgRequestDtoToJSON(value?: QarAcceptsOrgRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
    };
}

