/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QarAcceptsDarRequestDto
 */
export interface QarAcceptsDarRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof QarAcceptsDarRequestDto
     */
    workflowInstanceId: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof QarAcceptsDarRequestDto
     */
    orgId: string;
    /**
     * User Id
     * @type {string}
     * @memberof QarAcceptsDarRequestDto
     */
    proposedDarId: string;
}

/**
 * Check if a given object implements the QarAcceptsDarRequestDto interface.
 */
export function instanceOfQarAcceptsDarRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    if (!('proposedDarId' in value)) return false;
    return true;
}

export function QarAcceptsDarRequestDtoFromJSON(json: any): QarAcceptsDarRequestDto {
    return QarAcceptsDarRequestDtoFromJSONTyped(json, false);
}

export function QarAcceptsDarRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QarAcceptsDarRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'orgId': json['orgId'],
        'proposedDarId': json['proposedDarId'],
    };
}

export function QarAcceptsDarRequestDtoToJSON(value?: QarAcceptsDarRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'proposedDarId': value['proposedDarId'],
    };
}

