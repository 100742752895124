/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MeetingNoteResponse,
  WorkflowMeetingNoteRequest,
} from '../models/index';
import {
    MeetingNoteResponseFromJSON,
    MeetingNoteResponseToJSON,
    WorkflowMeetingNoteRequestFromJSON,
    WorkflowMeetingNoteRequestToJSON,
} from '../models/index';

export interface CreateNoteRequest {
    user: string;
    workflowMeetingNoteRequest: WorkflowMeetingNoteRequest;
    workflow?: string;
}

export interface DeleteNodeRequest {
    user: string;
    workflow?: string;
}

export interface GetNoteRequest {
    user: string;
    workflow?: string;
}

export interface UpdateNoteRequest {
    user: string;
    workflowMeetingNoteRequest: WorkflowMeetingNoteRequest;
    workflow?: string;
}

/**
 * 
 */
export class MeetingNoteControllerApi extends runtime.BaseAPI {

    /**
     * Create new note
     */
    async createNoteRaw(requestParameters: CreateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingNoteResponse>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling createNote().'
            );
        }

        if (requestParameters['workflowMeetingNoteRequest'] == null) {
            throw new runtime.RequiredError(
                'workflowMeetingNoteRequest',
                'Required parameter "workflowMeetingNoteRequest" was null or undefined when calling createNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/meeting/note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowMeetingNoteRequestToJSON(requestParameters['workflowMeetingNoteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingNoteResponseFromJSON(jsonValue));
    }

    /**
     * Create new note
     */
    async createNote(requestParameters: CreateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingNoteResponse> {
        const response = await this.createNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete existed note
     */
    async deleteNodeRaw(requestParameters: DeleteNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling deleteNode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/meeting/note`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete existed note
     */
    async deleteNode(requestParameters: DeleteNodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finds and returns note text by workflow and user
     * Get note
     */
    async getNoteRaw(requestParameters: GetNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingNoteResponse>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/meeting/note`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingNoteResponseFromJSON(jsonValue));
    }

    /**
     * Finds and returns note text by workflow and user
     * Get note
     */
    async getNote(requestParameters: GetNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingNoteResponse> {
        const response = await this.getNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update already existed note
     */
    async updateNoteRaw(requestParameters: UpdateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingNoteResponse>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling updateNote().'
            );
        }

        if (requestParameters['workflowMeetingNoteRequest'] == null) {
            throw new runtime.RequiredError(
                'workflowMeetingNoteRequest',
                'Required parameter "workflowMeetingNoteRequest" was null or undefined when calling updateNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/meeting/note`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowMeetingNoteRequestToJSON(requestParameters['workflowMeetingNoteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingNoteResponseFromJSON(jsonValue));
    }

    /**
     * Update already existed note
     */
    async updateNote(requestParameters: UpdateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingNoteResponse> {
        const response = await this.updateNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
