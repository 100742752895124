/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskActorResponseDto } from './TaskActorResponseDto';
import {
    TaskActorResponseDtoFromJSON,
    TaskActorResponseDtoFromJSONTyped,
    TaskActorResponseDtoToJSON,
} from './TaskActorResponseDto';

/**
 * 
 * @export
 * @interface TaskTypeResponseDto
 */
export interface TaskTypeResponseDto {
    /**
     * Name of workflow model
     * @type {string}
     * @memberof TaskTypeResponseDto
     */
    workflowModelId?: string;
    /**
     * Task Type for Workflow participants. Set of task types is different for each workflow model
     * @type {string}
     * @memberof TaskTypeResponseDto
     */
    taskType?: string;
    /**
     * Localizable name of the item
     * @type {string}
     * @memberof TaskTypeResponseDto
     */
    symbolicTextId?: string;
    /**
     * 
     * @type {Array<TaskActorResponseDto>}
     * @memberof TaskTypeResponseDto
     */
    taskActors?: Array<TaskActorResponseDto>;
}

/**
 * Check if a given object implements the TaskTypeResponseDto interface.
 */
export function instanceOfTaskTypeResponseDto(value: object): boolean {
    return true;
}

export function TaskTypeResponseDtoFromJSON(json: any): TaskTypeResponseDto {
    return TaskTypeResponseDtoFromJSONTyped(json, false);
}

export function TaskTypeResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskTypeResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowModelId': json['workflowModelId'] == null ? undefined : json['workflowModelId'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
        'symbolicTextId': json['symbolicTextId'] == null ? undefined : json['symbolicTextId'],
        'taskActors': json['taskActors'] == null ? undefined : ((json['taskActors'] as Array<any>).map(TaskActorResponseDtoFromJSON)),
    };
}

export function TaskTypeResponseDtoToJSON(value?: TaskTypeResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowModelId': value['workflowModelId'],
        'taskType': value['taskType'],
        'symbolicTextId': value['symbolicTextId'],
        'taskActors': value['taskActors'] == null ? undefined : ((value['taskActors'] as Array<any>).map(TaskActorResponseDtoToJSON)),
    };
}

