/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateRoleAcceptanceResponseDto
 */
export interface CandidateRoleAcceptanceResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof CandidateRoleAcceptanceResponseDto
     */
    workflowInstanceId?: string;
    /**
     * User Id
     * @type {string}
     * @memberof CandidateRoleAcceptanceResponseDto
     */
    userId?: string;
    /**
     * Result of the role acceptance attempt. It can be attempt of acceptance or rejection, can be successful or can fail
     * @type {string}
     * @memberof CandidateRoleAcceptanceResponseDto
     */
    status?: CandidateRoleAcceptanceResponseDtoStatusEnum;
}


/**
 * @export
 */
export const CandidateRoleAcceptanceResponseDtoStatusEnum = {
    SuccessfullyRejected: 'SUCCESSFULLY_REJECTED',
    SuccessfullyAccepted: 'SUCCESSFULLY_ACCEPTED',
    InviteIsAlreadyRejected: 'INVITE_IS_ALREADY_REJECTED',
    InviteIsAlreadyAccepted: 'INVITE_IS_ALREADY_ACCEPTED'
} as const;
export type CandidateRoleAcceptanceResponseDtoStatusEnum = typeof CandidateRoleAcceptanceResponseDtoStatusEnum[keyof typeof CandidateRoleAcceptanceResponseDtoStatusEnum];


/**
 * Check if a given object implements the CandidateRoleAcceptanceResponseDto interface.
 */
export function instanceOfCandidateRoleAcceptanceResponseDto(value: object): boolean {
    return true;
}

export function CandidateRoleAcceptanceResponseDtoFromJSON(json: any): CandidateRoleAcceptanceResponseDto {
    return CandidateRoleAcceptanceResponseDtoFromJSONTyped(json, false);
}

export function CandidateRoleAcceptanceResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateRoleAcceptanceResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function CandidateRoleAcceptanceResponseDtoToJSON(value?: CandidateRoleAcceptanceResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'userId': value['userId'],
        'status': value['status'],
    };
}

