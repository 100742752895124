/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RegisterAcceptRequestDto,
  RequestCredentialWorkflowRequestDto,
  ResponseDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    RegisterAcceptRequestDtoFromJSON,
    RegisterAcceptRequestDtoToJSON,
    RequestCredentialWorkflowRequestDtoFromJSON,
    RequestCredentialWorkflowRequestDtoToJSON,
    ResponseDtoFromJSON,
    ResponseDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface CancelInstance1Request {
    workflowInstanceId: string;
}

export interface CompleteInstance1Request {
    workflowInstanceId: string;
}

export interface RegisterAcceptRequest {
    registerAcceptRequestDto: RegisterAcceptRequestDto;
}

export interface StartRequest {
    requestCredentialWorkflowRequestDto: RequestCredentialWorkflowRequestDto;
}

export interface SupervisorSignRequest {
    workflow?: string;
}

/**
 * 
 */
export class RequestCredentialControllerApi extends runtime.BaseAPI {

    /**
     * Cancel workflow instance
     */
    async cancelInstance1Raw(requestParameters: CancelInstance1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling cancelInstance1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/request-credential/{workflow_instance_id}/cancel`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Cancel workflow instance
     */
    async cancelInstance1(requestParameters: CancelInstance1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.cancelInstance1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete workflow instance
     */
    async completeInstance1Raw(requestParameters: CompleteInstance1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling completeInstance1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/request-credential/{workflow_instance_id}/completion`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Complete workflow instance
     */
    async completeInstance1(requestParameters: CompleteInstance1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.completeInstance1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registrate supervisor signing. Is called from State Machine service
     * Register supervisor accept
     */
    async registerAcceptRaw(requestParameters: RegisterAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['registerAcceptRequestDto'] == null) {
            throw new runtime.RequiredError(
                'registerAcceptRequestDto',
                'Required parameter "registerAcceptRequestDto" was null or undefined when calling registerAccept().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/request-credential/register-accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterAcceptRequestDtoToJSON(requestParameters['registerAcceptRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Registrate supervisor signing. Is called from State Machine service
     * Register supervisor accept
     */
    async registerAccept(requestParameters: RegisterAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.registerAcceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and start new Request Credential Workflow
     */
    async startRaw(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['requestCredentialWorkflowRequestDto'] == null) {
            throw new runtime.RequiredError(
                'requestCredentialWorkflowRequestDto',
                'Required parameter "requestCredentialWorkflowRequestDto" was null or undefined when calling start().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/request-credential/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestCredentialWorkflowRequestDtoToJSON(requestParameters['requestCredentialWorkflowRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create and start new Request Credential Workflow
     */
    async start(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.startRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registrate supervisor signing. If signs are enough, closes the workflow
     * Registrate supervisor signing
     */
    async supervisorSignRaw(requestParameters: SupervisorSignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/request-credential/supervisor-signing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Registrate supervisor signing. If signs are enough, closes the workflow
     * Registrate supervisor signing
     */
    async supervisorSign(requestParameters: SupervisorSignRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.supervisorSignRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
