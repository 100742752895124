/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCredRequest,
  CredDto,
} from '../models/index';
import {
    CreateCredRequestFromJSON,
    CreateCredRequestToJSON,
    CredDtoFromJSON,
    CredDtoToJSON,
} from '../models/index';

export interface GetBySaid1Request {
    saidQuery: string;
}

export interface SaveNativeAidRequest {
    createCredRequest: CreateCredRequest;
}

/**
 * 
 */
export class CredControllerApi extends runtime.BaseAPI {

    /**
     * Get by said
     */
    async getBySaid1Raw(requestParameters: GetBySaid1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CredDto>> {
        if (requestParameters['saidQuery'] == null) {
            throw new runtime.RequiredError(
                'saidQuery',
                'Required parameter "saidQuery" was null or undefined when calling getBySaid1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['saidQuery'] != null) {
            queryParameters['said-query'] = requestParameters['saidQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/cred/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredDtoFromJSON(jsonValue));
    }

    /**
     * Get by said
     */
    async getBySaid1(requestParameters: GetBySaid1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CredDto | null | undefined > {
        const response = await this.getBySaid1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Save native AID
     */
    async saveNativeAidRaw(requestParameters: SaveNativeAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CredDto>> {
        if (requestParameters['createCredRequest'] == null) {
            throw new runtime.RequiredError(
                'createCredRequest',
                'Required parameter "createCredRequest" was null or undefined when calling saveNativeAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/cred/native/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCredRequestToJSON(requestParameters['createCredRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredDtoFromJSON(jsonValue));
    }

    /**
     * Save native AID
     */
    async saveNativeAid(requestParameters: SaveNativeAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CredDto> {
        const response = await this.saveNativeAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
