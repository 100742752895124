/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LarsResponseDto
 */
export interface LarsResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LarsResponseDto
     */
    userIds?: Array<string>;
}

/**
 * Check if a given object implements the LarsResponseDto interface.
 */
export function instanceOfLarsResponseDto(value: object): boolean {
    return true;
}

export function LarsResponseDtoFromJSON(json: any): LarsResponseDto {
    return LarsResponseDtoFromJSONTyped(json, false);
}

export function LarsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LarsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userIds': json['userIds'] == null ? undefined : json['userIds'],
    };
}

export function LarsResponseDtoToJSON(value?: LarsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userIds': value['userIds'],
    };
}

