/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DossierDetailsResponseDto,
} from '../models/index';
import {
    DossierDetailsResponseDtoFromJSON,
    DossierDetailsResponseDtoToJSON,
} from '../models/index';

export interface GetDossierDetailsRequest {
    said: string;
}

/**
 * 
 */
export class DossierControllerApi extends runtime.BaseAPI {

    /**
     * Get Dossier credentials details
     */
    async getDossierDetailsRaw(requestParameters: GetDossierDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierDetailsResponseDto>> {
        if (requestParameters['said'] == null) {
            throw new runtime.RequiredError(
                'said',
                'Required parameter "said" was null or undefined when calling getDossierDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/voice/dossier/{said}`.replace(`{${"said"}}`, encodeURIComponent(String(requestParameters['said']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierDetailsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get Dossier credentials details
     */
    async getDossierDetails(requestParameters: GetDossierDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierDetailsResponseDto | null | undefined > {
        const response = await this.getDossierDetailsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
