/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeSlotResponse
 */
export interface TimeSlotResponse {
    /**
     * 
     * @type {string}
     * @memberof TimeSlotResponse
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotResponse
     */
    end?: string;
}

/**
 * Check if a given object implements the TimeSlotResponse interface.
 */
export function instanceOfTimeSlotResponse(value: object): boolean {
    return true;
}

export function TimeSlotResponseFromJSON(json: any): TimeSlotResponse {
    return TimeSlotResponseFromJSONTyped(json, false);
}

export function TimeSlotResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSlotResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'] == null ? undefined : json['start'],
        'end': json['end'] == null ? undefined : json['end'],
    };
}

export function TimeSlotResponseToJSON(value?: TimeSlotResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': value['start'],
        'end': value['end'],
    };
}

