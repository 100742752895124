import { uiNotificationService } from '@/utils/apis'

export async function getUserNotifications(
  userId: string,
  isRead: boolean = false
) {
  return await uiNotificationService.getUINotificationsByUserIdAndIsRead({
    userId,
    isRead
  })
}
