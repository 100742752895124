/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Expiration } from './Expiration';
import {
    ExpirationFromJSON,
    ExpirationFromJSONTyped,
    ExpirationToJSON,
} from './Expiration';

/**
 * List of org parameters from GLEIF
 * @export
 * @interface ResearchGleifResponseDto
 */
export interface ResearchGleifResponseDto {
    /**
     * Org details id, not be confused with org id
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    id?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    legalCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    legalEntityName?: string;
    /**
     * Org lei
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    lei?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    dbaOrBrandName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    altBusiness?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    stockExchange?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    stockSymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    verticalServed?: string;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    status?: string;
    /**
     * 
     * @type {Expiration}
     * @memberof ResearchGleifResponseDto
     */
    expiration?: Expiration;
    /**
     * 
     * @type {string}
     * @memberof ResearchGleifResponseDto
     */
    nextRenewalDate?: string;
    /**
     * Any other org parameters
     * @type {{ [key: string]: string; }}
     * @memberof ResearchGleifResponseDto
     */
    parameters?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ResearchGleifResponseDto interface.
 */
export function instanceOfResearchGleifResponseDto(value: object): boolean {
    return true;
}

export function ResearchGleifResponseDtoFromJSON(json: any): ResearchGleifResponseDto {
    return ResearchGleifResponseDtoFromJSONTyped(json, false);
}

export function ResearchGleifResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchGleifResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'legalCompanyName': json['legalCompanyName'] == null ? undefined : json['legalCompanyName'],
        'legalEntityName': json['legalEntityName'] == null ? undefined : json['legalEntityName'],
        'lei': json['lei'] == null ? undefined : json['lei'],
        'dbaOrBrandName': json['dbaOrBrandName'] == null ? undefined : json['dbaOrBrandName'],
        'website': json['website'] == null ? undefined : json['website'],
        'altBusiness': json['altBusiness'] == null ? undefined : json['altBusiness'],
        'address': json['address'] == null ? undefined : json['address'],
        'country': json['country'] == null ? undefined : json['country'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'ein': json['ein'] == null ? undefined : json['ein'],
        'contactPhone': json['contactPhone'] == null ? undefined : json['contactPhone'],
        'contactEmail': json['contactEmail'] == null ? undefined : json['contactEmail'],
        'stockExchange': json['stockExchange'] == null ? undefined : json['stockExchange'],
        'stockSymbol': json['stockSymbol'] == null ? undefined : json['stockSymbol'],
        'verticalServed': json['verticalServed'] == null ? undefined : json['verticalServed'],
        'status': json['status'] == null ? undefined : json['status'],
        'expiration': json['expiration'] == null ? undefined : ExpirationFromJSON(json['expiration']),
        'nextRenewalDate': json['nextRenewalDate'] == null ? undefined : json['nextRenewalDate'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
    };
}

export function ResearchGleifResponseDtoToJSON(value?: ResearchGleifResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orgId': value['orgId'],
        'legalCompanyName': value['legalCompanyName'],
        'legalEntityName': value['legalEntityName'],
        'lei': value['lei'],
        'dbaOrBrandName': value['dbaOrBrandName'],
        'website': value['website'],
        'altBusiness': value['altBusiness'],
        'address': value['address'],
        'country': value['country'],
        'postalCode': value['postalCode'],
        'city': value['city'],
        'state': value['state'],
        'ein': value['ein'],
        'contactPhone': value['contactPhone'],
        'contactEmail': value['contactEmail'],
        'stockExchange': value['stockExchange'],
        'stockSymbol': value['stockSymbol'],
        'verticalServed': value['verticalServed'],
        'status': value['status'],
        'expiration': ExpirationToJSON(value['expiration']),
        'nextRenewalDate': value['nextRenewalDate'],
        'parameters': value['parameters'],
    };
}

