/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WorkflowTaskActorDto,
} from '../models/index';
import {
    WorkflowTaskActorDtoFromJSON,
    WorkflowTaskActorDtoToJSON,
} from '../models/index';

export interface FindByUserIdAndWorkflowIdRequest {
    user: string;
    workflow?: string;
}

/**
 * 
 */
export class WorkflowTaskActorControllerApi extends runtime.BaseAPI {

    /**
     * Returns list of entities by user-id and workflow
     */
    async findByUserIdAndWorkflowIdRaw(requestParameters: FindByUserIdAndWorkflowIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowTaskActorDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling findByUserIdAndWorkflowId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/task-actor/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowTaskActorDtoFromJSON));
    }

    /**
     * Returns list of entities by user-id and workflow
     */
    async findByUserIdAndWorkflowId(requestParameters: FindByUserIdAndWorkflowIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowTaskActorDto> | null | undefined > {
        const response = await this.findByUserIdAndWorkflowIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
