/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CertificateChainItem
 */
export interface CertificateChainItem {
    /**
     * Self-Addressing Identifier. Primary credential id
     * @type {string}
     * @memberof CertificateChainItem
     */
    said?: string;
    /**
     * Autonomic Identifier
     * @type {string}
     * @memberof CertificateChainItem
     */
    issueeAid?: string;
    /**
     * Human-readable Identifier for AID
     * @type {string}
     * @memberof CertificateChainItem
     */
    issueeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateChainItem
     */
    type?: CertificateChainItemTypeEnum;
    /**
     * When the credential was issued in format yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXXXX
     * @type {string}
     * @memberof CertificateChainItem
     */
    issuedAt?: string;
}


/**
 * @export
 */
export const CertificateChainItemTypeEnum = {
    Unknown: 'UNKNOWN',
    Dossier: 'DOSSIER',
    Vetting: 'VETTING',
    Alloc: 'ALLOC',
    TnAlloc: 'TN_ALLOC',
    DelSig: 'DEL_SIG',
    Qvi: 'QVI',
    Root: 'ROOT'
} as const;
export type CertificateChainItemTypeEnum = typeof CertificateChainItemTypeEnum[keyof typeof CertificateChainItemTypeEnum];


/**
 * Check if a given object implements the CertificateChainItem interface.
 */
export function instanceOfCertificateChainItem(value: object): boolean {
    return true;
}

export function CertificateChainItemFromJSON(json: any): CertificateChainItem {
    return CertificateChainItemFromJSONTyped(json, false);
}

export function CertificateChainItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateChainItem {
    if (json == null) {
        return json;
    }
    return {
        
        'said': json['said'] == null ? undefined : json['said'],
        'issueeAid': json['issueeAid'] == null ? undefined : json['issueeAid'],
        'issueeAlias': json['issueeAlias'] == null ? undefined : json['issueeAlias'],
        'type': json['type'] == null ? undefined : json['type'],
        'issuedAt': json['issuedAt'] == null ? undefined : json['issuedAt'],
    };
}

export function CertificateChainItemToJSON(value?: CertificateChainItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'said': value['said'],
        'issueeAid': value['issueeAid'],
        'issueeAlias': value['issueeAlias'],
        'type': value['type'],
        'issuedAt': value['issuedAt'],
    };
}

