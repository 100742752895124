/* tslint:disable */
/* eslint-disable */
/**
 * Notification service API
 * Service for handling and managing notifications
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SentMessage,
} from '../models/index';
import {
    SentMessageFromJSON,
    SentMessageToJSON,
} from '../models/index';

export interface BuildEmailRequest {
    notificationEventId: string;
    brandId: string;
    userId: string;
    workflowInstanceIdQuery?: string;
}

export interface GetSentMessageRequest {
    email: string;
}

/**
 * 
 */
export class APITestsControllerApi extends runtime.BaseAPI {

    /**
     * Builds email with HTML format for the specified parameters. This endpoint is used to test HTML generator
     * Build HTML email
     */
    async buildEmailRaw(requestParameters: BuildEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['notificationEventId'] == null) {
            throw new runtime.RequiredError(
                'notificationEventId',
                'Required parameter "notificationEventId" was null or undefined when calling buildEmail().'
            );
        }

        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling buildEmail().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling buildEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['notificationEventId'] != null) {
            queryParameters['notification-event-id'] = requestParameters['notificationEventId'];
        }

        if (requestParameters['brandId'] != null) {
            queryParameters['brandId'] = requestParameters['brandId'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['user id'] = requestParameters['userId'];
        }

        if (requestParameters['workflowInstanceIdQuery'] != null) {
            queryParameters['workflow-instance-id-query'] = requestParameters['workflowInstanceIdQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/.private/built-html-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Builds email with HTML format for the specified parameters. This endpoint is used to test HTML generator
     * Build HTML email
     */
    async buildEmail(requestParameters: BuildEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.buildEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cleans up all dev messages sent by email
     * Delete sent messages
     */
    async clearRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/.private/sent-messages`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cleans up all dev messages sent by email
     * Delete sent messages
     */
    async clear(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.clearRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all messages that could be sent by email in prod mode
     * Get sent email messages
     */
    async getSentMessageRaw(requestParameters: GetSentMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SentMessage>>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling getSentMessage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/.private/sent-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SentMessageFromJSON));
    }

    /**
     * Get all messages that could be sent by email in prod mode
     * Get sent email messages
     */
    async getSentMessage(requestParameters: GetSentMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SentMessage> | null | undefined > {
        const response = await this.getSentMessageRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
