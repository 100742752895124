/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';

/**
 * 
 * @export
 * @interface NewUserInfoDto
 */
export interface NewUserInfoDto {
    /**
     * User Id
     * @type {string}
     * @memberof NewUserInfoDto
     */
    id?: string;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof NewUserInfoDto
     */
    firstName?: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof NewUserInfoDto
     */
    lastName?: string;
    /**
     * User's email.
     * @type {string}
     * @memberof NewUserInfoDto
     */
    email?: string;
    /**
     * 
     * @type {PhoneNumberDto}
     * @memberof NewUserInfoDto
     */
    phoneNumber?: PhoneNumberDto;
    /**
     * 
     * @type {string}
     * @memberof NewUserInfoDto
     */
    org?: string;
    /**
     * Onboarding user status
     * @type {string}
     * @memberof NewUserInfoDto
     */
    status?: NewUserInfoDtoStatusEnum;
}


/**
 * @export
 */
export const NewUserInfoDtoStatusEnum = {
    Invited: 'INVITED',
    OnboardVerificationPending: 'ONBOARD_VERIFICATION_PENDING',
    VerificationPending: 'VERIFICATION_PENDING',
    PasscodePending: 'PASSCODE_PENDING',
    OnboardingComplete: 'ONBOARDING_COMPLETE'
} as const;
export type NewUserInfoDtoStatusEnum = typeof NewUserInfoDtoStatusEnum[keyof typeof NewUserInfoDtoStatusEnum];


/**
 * Check if a given object implements the NewUserInfoDto interface.
 */
export function instanceOfNewUserInfoDto(value: object): boolean {
    return true;
}

export function NewUserInfoDtoFromJSON(json: any): NewUserInfoDto {
    return NewUserInfoDtoFromJSONTyped(json, false);
}

export function NewUserInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUserInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : PhoneNumberDtoFromJSON(json['phoneNumber']),
        'org': json['org'] == null ? undefined : json['org'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function NewUserInfoDtoToJSON(value?: NewUserInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': PhoneNumberDtoToJSON(value['phoneNumber']),
        'org': value['org'],
        'status': value['status'],
    };
}

