/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Onboard
 */
export interface Onboard {
    /**
     * User's email.
     * @type {string}
     * @memberof Onboard
     */
    email: string;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof Onboard
     */
    firstName: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof Onboard
     */
    lastName: string;
    /**
     * Name of user's organization
     * @type {string}
     * @memberof Onboard
     */
    companyName: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof Onboard
     */
    role?: string;
    /**
     * Service provider for the specified organization
     * @type {string}
     * @memberof Onboard
     */
    serviceProviderId?: string;
}

/**
 * Check if a given object implements the Onboard interface.
 */
export function instanceOfOnboard(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('companyName' in value)) return false;
    return true;
}

export function OnboardFromJSON(json: any): Onboard {
    return OnboardFromJSONTyped(json, false);
}

export function OnboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Onboard {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'companyName': json['companyName'],
        'role': json['role'] == null ? undefined : json['role'],
        'serviceProviderId': json['serviceProviderId'] == null ? undefined : json['serviceProviderId'],
    };
}

export function OnboardToJSON(value?: Onboard | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'companyName': value['companyName'],
        'role': value['role'],
        'serviceProviderId': value['serviceProviderId'],
    };
}

