/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgDetailsDto
 */
export interface OrgDetailsDto {
    /**
     * Id of org research result. Not to be confused with orgId
     * @type {string}
     * @memberof OrgDetailsDto
     */
    id?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof OrgDetailsDto
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    legalCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    legalEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    status?: string;
    /**
     * Org lei
     * @type {string}
     * @memberof OrgDetailsDto
     */
    lei?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    dbaOrBrandName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    altBusiness?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    stockExchange?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    stockSymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailsDto
     */
    verticalServed?: string;
    /**
     * Any other parameters
     * @type {{ [key: string]: string; }}
     * @memberof OrgDetailsDto
     */
    parameters?: { [key: string]: string; };
}

/**
 * Check if a given object implements the OrgDetailsDto interface.
 */
export function instanceOfOrgDetailsDto(value: object): boolean {
    return true;
}

export function OrgDetailsDtoFromJSON(json: any): OrgDetailsDto {
    return OrgDetailsDtoFromJSONTyped(json, false);
}

export function OrgDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'legalCompanyName': json['legalCompanyName'] == null ? undefined : json['legalCompanyName'],
        'legalEntityName': json['legalEntityName'] == null ? undefined : json['legalEntityName'],
        'status': json['status'] == null ? undefined : json['status'],
        'lei': json['lei'] == null ? undefined : json['lei'],
        'dbaOrBrandName': json['dbaOrBrandName'] == null ? undefined : json['dbaOrBrandName'],
        'website': json['website'] == null ? undefined : json['website'],
        'altBusiness': json['altBusiness'] == null ? undefined : json['altBusiness'],
        'address': json['address'] == null ? undefined : json['address'],
        'country': json['country'] == null ? undefined : json['country'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'ein': json['ein'] == null ? undefined : json['ein'],
        'contactPhone': json['contactPhone'] == null ? undefined : json['contactPhone'],
        'contactEmail': json['contactEmail'] == null ? undefined : json['contactEmail'],
        'stockExchange': json['stockExchange'] == null ? undefined : json['stockExchange'],
        'stockSymbol': json['stockSymbol'] == null ? undefined : json['stockSymbol'],
        'verticalServed': json['verticalServed'] == null ? undefined : json['verticalServed'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
    };
}

export function OrgDetailsDtoToJSON(value?: OrgDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orgId': value['orgId'],
        'legalCompanyName': value['legalCompanyName'],
        'legalEntityName': value['legalEntityName'],
        'status': value['status'],
        'lei': value['lei'],
        'dbaOrBrandName': value['dbaOrBrandName'],
        'website': value['website'],
        'altBusiness': value['altBusiness'],
        'address': value['address'],
        'country': value['country'],
        'postalCode': value['postalCode'],
        'city': value['city'],
        'state': value['state'],
        'ein': value['ein'],
        'contactPhone': value['contactPhone'],
        'contactEmail': value['contactEmail'],
        'stockExchange': value['stockExchange'],
        'stockSymbol': value['stockSymbol'],
        'verticalServed': value['verticalServed'],
        'parameters': value['parameters'],
    };
}

