/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobTitleDto
 */
export interface JobTitleDto {
    /**
     * Full official name of the role
     * @type {string}
     * @memberof JobTitleDto
     */
    title?: string;
    /**
     * Short version of official name of the role
     * @type {string}
     * @memberof JobTitleDto
     */
    shortTitle?: string;
    /**
     * Entity Legal Forme code of the official role. List of roles is defined by GLEIF
     * @type {string}
     * @memberof JobTitleDto
     */
    elfCode?: string;
    /**
     * User Id
     * @type {string}
     * @memberof JobTitleDto
     */
    userId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof JobTitleDto
     */
    orgId?: string;
    /**
     * oor or ecr type in case it is role of that types
     * @type {string}
     * @memberof JobTitleDto
     */
    type?: string;
}

/**
 * Check if a given object implements the JobTitleDto interface.
 */
export function instanceOfJobTitleDto(value: object): boolean {
    return true;
}

export function JobTitleDtoFromJSON(json: any): JobTitleDto {
    return JobTitleDtoFromJSONTyped(json, false);
}

export function JobTitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobTitleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'elfCode': json['elfCode'] == null ? undefined : json['elfCode'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function JobTitleDtoToJSON(value?: JobTitleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'shortTitle': value['shortTitle'],
        'elfCode': value['elfCode'],
        'userId': value['userId'],
        'orgId': value['orgId'],
        'type': value['type'],
    };
}

