/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddPublicRecordsRequestDto
 */
export interface AddPublicRecordsRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof AddPublicRecordsRequestDto
     */
    workflowInstanceId: string;
    /**
     * Workflow task actor id that is used for this operation
     * @type {string}
     * @memberof AddPublicRecordsRequestDto
     */
    workflowInstanceAssignedTaskId: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof AddPublicRecordsRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddPublicRecordsRequestDto
     */
    publicRecordLinks?: Array<string>;
}

/**
 * Check if a given object implements the AddPublicRecordsRequestDto interface.
 */
export function instanceOfAddPublicRecordsRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('workflowInstanceAssignedTaskId' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function AddPublicRecordsRequestDtoFromJSON(json: any): AddPublicRecordsRequestDto {
    return AddPublicRecordsRequestDtoFromJSONTyped(json, false);
}

export function AddPublicRecordsRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPublicRecordsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
    };
}

export function AddPublicRecordsRequestDtoToJSON(value?: AddPublicRecordsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'publicRecordLinks': value['publicRecordLinks'],
    };
}

