/* tslint:disable */
/* eslint-disable */
/**
 * Notification service API
 * Service for handling and managing notifications
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationEventDto,
} from '../models/index';
import {
    NotificationEventDtoFromJSON,
    NotificationEventDtoToJSON,
} from '../models/index';

export interface DeleteNotificationEventRequest {
    notificationEvent: string;
    channel: string;
}

export interface GetNotificationEventRequest {
    notificationEvent: string;
    channel: string;
}

export interface SaveNotificationEventRequest {
    notificationEvent: string;
    channel: string;
    body: string;
}

/**
 * 
 */
export class NotificationEventControllerApi extends runtime.BaseAPI {

    /**
     * Delete notification event
     */
    async deleteNotificationEventRaw(requestParameters: DeleteNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['notificationEvent'] == null) {
            throw new runtime.RequiredError(
                'notificationEvent',
                'Required parameter "notificationEvent" was null or undefined when calling deleteNotificationEvent().'
            );
        }

        if (requestParameters['channel'] == null) {
            throw new runtime.RequiredError(
                'channel',
                'Required parameter "channel" was null or undefined when calling deleteNotificationEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['notificationEvent'] != null) {
            queryParameters['notification-event'] = requestParameters['notificationEvent'];
        }

        if (requestParameters['channel'] != null) {
            queryParameters['channel'] = requestParameters['channel'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/notification-event`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete notification event
     */
    async deleteNotificationEvent(requestParameters: DeleteNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteNotificationEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns symbolic text id
     * Get notification event
     */
    async getNotificationEventRaw(requestParameters: GetNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationEventDto>> {
        if (requestParameters['notificationEvent'] == null) {
            throw new runtime.RequiredError(
                'notificationEvent',
                'Required parameter "notificationEvent" was null or undefined when calling getNotificationEvent().'
            );
        }

        if (requestParameters['channel'] == null) {
            throw new runtime.RequiredError(
                'channel',
                'Required parameter "channel" was null or undefined when calling getNotificationEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['notificationEvent'] != null) {
            queryParameters['notification-event'] = requestParameters['notificationEvent'];
        }

        if (requestParameters['channel'] != null) {
            queryParameters['channel'] = requestParameters['channel'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/notification-event`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationEventDtoFromJSON(jsonValue));
    }

    /**
     * Returns symbolic text id
     * Get notification event
     */
    async getNotificationEvent(requestParameters: GetNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationEventDto | null | undefined > {
        const response = await this.getNotificationEventRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Create new or update notification event
     */
    async saveNotificationEventRaw(requestParameters: SaveNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['notificationEvent'] == null) {
            throw new runtime.RequiredError(
                'notificationEvent',
                'Required parameter "notificationEvent" was null or undefined when calling saveNotificationEvent().'
            );
        }

        if (requestParameters['channel'] == null) {
            throw new runtime.RequiredError(
                'channel',
                'Required parameter "channel" was null or undefined when calling saveNotificationEvent().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling saveNotificationEvent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['notificationEvent'] != null) {
            queryParameters['notification-event'] = requestParameters['notificationEvent'];
        }

        if (requestParameters['channel'] != null) {
            queryParameters['channel'] = requestParameters['channel'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/notification/notification-event`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create new or update notification event
     */
    async saveNotificationEvent(requestParameters: SaveNotificationEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveNotificationEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
