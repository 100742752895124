/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DarClarificationRequestDto
 */
export interface DarClarificationRequestDto {
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    orgId: string;
    /**
     * User Id
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    rejectedDarId: string;
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * Explanation given by QAR why proposed DAR was rejected or why they need more clarification. It is some text written by QAR
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    note: string;
}

/**
 * Check if a given object implements the DarClarificationRequestDto interface.
 */
export function instanceOfDarClarificationRequestDto(value: object): boolean {
    if (!('orgId' in value)) return false;
    if (!('rejectedDarId' in value)) return false;
    if (!('workflowInstanceId' in value)) return false;
    if (!('note' in value)) return false;
    return true;
}

export function DarClarificationRequestDtoFromJSON(json: any): DarClarificationRequestDto {
    return DarClarificationRequestDtoFromJSONTyped(json, false);
}

export function DarClarificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DarClarificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'orgId': json['orgId'],
        'rejectedDarId': json['rejectedDarId'],
        'workflowInstanceId': json['workflowInstanceId'],
        'note': json['note'],
    };
}

export function DarClarificationRequestDtoToJSON(value?: DarClarificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orgId': value['orgId'],
        'rejectedDarId': value['rejectedDarId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'note': value['note'],
    };
}

