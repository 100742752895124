/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MultisigMemberDto } from './MultisigMemberDto';
import {
    MultisigMemberDtoFromJSON,
    MultisigMemberDtoFromJSONTyped,
    MultisigMemberDtoToJSON,
} from './MultisigMemberDto';

/**
 * 
 * @export
 * @interface MultisigMembersResponse
 */
export interface MultisigMembersResponse {
    /**
     * 
     * @type {Array<MultisigMemberDto>}
     * @memberof MultisigMembersResponse
     */
    members?: Array<MultisigMemberDto>;
}

/**
 * Check if a given object implements the MultisigMembersResponse interface.
 */
export function instanceOfMultisigMembersResponse(value: object): boolean {
    return true;
}

export function MultisigMembersResponseFromJSON(json: any): MultisigMembersResponse {
    return MultisigMembersResponseFromJSONTyped(json, false);
}

export function MultisigMembersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultisigMembersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'members': json['members'] == null ? undefined : ((json['members'] as Array<any>).map(MultisigMemberDtoFromJSON)),
    };
}

export function MultisigMembersResponseToJSON(value?: MultisigMembersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'members': value['members'] == null ? undefined : ((value['members'] as Array<any>).map(MultisigMemberDtoToJSON)),
    };
}

