/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialsIssuanceStatusDto
 */
export interface CredentialsIssuanceStatusDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof CredentialsIssuanceStatusDto
     */
    workflowInstanceId?: string;
    /**
     * Credential type produced in the current Workflow
     * @type {string}
     * @memberof CredentialsIssuanceStatusDto
     */
    credentialType?: string;
    /**
     * State of the current workflow - is it in the progress or completed
     * @type {string}
     * @memberof CredentialsIssuanceStatusDto
     */
    status?: CredentialsIssuanceStatusDtoStatusEnum;
}


/**
 * @export
 */
export const CredentialsIssuanceStatusDtoStatusEnum = {
    Started: 'STARTED',
    Canceled: 'CANCELED',
    Terminated: 'TERMINATED',
    Completed: 'COMPLETED'
} as const;
export type CredentialsIssuanceStatusDtoStatusEnum = typeof CredentialsIssuanceStatusDtoStatusEnum[keyof typeof CredentialsIssuanceStatusDtoStatusEnum];


/**
 * Check if a given object implements the CredentialsIssuanceStatusDto interface.
 */
export function instanceOfCredentialsIssuanceStatusDto(value: object): boolean {
    return true;
}

export function CredentialsIssuanceStatusDtoFromJSON(json: any): CredentialsIssuanceStatusDto {
    return CredentialsIssuanceStatusDtoFromJSONTyped(json, false);
}

export function CredentialsIssuanceStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialsIssuanceStatusDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'credentialType': json['credentialType'] == null ? undefined : json['credentialType'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function CredentialsIssuanceStatusDtoToJSON(value?: CredentialsIssuanceStatusDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'credentialType': value['credentialType'],
        'status': value['status'],
    };
}

