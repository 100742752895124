/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientDto } from './RecipientDto';
import {
    RecipientDtoFromJSON,
    RecipientDtoFromJSONTyped,
    RecipientDtoToJSON,
} from './RecipientDto';

/**
 * 
 * @export
 * @interface EcrProposalRequestDto
 */
export interface EcrProposalRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof EcrProposalRequestDto
     */
    workflowInstanceId: string;
    /**
     * Workflow task actor id that is used for this operation
     * @type {string}
     * @memberof EcrProposalRequestDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof EcrProposalRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {Array<RecipientDto>}
     * @memberof EcrProposalRequestDto
     */
    proposedUsers?: Array<RecipientDto>;
}

/**
 * Check if a given object implements the EcrProposalRequestDto interface.
 */
export function instanceOfEcrProposalRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function EcrProposalRequestDtoFromJSON(json: any): EcrProposalRequestDto {
    return EcrProposalRequestDtoFromJSONTyped(json, false);
}

export function EcrProposalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcrProposalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'proposedUsers': json['proposedUsers'] == null ? undefined : ((json['proposedUsers'] as Array<any>).map(RecipientDtoFromJSON)),
    };
}

export function EcrProposalRequestDtoToJSON(value?: EcrProposalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'proposedUsers': value['proposedUsers'] == null ? undefined : ((value['proposedUsers'] as Array<any>).map(RecipientDtoToJSON)),
    };
}

