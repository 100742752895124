/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * wallet id
     * @type {string}
     * @memberof Wallet
     */
    id: string;
    /**
     * User's email.
     * @type {string}
     * @memberof Wallet
     */
    email: string;
    /**
     * Wallet data was returned from Keria
     * @type {string}
     * @memberof Wallet
     */
    walletData: string;
}

/**
 * Check if a given object implements the Wallet interface.
 */
export function instanceOfWallet(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('email' in value)) return false;
    if (!('walletData' in value)) return false;
    return true;
}

export function WalletFromJSON(json: any): Wallet {
    return WalletFromJSONTyped(json, false);
}

export function WalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): Wallet {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'walletData': json['walletData'],
    };
}

export function WalletToJSON(value?: Wallet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'walletData': value['walletData'],
    };
}

