/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * Name of an org
     * @type {string}
     * @memberof OrganizationDto
     */
    name?: string;
    /**
     * Org emails domain
     * @type {string}
     * @memberof OrganizationDto
     */
    domain?: string;
}

/**
 * Check if a given object implements the OrganizationDto interface.
 */
export function instanceOfOrganizationDto(value: object): boolean {
    return true;
}

export function OrganizationDtoFromJSON(json: any): OrganizationDto {
    return OrganizationDtoFromJSONTyped(json, false);
}

export function OrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'domain': json['domain'] == null ? undefined : json['domain'],
    };
}

export function OrganizationDtoToJSON(value?: OrganizationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'domain': value['domain'],
    };
}

