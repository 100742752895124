/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorResentNotificationResponseDto
 */
export interface OorResentNotificationResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof OorResentNotificationResponseDto
     */
    workflowInstanceId?: string;
    /**
     * User Id
     * @type {string}
     * @memberof OorResentNotificationResponseDto
     */
    userId?: string;
}

/**
 * Check if a given object implements the OorResentNotificationResponseDto interface.
 */
export function instanceOfOorResentNotificationResponseDto(value: object): boolean {
    return true;
}

export function OorResentNotificationResponseDtoFromJSON(json: any): OorResentNotificationResponseDto {
    return OorResentNotificationResponseDtoFromJSONTyped(json, false);
}

export function OorResentNotificationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorResentNotificationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
    };
}

export function OorResentNotificationResponseDtoToJSON(value?: OorResentNotificationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'userId': value['userId'],
    };
}

