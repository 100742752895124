/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AidResponseDto,
  LegalNamesDto,
  NewAidDto,
  NewUserInfoDto,
  NewUserRequestDto,
  OriginAidDto,
  UserInfoDto,
  WitnessDto,
} from '../models/index';
import {
    AidResponseDtoFromJSON,
    AidResponseDtoToJSON,
    LegalNamesDtoFromJSON,
    LegalNamesDtoToJSON,
    NewAidDtoFromJSON,
    NewAidDtoToJSON,
    NewUserInfoDtoFromJSON,
    NewUserInfoDtoToJSON,
    NewUserRequestDtoFromJSON,
    NewUserRequestDtoToJSON,
    OriginAidDtoFromJSON,
    OriginAidDtoToJSON,
    UserInfoDtoFromJSON,
    UserInfoDtoToJSON,
    WitnessDtoFromJSON,
    WitnessDtoToJSON,
} from '../models/index';

export interface CreateUserOriginAidRequest {
    userId: string;
    originAidDto: OriginAidDto;
}

export interface GetUserWalletAidRequest {
    userId: string;
}

export interface GetWebcamPhotoRequest {
    userId: string;
}

export interface GetWebcamUrlRequest {
    userId: string;
}

export interface NewUserRequest {
    newUserRequestDto: NewUserRequestDto;
}

export interface UpdateLegalNamesRequest {
    legalNamesDto: LegalNamesDto;
}

export interface UpdateUserOriginAidRequest {
    originAidDto: OriginAidDto;
}

export interface UpdateUserWalletAidRequest {
    userId: string;
    newAidDto: NewAidDto;
}

export interface UpdateUserWalletAidByReferenceRequest {
    newAidDto: NewAidDto;
}

export interface UserByEmailRequest {
    email: string;
}

export interface UserInfoRequest {
    emailQuery?: string;
    countryCodeQuery?: string;
    phoneNumberQuery?: string;
}

export interface UserInfoByUserIdRequest {
    userId: string;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     * Creates origin-aid for the first time. Origin-aid should not be set in DB. This request is not covered by security.
     * Create origin-aid
     */
    async createUserOriginAidRaw(requestParameters: CreateUserOriginAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewAidDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling createUserOriginAid().'
            );
        }

        if (requestParameters['originAidDto'] == null) {
            throw new runtime.RequiredError(
                'originAidDto',
                'Required parameter "originAidDto" was null or undefined when calling createUserOriginAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/origin-aid/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OriginAidDtoToJSON(requestParameters['originAidDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewAidDtoFromJSON(jsonValue));
    }

    /**
     * Creates origin-aid for the first time. Origin-aid should not be set in DB. This request is not covered by security.
     * Create origin-aid
     */
    async createUserOriginAid(requestParameters: CreateUserOriginAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewAidDto> {
        const response = await this.createUserOriginAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finds origin aid for authorized user
     * Get origin-aid
     */
    async getUserOriginAidRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/origin-aid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidResponseDtoFromJSON(jsonValue));
    }

    /**
     * Finds origin aid for authorized user
     * Get origin-aid
     */
    async getUserOriginAid(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidResponseDto | null | undefined > {
        const response = await this.getUserOriginAidRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get current user wallet aid by user-id
     */
    async getUserWalletAidRaw(requestParameters: GetUserWalletAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserWalletAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/{user_id}/aid`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get current user wallet aid by user-id
     */
    async getUserWalletAid(requestParameters: GetUserWalletAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getUserWalletAidRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get current user wallet aid
     */
    async getUserWalletAid1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/aid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get current user wallet aid
     */
    async getUserWalletAid1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getUserWalletAid1Raw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Returns users photo for demo and dev users
     * Get user photo
     */
    async getWebcamPhotoRaw(requestParameters: GetWebcamPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getWebcamPhoto().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/{user_id}/webcam-photo`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns users photo for demo and dev users
     * Get user photo
     */
    async getWebcamPhoto(requestParameters: GetWebcamPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getWebcamPhotoRaw(requestParameters, initOverrides);
    }

    /**
     * Returns users photo url. Used for demo and dev users in ceremonies
     * Get user photo url
     */
    async getWebcamUrlRaw(requestParameters: GetWebcamUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getWebcamUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/{user_id}/webcam-url`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns users photo url. Used for demo and dev users in ceremonies
     * Get user photo url
     */
    async getWebcamUrl(requestParameters: GetWebcamUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string> | null | undefined > {
        const response = await this.getWebcamUrlRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Create new user
     */
    async newUserRaw(requestParameters: NewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewUserInfoDto>> {
        if (requestParameters['newUserRequestDto'] == null) {
            throw new runtime.RequiredError(
                'newUserRequestDto',
                'Required parameter "newUserRequestDto" was null or undefined when calling newUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewUserRequestDtoToJSON(requestParameters['newUserRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewUserInfoDtoFromJSON(jsonValue));
    }

    /**
     * Create new user
     */
    async newUser(requestParameters: NewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewUserInfoDto> {
        const response = await this.newUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Called by automation - legal names are defined in some id verification services like ID Verse
     * Updates user\'s legal names if they are absent
     */
    async updateLegalNamesRaw(requestParameters: UpdateLegalNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['legalNamesDto'] == null) {
            throw new runtime.RequiredError(
                'legalNamesDto',
                'Required parameter "legalNamesDto" was null or undefined when calling updateLegalNames().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/legal-names`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LegalNamesDtoToJSON(requestParameters['legalNamesDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Called by automation - legal names are defined in some id verification services like ID Verse
     * Updates user\'s legal names if they are absent
     */
    async updateLegalNames(requestParameters: UpdateLegalNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateLegalNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates value of already configured origin-aid. Origin-aid should be already set in DB. This request is covered by security check
     * Update origin-aid
     */
    async updateUserOriginAidRaw(requestParameters: UpdateUserOriginAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidResponseDto>> {
        if (requestParameters['originAidDto'] == null) {
            throw new runtime.RequiredError(
                'originAidDto',
                'Required parameter "originAidDto" was null or undefined when calling updateUserOriginAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/origin-aid`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OriginAidDtoToJSON(requestParameters['originAidDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidResponseDtoFromJSON(jsonValue));
    }

    /**
     * Updates value of already configured origin-aid. Origin-aid should be already set in DB. This request is covered by security check
     * Update origin-aid
     */
    async updateUserOriginAid(requestParameters: UpdateUserOriginAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidResponseDto> {
        const response = await this.updateUserOriginAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update wallet aid by user-id
     */
    async updateUserWalletAidRaw(requestParameters: UpdateUserWalletAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateUserWalletAid().'
            );
        }

        if (requestParameters['newAidDto'] == null) {
            throw new runtime.RequiredError(
                'newAidDto',
                'Required parameter "newAidDto" was null or undefined when calling updateUserWalletAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/{user_id}/aid`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewAidDtoToJSON(requestParameters['newAidDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update wallet aid by user-id
     */
    async updateUserWalletAid(requestParameters: UpdateUserWalletAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateUserWalletAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user wallet aid for current user
     */
    async updateUserWalletAidByReferenceRaw(requestParameters: UpdateUserWalletAidByReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['newAidDto'] == null) {
            throw new runtime.RequiredError(
                'newAidDto',
                'Required parameter "newAidDto" was null or undefined when calling updateUserWalletAidByReference().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/user/aid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewAidDtoToJSON(requestParameters['newAidDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create user wallet aid for current user
     */
    async updateUserWalletAidByReference(requestParameters: UpdateUserWalletAidByReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateUserWalletAidByReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find user info by email
     */
    async userByEmailRaw(requestParameters: UserByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoDto>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling userByEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/by-email/{email}/user/info`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters['email']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoDtoFromJSON(jsonValue));
    }

    /**
     * Find user info by email
     */
    async userByEmail(requestParameters: UserByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoDto> {
        const response = await this.userByEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Most common way to get requester info
     * Find user info for the current authorization signature
     */
    async userIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Most common way to get requester info
     * Find user info for the current authorization signature
     */
    async userId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.userIdRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find user by email OR phone number. One of that parameters is mandatory
     * Find user
     */
    async userInfoRaw(requestParameters: UserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters['emailQuery'] != null) {
            queryParameters['email-query'] = requestParameters['emailQuery'];
        }

        if (requestParameters['countryCodeQuery'] != null) {
            queryParameters['countryCode-query'] = requestParameters['countryCodeQuery'];
        }

        if (requestParameters['phoneNumberQuery'] != null) {
            queryParameters['phoneNumber-query'] = requestParameters['phoneNumberQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoDtoFromJSON(jsonValue));
    }

    /**
     * Find user by email OR phone number. One of that parameters is mandatory
     * Find user
     */
    async userInfo(requestParameters: UserInfoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoDto | null | undefined > {
        const response = await this.userInfoRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find user info by user-id
     */
    async userInfoByUserIdRaw(requestParameters: UserInfoByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling userInfoByUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user/{user_id}/info`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoDtoFromJSON(jsonValue));
    }

    /**
     * Find user info by user-id
     */
    async userInfoByUserId(requestParameters: UserInfoByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoDto> {
        const response = await this.userInfoByUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the name of the witnesses set for the specified user. Returns values of WitnessSet
     * Find user witnesses
     */
    async witnessesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WitnessDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/witnesses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WitnessDtoFromJSON));
    }

    /**
     * Returns the name of the witnesses set for the specified user. Returns values of WitnessSet
     * Find user witnesses
     */
    async witnesses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WitnessDto> | null | undefined > {
        const response = await this.witnessesRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
