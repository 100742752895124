/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetAliasesRequest,
  GetAliasesResponse,
  WatcherCredDto,
} from '../models/index';
import {
    GetAliasesRequestFromJSON,
    GetAliasesRequestToJSON,
    GetAliasesResponseFromJSON,
    GetAliasesResponseToJSON,
    WatcherCredDtoFromJSON,
    WatcherCredDtoToJSON,
} from '../models/index';

export interface GetAliasesOperationRequest {
    getAliasesRequest: GetAliasesRequest;
}

export interface GetBySaidRequest {
    said: string;
}

export interface RevocationRequest {
    saidQuery: string;
}

export interface SaveAidRequest {
    watcherCredDto: WatcherCredDto;
}

/**
 * 
 */
export class WatcherCredControllerApi extends runtime.BaseAPI {

    /**
     * Get several credential aliases by saids
     */
    async getAliasesRaw(requestParameters: GetAliasesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAliasesResponse>> {
        if (requestParameters['getAliasesRequest'] == null) {
            throw new runtime.RequiredError(
                'getAliasesRequest',
                'Required parameter "getAliasesRequest" was null or undefined when calling getAliases().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/watcher/cred/aliases`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAliasesRequestToJSON(requestParameters['getAliasesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAliasesResponseFromJSON(jsonValue));
    }

    /**
     * Get several credential aliases by saids
     */
    async getAliases(requestParameters: GetAliasesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAliasesResponse> {
        const response = await this.getAliasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get credential by said
     */
    async getBySaidRaw(requestParameters: GetBySaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WatcherCredDto>>> {
        if (requestParameters['said'] == null) {
            throw new runtime.RequiredError(
                'said',
                'Required parameter "said" was null or undefined when calling getBySaid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/watcher/cred/{said}`.replace(`{${"said"}}`, encodeURIComponent(String(requestParameters['said']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WatcherCredDtoFromJSON));
    }

    /**
     * Get credential by said
     */
    async getBySaid(requestParameters: GetBySaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WatcherCredDto> | null | undefined > {
        const response = await this.getBySaidRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Revoke credential
     */
    async revocationRaw(requestParameters: RevocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WatcherCredDto>> {
        if (requestParameters['saidQuery'] == null) {
            throw new runtime.RequiredError(
                'saidQuery',
                'Required parameter "saidQuery" was null or undefined when calling revocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['saidQuery'] != null) {
            queryParameters['said-query'] = requestParameters['saidQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/watcher/cred/revocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WatcherCredDtoFromJSON(jsonValue));
    }

    /**
     * Revoke credential
     */
    async revocation(requestParameters: RevocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WatcherCredDto> {
        const response = await this.revocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save AID to watcher schema
     */
    async saveAidRaw(requestParameters: SaveAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WatcherCredDto>> {
        if (requestParameters['watcherCredDto'] == null) {
            throw new runtime.RequiredError(
                'watcherCredDto',
                'Required parameter "watcherCredDto" was null or undefined when calling saveAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/watcher/cred/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WatcherCredDtoToJSON(requestParameters['watcherCredDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WatcherCredDtoFromJSON(jsonValue));
    }

    /**
     * Save AID to watcher schema
     */
    async saveAid(requestParameters: SaveAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WatcherCredDto> {
        const response = await this.saveAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
