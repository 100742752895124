/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PendingRoleUpdateRequestDto
 */
export interface PendingRoleUpdateRequestDto {
    /**
     * User Id
     * @type {string}
     * @memberof PendingRoleUpdateRequestDto
     */
    userId: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof PendingRoleUpdateRequestDto
     */
    role: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof PendingRoleUpdateRequestDto
     */
    orgId: string;
    /**
     * Is role accepted?
     * @type {boolean}
     * @memberof PendingRoleUpdateRequestDto
     */
    roleStatus?: boolean;
    /**
     * oor or ecr type in case it is role of that types
     * @type {string}
     * @memberof PendingRoleUpdateRequestDto
     */
    jobTitleType?: string;
}

/**
 * Check if a given object implements the PendingRoleUpdateRequestDto interface.
 */
export function instanceOfPendingRoleUpdateRequestDto(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('role' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function PendingRoleUpdateRequestDtoFromJSON(json: any): PendingRoleUpdateRequestDto {
    return PendingRoleUpdateRequestDtoFromJSONTyped(json, false);
}

export function PendingRoleUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingRoleUpdateRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'role': json['role'],
        'orgId': json['orgId'],
        'roleStatus': json['Role status'] == null ? undefined : json['Role status'],
        'jobTitleType': json['jobTitleType'] == null ? undefined : json['jobTitleType'],
    };
}

export function PendingRoleUpdateRequestDtoToJSON(value?: PendingRoleUpdateRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'role': value['role'],
        'orgId': value['orgId'],
        'Role status': value['roleStatus'],
        'jobTitleType': value['jobTitleType'],
    };
}

