/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Candidate
 */
export interface Candidate {
    /**
     * User Id
     * @type {string}
     * @memberof Candidate
     */
    userId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Candidate
     */
    emails?: Array<string>;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof Candidate
     */
    personalNames?: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof Candidate
     */
    familyNames?: string;
    /**
     * Current status of candidate in the workflow
     * @type {string}
     * @memberof Candidate
     */
    status?: CandidateStatusEnum;
}


/**
 * @export
 */
export const CandidateStatusEnum = {
    Proposed: 'PROPOSED',
    Approved: 'APPROVED',
    Agreed: 'AGREED',
    Certified: 'CERTIFIED',
    Rejected: 'REJECTED'
} as const;
export type CandidateStatusEnum = typeof CandidateStatusEnum[keyof typeof CandidateStatusEnum];


/**
 * Check if a given object implements the Candidate interface.
 */
export function instanceOfCandidate(value: object): boolean {
    return true;
}

export function CandidateFromJSON(json: any): Candidate {
    return CandidateFromJSONTyped(json, false);
}

export function CandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Candidate {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'emails': json['emails'] == null ? undefined : json['emails'],
        'personalNames': json['personalNames'] == null ? undefined : json['personalNames'],
        'familyNames': json['familyNames'] == null ? undefined : json['familyNames'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function CandidateToJSON(value?: Candidate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'emails': value['emails'],
        'personalNames': value['personalNames'],
        'familyNames': value['familyNames'],
        'status': value['status'],
    };
}

