/* tslint:disable */
/* eslint-disable */
/**
 * Notification service API
 * Service for handling and managing notifications
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NewAutotodoRequestDto,
  NotificationResponseDto,
} from '../models/index';
import {
    NewAutotodoRequestDtoFromJSON,
    NewAutotodoRequestDtoToJSON,
    NotificationResponseDtoFromJSON,
    NotificationResponseDtoToJSON,
} from '../models/index';

export interface CreateNewAutotodoRequest {
    newAutotodoRequestDto: NewAutotodoRequestDto;
}

/**
 * 
 */
export class AutotodoControllerApi extends runtime.BaseAPI {

    /**
     * Create autotodo with custom paramters
     */
    async createNewAutotodoRaw(requestParameters: CreateNewAutotodoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationResponseDto>> {
        if (requestParameters['newAutotodoRequestDto'] == null) {
            throw new runtime.RequiredError(
                'newAutotodoRequestDto',
                'Required parameter "newAutotodoRequestDto" was null or undefined when calling createNewAutotodo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/notification/autotodo/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewAutotodoRequestDtoToJSON(requestParameters['newAutotodoRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create autotodo with custom paramters
     */
    async createNewAutotodo(requestParameters: CreateNewAutotodoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationResponseDto> {
        const response = await this.createNewAutotodoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
