/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Personal user preferences
 * @export
 * @interface UserPrefDto
 */
export interface UserPrefDto {
    /**
     * User's location timezone
     * @type {string}
     * @memberof UserPrefDto
     */
    timezone?: string;
    /**
     * Is time format in 24 hours or 12 hours?
     * @type {boolean}
     * @memberof UserPrefDto
     */
    timeFormatIn24h?: boolean;
    /**
     * User's lang for localization in ISO 639-1 format
     * @type {string}
     * @memberof UserPrefDto
     */
    lang?: string;
}

/**
 * Check if a given object implements the UserPrefDto interface.
 */
export function instanceOfUserPrefDto(value: object): boolean {
    return true;
}

export function UserPrefDtoFromJSON(json: any): UserPrefDto {
    return UserPrefDtoFromJSONTyped(json, false);
}

export function UserPrefDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPrefDto {
    if (json == null) {
        return json;
    }
    return {
        
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'timeFormatIn24h': json['timeFormatIn24h'] == null ? undefined : json['timeFormatIn24h'],
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}

export function UserPrefDtoToJSON(value?: UserPrefDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timezone': value['timezone'],
        'timeFormatIn24h': value['timeFormatIn24h'],
        'lang': value['lang'],
    };
}

