/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EcrWorkflowModeResponseDto
 */
export interface EcrWorkflowModeResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof EcrWorkflowModeResponseDto
     */
    workflowInstanceId?: string;
    /**
     * ECR Workflow mode
     * @type {string}
     * @memberof EcrWorkflowModeResponseDto
     */
    ecrWorkflowMode?: EcrWorkflowModeResponseDtoEcrWorkflowModeEnum;
}


/**
 * @export
 */
export const EcrWorkflowModeResponseDtoEcrWorkflowModeEnum = {
    QviIssued: 'QVI_ISSUED',
    SelfIssued: 'SELF_ISSUED'
} as const;
export type EcrWorkflowModeResponseDtoEcrWorkflowModeEnum = typeof EcrWorkflowModeResponseDtoEcrWorkflowModeEnum[keyof typeof EcrWorkflowModeResponseDtoEcrWorkflowModeEnum];


/**
 * Check if a given object implements the EcrWorkflowModeResponseDto interface.
 */
export function instanceOfEcrWorkflowModeResponseDto(value: object): boolean {
    return true;
}

export function EcrWorkflowModeResponseDtoFromJSON(json: any): EcrWorkflowModeResponseDto {
    return EcrWorkflowModeResponseDtoFromJSONTyped(json, false);
}

export function EcrWorkflowModeResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcrWorkflowModeResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'ecrWorkflowMode': json['ecrWorkflowMode'] == null ? undefined : json['ecrWorkflowMode'],
    };
}

export function EcrWorkflowModeResponseDtoToJSON(value?: EcrWorkflowModeResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'ecrWorkflowMode': value['ecrWorkflowMode'],
    };
}

