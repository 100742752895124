/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddClientToServiceProviderRequest,
  OrgDto,
} from '../models/index';
import {
    AddClientToServiceProviderRequestFromJSON,
    AddClientToServiceProviderRequestToJSON,
    OrgDtoFromJSON,
    OrgDtoToJSON,
} from '../models/index';

export interface AddClientToServiceProvider1Request {
    addClientToServiceProviderRequest: AddClientToServiceProviderRequest;
}

export interface FindClientsForServiceProvider1Request {
    org: string;
    serviceType: string;
}

/**
 * 
 */
export class ClientControllerInternalApi extends runtime.BaseAPI {

    /**
     * Adds a client to the service provider.
     */
    async addClientToServiceProvider1Raw(requestParameters: AddClientToServiceProvider1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['addClientToServiceProviderRequest'] == null) {
            throw new runtime.RequiredError(
                'addClientToServiceProviderRequest',
                'Required parameter "addClientToServiceProviderRequest" was null or undefined when calling addClientToServiceProvider1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/client/.internal/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddClientToServiceProviderRequestToJSON(requestParameters['addClientToServiceProviderRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Adds a client to the service provider.
     */
    async addClientToServiceProvider1(requestParameters: AddClientToServiceProvider1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addClientToServiceProvider1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find clients by service provider\'s orgId and serviceType
     */
    async findClientsForServiceProvider1Raw(requestParameters: FindClientsForServiceProvider1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrgDto>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling findClientsForServiceProvider1().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findClientsForServiceProvider1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/client/.internal/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgDtoFromJSON));
    }

    /**
     * Find clients by service provider\'s orgId and serviceType
     */
    async findClientsForServiceProvider1(requestParameters: FindClientsForServiceProvider1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrgDto> | null | undefined > {
        const response = await this.findClientsForServiceProvider1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
