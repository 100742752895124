/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttendeeDto
 */
export interface AttendeeDto {
    /**
     * User Id
     * @type {string}
     * @memberof AttendeeDto
     */
    userId?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof AttendeeDto
     */
    name?: string;
}

/**
 * Check if a given object implements the AttendeeDto interface.
 */
export function instanceOfAttendeeDto(value: object): boolean {
    return true;
}

export function AttendeeDtoFromJSON(json: any): AttendeeDto {
    return AttendeeDtoFromJSONTyped(json, false);
}

export function AttendeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendeeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function AttendeeDtoToJSON(value?: AttendeeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'name': value['name'],
    };
}

