/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPersonaDto
 */
export interface NewPersonaDto {
    /**
     * User password
     * @type {string}
     * @memberof NewPersonaDto
     */
    password?: string;
    /**
     * User's email.
     * @type {string}
     * @memberof NewPersonaDto
     */
    email?: string;
    /**
     * Should this persona be registered in Cognito?
     * @type {boolean}
     * @memberof NewPersonaDto
     */
    registerInCognito?: boolean;
    /**
     * Should this persona get new wallet?
     * @type {boolean}
     * @memberof NewPersonaDto
     */
    createNewWallet?: boolean;
    /**
     * User's wallet data
     * @type {string}
     * @memberof NewPersonaDto
     */
    walletData?: string;
}

/**
 * Check if a given object implements the NewPersonaDto interface.
 */
export function instanceOfNewPersonaDto(value: object): boolean {
    return true;
}

export function NewPersonaDtoFromJSON(json: any): NewPersonaDto {
    return NewPersonaDtoFromJSONTyped(json, false);
}

export function NewPersonaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPersonaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'password': json['Password'] == null ? undefined : json['Password'],
        'email': json['email'] == null ? undefined : json['email'],
        'registerInCognito': json['registerInCognito'] == null ? undefined : json['registerInCognito'],
        'createNewWallet': json['createNewWallet'] == null ? undefined : json['createNewWallet'],
        'walletData': json['walletData'] == null ? undefined : json['walletData'],
    };
}

export function NewPersonaDtoToJSON(value?: NewPersonaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Password': value['password'],
        'email': value['email'],
        'registerInCognito': value['registerInCognito'],
        'createNewWallet': value['createNewWallet'],
        'walletData': value['walletData'],
    };
}

