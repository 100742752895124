/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CandidateRoleAcceptanceResponseDto,
  CredentialsIssuanceStartRequestDto,
  CredentialsIssuanceStatusDto,
  ResponseDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    CandidateRoleAcceptanceResponseDtoFromJSON,
    CandidateRoleAcceptanceResponseDtoToJSON,
    CredentialsIssuanceStartRequestDtoFromJSON,
    CredentialsIssuanceStartRequestDtoToJSON,
    CredentialsIssuanceStatusDtoFromJSON,
    CredentialsIssuanceStatusDtoToJSON,
    ResponseDtoFromJSON,
    ResponseDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface AcceptanceRequest {
    encryptedParams: string;
}

export interface RecipientSigningRequest {
    workflow?: string;
}

export interface Start4Request {
    credentialsIssuanceStartRequestDto: CredentialsIssuanceStartRequestDto;
}

export interface StatusRequest {
    workflow?: string;
}

/**
 * 
 */
export class CredentialsIssuanceControllerApi extends runtime.BaseAPI {

    /**
     * Should be used without security, params are encrypted. Used in email link.
     * Accept or reject from candidate
     */
    async acceptanceRaw(requestParameters: AcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidateRoleAcceptanceResponseDto>> {
        if (requestParameters['encryptedParams'] == null) {
            throw new runtime.RequiredError(
                'encryptedParams',
                'Required parameter "encryptedParams" was null or undefined when calling acceptance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/credential-issuance/acceptance/{encrypted-params}`.replace(`{${"encrypted-params"}}`, encodeURIComponent(String(requestParameters['encryptedParams']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateRoleAcceptanceResponseDtoFromJSON(jsonValue));
    }

    /**
     * Should be used without security, params are encrypted. Used in email link.
     * Accept or reject from candidate
     */
    async acceptance(requestParameters: AcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidateRoleAcceptanceResponseDto> {
        const response = await this.acceptanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registrate recipient signing and close the workflow
     * Recipient signing
     */
    async recipientSigningRaw(requestParameters: RecipientSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/credential-issuance/recipient-signing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Registrate recipient signing and close the workflow
     * Recipient signing
     */
    async recipientSigning(requestParameters: RecipientSigningRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.recipientSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and start new Credential Issuance Workflow Instance
     */
    async start4Raw(requestParameters: Start4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['credentialsIssuanceStartRequestDto'] == null) {
            throw new runtime.RequiredError(
                'credentialsIssuanceStartRequestDto',
                'Required parameter "credentialsIssuanceStartRequestDto" was null or undefined when calling start4().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/credential-issuance/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsIssuanceStartRequestDtoToJSON(requestParameters['credentialsIssuanceStartRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create and start new Credential Issuance Workflow Instance
     */
    async start4(requestParameters: Start4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.start4Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workflow status
     */
    async statusRaw(requestParameters: StatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CredentialsIssuanceStatusDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/credential-issuance/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialsIssuanceStatusDtoFromJSON(jsonValue));
    }

    /**
     * Workflow status
     */
    async status(requestParameters: StatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CredentialsIssuanceStatusDto> {
        const response = await this.statusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
