/* tslint:disable */
/* eslint-disable */
/**
 * Notification service API
 * Service for handling and managing notifications
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UINotificationResponseDto
 */
export interface UINotificationResponseDto {
    /**
     * Id of the specified notification message
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    id?: string;
    /**
     * Detailed text of UI notification
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    description?: string;
    /**
     * Short text of UI notification
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    title?: string;
    /**
     * When the UI notification was created by system
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    createdAt?: string;
    /**
     * User Id
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    userId?: string;
    /**
     * Notification channel - way to get to user
     * @type {string}
     * @memberof UINotificationResponseDto
     */
    channel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UINotificationResponseDto
     */
    read?: boolean;
}

/**
 * Check if a given object implements the UINotificationResponseDto interface.
 */
export function instanceOfUINotificationResponseDto(value: object): boolean {
    return true;
}

export function UINotificationResponseDtoFromJSON(json: any): UINotificationResponseDto {
    return UINotificationResponseDtoFromJSONTyped(json, false);
}

export function UINotificationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UINotificationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'title': json['title'] == null ? undefined : json['title'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'channel': json['channel'] == null ? undefined : json['channel'],
        'read': json['read'] == null ? undefined : json['read'],
    };
}

export function UINotificationResponseDtoToJSON(value?: UINotificationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
        'title': value['title'],
        'createdAt': value['createdAt'],
        'userId': value['userId'],
        'channel': value['channel'],
        'read': value['read'],
    };
}

