/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplaceQarRequest
 */
export interface ReplaceQarRequest {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof ReplaceQarRequest
     */
    workflowInstanceId: string;
    /**
     * User Id
     * @type {string}
     * @memberof ReplaceQarRequest
     */
    oldQarId: string;
    /**
     * User Id
     * @type {string}
     * @memberof ReplaceQarRequest
     */
    newQarId: string;
}

/**
 * Check if a given object implements the ReplaceQarRequest interface.
 */
export function instanceOfReplaceQarRequest(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('oldQarId' in value)) return false;
    if (!('newQarId' in value)) return false;
    return true;
}

export function ReplaceQarRequestFromJSON(json: any): ReplaceQarRequest {
    return ReplaceQarRequestFromJSONTyped(json, false);
}

export function ReplaceQarRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplaceQarRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'oldQarId': json['oldQarId'],
        'newQarId': json['newQarId'],
    };
}

export function ReplaceQarRequestToJSON(value?: ReplaceQarRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'oldQarId': value['oldQarId'],
        'newQarId': value['newQarId'],
    };
}

