/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QarInfo
 */
export interface QarInfo {
    /**
     * 
     * @type {string}
     * @memberof QarInfo
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof QarInfo
     */
    userName?: string;
}

/**
 * Check if a given object implements the QarInfo interface.
 */
export function instanceOfQarInfo(value: object): boolean {
    return true;
}

export function QarInfoFromJSON(json: any): QarInfo {
    return QarInfoFromJSONTyped(json, false);
}

export function QarInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QarInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
    };
}

export function QarInfoToJSON(value?: QarInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'userName': value['userName'],
    };
}

