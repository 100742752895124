/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Origin cell
 * @export
 * @interface CellDto
 */
export interface CellDto {
    /**
     * Cell name
     * @type {string}
     * @memberof CellDto
     */
    name?: string;
    /**
     * Cell base URL. All URLs start from it
     * @type {string}
     * @memberof CellDto
     */
    url?: string;
}

/**
 * Check if a given object implements the CellDto interface.
 */
export function instanceOfCellDto(value: object): boolean {
    return true;
}

export function CellDtoFromJSON(json: any): CellDto {
    return CellDtoFromJSONTyped(json, false);
}

export function CellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CellDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function CellDtoToJSON(value?: CellDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'url': value['url'],
    };
}

