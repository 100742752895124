/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPassword
 */
export interface SetPassword {
    /**
     * User Id
     * @type {string}
     * @memberof SetPassword
     */
    id?: string;
    /**
     * User's password
     * @type {string}
     * @memberof SetPassword
     */
    password: string;
    /**
     * User can use external or internal wallet. Or user can be demo user and theirs wallet can be created by demo-tools
     * @type {string}
     * @memberof SetPassword
     */
    realm?: SetPasswordRealmEnum;
    /**
     * URL of keria agent responsible for user's wallet
     * @type {string}
     * @memberof SetPassword
     */
    keriaAgentUrl?: string;
}


/**
 * @export
 */
export const SetPasswordRealmEnum = {
    Normal: 'NORMAL',
    Demo: 'DEMO',
    External: 'EXTERNAL'
} as const;
export type SetPasswordRealmEnum = typeof SetPasswordRealmEnum[keyof typeof SetPasswordRealmEnum];


/**
 * Check if a given object implements the SetPassword interface.
 */
export function instanceOfSetPassword(value: object): boolean {
    if (!('password' in value)) return false;
    return true;
}

export function SetPasswordFromJSON(json: any): SetPassword {
    return SetPasswordFromJSONTyped(json, false);
}

export function SetPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPassword {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'password': json['password'],
        'realm': json['realm'] == null ? undefined : json['realm'],
        'keriaAgentUrl': json['keriaAgentUrl'] == null ? undefined : json['keriaAgentUrl'],
    };
}

export function SetPasswordToJSON(value?: SetPassword | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'password': value['password'],
        'realm': value['realm'],
        'keriaAgentUrl': value['keriaAgentUrl'],
    };
}

