/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMultisigRequest,
  CreateMultisigResponse,
  MultisigMembersResponse,
} from '../models/index';
import {
    CreateMultisigRequestFromJSON,
    CreateMultisigRequestToJSON,
    CreateMultisigResponseFromJSON,
    CreateMultisigResponseToJSON,
    MultisigMembersResponseFromJSON,
    MultisigMembersResponseToJSON,
} from '../models/index';

export interface CreateMultisigOperationRequest {
    createMultisigRequest: CreateMultisigRequest;
}

export interface GetByMultisigAidRequest {
    aid: string;
}

/**
 * 
 */
export class MultisigControllerApi extends runtime.BaseAPI {

    /**
     * Save multisig aid and its members
     */
    async createMultisigRaw(requestParameters: CreateMultisigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateMultisigResponse>> {
        if (requestParameters['createMultisigRequest'] == null) {
            throw new runtime.RequiredError(
                'createMultisigRequest',
                'Required parameter "createMultisigRequest" was null or undefined when calling createMultisig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/multisig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMultisigRequestToJSON(requestParameters['createMultisigRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMultisigResponseFromJSON(jsonValue));
    }

    /**
     * Save multisig aid and its members
     */
    async createMultisig(requestParameters: CreateMultisigOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateMultisigResponse> {
        const response = await this.createMultisigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multisig members
     */
    async getByMultisigAidRaw(requestParameters: GetByMultisigAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultisigMembersResponse>> {
        if (requestParameters['aid'] == null) {
            throw new runtime.RequiredError(
                'aid',
                'Required parameter "aid" was null or undefined when calling getByMultisigAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/multisig/{aid}/members`.replace(`{${"aid"}}`, encodeURIComponent(String(requestParameters['aid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultisigMembersResponseFromJSON(jsonValue));
    }

    /**
     * Get multisig members
     */
    async getByMultisigAid(requestParameters: GetByMultisigAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultisigMembersResponse | null | undefined > {
        const response = await this.getByMultisigAidRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
