/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrandDto,
  BrandEmailDto,
  BrandLinksResponse,
} from '../models/index';
import {
    BrandDtoFromJSON,
    BrandDtoToJSON,
    BrandEmailDtoFromJSON,
    BrandEmailDtoToJSON,
    BrandLinksResponseFromJSON,
    BrandLinksResponseToJSON,
} from '../models/index';

export interface FindBrand1Request {
    brandId: string;
}

export interface FindBrandEmailInfo1Request {
    brandId: string;
    emailTypeId: string;
}

export interface FindBrandEmailLinks1Request {
    brandId: string;
}

/**
 * 
 */
export class BrandControllerInternalApi extends runtime.BaseAPI {

    /**
     */
    async findBrand1Raw(requestParameters: FindBrand1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandDto>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrand1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/.internal/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandDtoFromJSON(jsonValue));
    }

    /**
     */
    async findBrand1(requestParameters: FindBrand1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandDto> {
        const response = await this.findBrand1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBrandEmailInfo1Raw(requestParameters: FindBrandEmailInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandEmailDto>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrandEmailInfo1().'
            );
        }

        if (requestParameters['emailTypeId'] == null) {
            throw new runtime.RequiredError(
                'emailTypeId',
                'Required parameter "emailTypeId" was null or undefined when calling findBrandEmailInfo1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/.internal/{brandId}/email-info/{emailTypeId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))).replace(`{${"emailTypeId"}}`, encodeURIComponent(String(requestParameters['emailTypeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async findBrandEmailInfo1(requestParameters: FindBrandEmailInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandEmailDto> {
        const response = await this.findBrandEmailInfo1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBrandEmailLinks1Raw(requestParameters: FindBrandEmailLinks1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandLinksResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrandEmailLinks1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/.internal/{brandId}/email-links`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandLinksResponseFromJSON(jsonValue));
    }

    /**
     */
    async findBrandEmailLinks1(requestParameters: FindBrandEmailLinks1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandLinksResponse> {
        const response = await this.findBrandEmailLinks1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
