/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortingItem } from './SortingItem';
import {
    SortingItemFromJSON,
    SortingItemFromJSONTyped,
    SortingItemToJSON,
} from './SortingItem';

/**
 * 
 * @export
 * @interface GettingInstancesRequest
 */
export interface GettingInstancesRequest {
    /**
     * Any string for fuzzy search in several columns
     * @type {string}
     * @memberof GettingInstancesRequest
     */
    fuzzySearch?: string;
    /**
     * Filters for different parameters of instances. Possible values for keys: "WORKFLOW_INSTANCE_ID", "DATE_REQUESTED", "IN_PROGRESS", "CREDENTIALS", "ORG_ID", "REQUESTOR", "STATUS
     * @type {{ [key: string]: string; }}
     * @memberof GettingInstancesRequest
     */
    instanceFilters?: { [key: string]: string; };
    /**
     * If true - endpoint will return workflow instances in active statuses only, if false in completed/canceled/terminated. If null - filter is disabled
     * @type {boolean}
     * @memberof GettingInstancesRequest
     */
    activeStatuses?: boolean;
    /**
     * Interval for started at parameter. Should contain 0, 1 or 2 items - left and right bounds. If any bound is not defined, is is not used. Can have null in first or second item
     * @type {Array<string>}
     * @memberof GettingInstancesRequest
     */
    startedAtInterval?: Array<string>;
    /**
     * 
     * @type {Array<SortingItem>}
     * @memberof GettingInstancesRequest
     */
    sortingItems?: Array<SortingItem>;
    /**
     * Result offset
     * @type {number}
     * @memberof GettingInstancesRequest
     */
    offset?: number;
    /**
     * Maximum number of returned values in result. Has default value
     * @type {number}
     * @memberof GettingInstancesRequest
     */
    limit?: number;
}

/**
 * Check if a given object implements the GettingInstancesRequest interface.
 */
export function instanceOfGettingInstancesRequest(value: object): boolean {
    return true;
}

export function GettingInstancesRequestFromJSON(json: any): GettingInstancesRequest {
    return GettingInstancesRequestFromJSONTyped(json, false);
}

export function GettingInstancesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GettingInstancesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'fuzzySearch': json['Fuzzy search'] == null ? undefined : json['Fuzzy search'],
        'instanceFilters': json['Instance filters'] == null ? undefined : json['Instance filters'],
        'activeStatuses': json['activeStatuses'] == null ? undefined : json['activeStatuses'],
        'startedAtInterval': json['startedAtInterval'] == null ? undefined : json['startedAtInterval'],
        'sortingItems': json['sortingItems'] == null ? undefined : ((json['sortingItems'] as Array<any>).map(SortingItemFromJSON)),
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
    };
}

export function GettingInstancesRequestToJSON(value?: GettingInstancesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Fuzzy search': value['fuzzySearch'],
        'Instance filters': value['instanceFilters'],
        'activeStatuses': value['activeStatuses'],
        'startedAtInterval': value['startedAtInterval'],
        'sortingItems': value['sortingItems'] == null ? undefined : ((value['sortingItems'] as Array<any>).map(SortingItemToJSON)),
        'offset': value['offset'],
        'limit': value['limit'],
    };
}

