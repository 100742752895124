/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRoleRequestDto
 */
export interface CreateRoleRequestDto {
    /**
     * User Id
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    userId: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    orgId: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    grant: string;
    /**
     * User Id
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    proposedById: string;
    /**
     * User Id
     * @type {string}
     * @memberof CreateRoleRequestDto
     */
    approvedById: string;
    /**
     * Grand can be accepted rejected or in progress (null value)
     * @type {boolean}
     * @memberof CreateRoleRequestDto
     */
    accepted: boolean;
}

/**
 * Check if a given object implements the CreateRoleRequestDto interface.
 */
export function instanceOfCreateRoleRequestDto(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('orgId' in value)) return false;
    if (!('grant' in value)) return false;
    if (!('proposedById' in value)) return false;
    if (!('approvedById' in value)) return false;
    if (!('accepted' in value)) return false;
    return true;
}

export function CreateRoleRequestDtoFromJSON(json: any): CreateRoleRequestDto {
    return CreateRoleRequestDtoFromJSONTyped(json, false);
}

export function CreateRoleRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoleRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'orgId': json['orgId'],
        'grant': json['grant'],
        'proposedById': json['proposedById'],
        'approvedById': json['approvedById'],
        'accepted': json['accepted'],
    };
}

export function CreateRoleRequestDtoToJSON(value?: CreateRoleRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'orgId': value['orgId'],
        'grant': value['grant'],
        'proposedById': value['proposedById'],
        'approvedById': value['approvedById'],
        'accepted': value['accepted'],
    };
}

