/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Basic aid parameters
 * @export
 * @interface AidDto
 */
export interface AidDto {
    /**
     * Autonomic Identifier
     * @type {string}
     * @memberof AidDto
     */
    aid?: string;
    /**
     * Human-readable Identifier for AID
     * @type {string}
     * @memberof AidDto
     */
    alias?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AidDto
     */
    oobi?: Array<string>;
    /**
     * User Id
     * @type {string}
     * @memberof AidDto
     */
    userId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof AidDto
     */
    orgId?: string;
    /**
     * Type of the AID
     * @type {string}
     * @memberof AidDto
     */
    type?: string;
    /**
     * Number of users required for signing. Used for multisigs only
     * @type {string}
     * @memberof AidDto
     */
    signingThreshold?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AidDto
     */
    multisig?: boolean;
}

/**
 * Check if a given object implements the AidDto interface.
 */
export function instanceOfAidDto(value: object): boolean {
    return true;
}

export function AidDtoFromJSON(json: any): AidDto {
    return AidDtoFromJSONTyped(json, false);
}

export function AidDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AidDto {
    if (json == null) {
        return json;
    }
    return {
        
        'aid': json['aid'] == null ? undefined : json['aid'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'oobi': json['oobi'] == null ? undefined : json['oobi'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'type': json['type'] == null ? undefined : json['type'],
        'signingThreshold': json['signingThreshold'] == null ? undefined : json['signingThreshold'],
        'multisig': json['multisig'] == null ? undefined : json['multisig'],
    };
}

export function AidDtoToJSON(value?: AidDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aid': value['aid'],
        'alias': value['alias'],
        'oobi': value['oobi'],
        'userId': value['userId'],
        'orgId': value['orgId'],
        'type': value['type'],
        'signingThreshold': value['signingThreshold'],
        'multisig': value['multisig'],
    };
}

