/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultisigMemberDto
 */
export interface MultisigMemberDto {
    /**
     * User Id
     * @type {string}
     * @memberof MultisigMemberDto
     */
    userId?: string;
    /**
     * Autonomic Identifier
     * @type {string}
     * @memberof MultisigMemberDto
     */
    aid?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof MultisigMemberDto
     */
    orgId?: string;
    /**
     * Autonomic Identifier
     * @type {string}
     * @memberof MultisigMemberDto
     */
    memberAid?: string;
}

/**
 * Check if a given object implements the MultisigMemberDto interface.
 */
export function instanceOfMultisigMemberDto(value: object): boolean {
    return true;
}

export function MultisigMemberDtoFromJSON(json: any): MultisigMemberDto {
    return MultisigMemberDtoFromJSONTyped(json, false);
}

export function MultisigMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultisigMemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'aid': json['aid'] == null ? undefined : json['aid'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'memberAid': json['memberAid'] == null ? undefined : json['memberAid'],
    };
}

export function MultisigMemberDtoToJSON(value?: MultisigMemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'aid': value['aid'],
        'orgId': value['orgId'],
        'memberAid': value['memberAid'],
    };
}

