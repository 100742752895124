/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateNativeAidRequest } from './CreateNativeAidRequest';
import {
    CreateNativeAidRequestFromJSON,
    CreateNativeAidRequestFromJSONTyped,
    CreateNativeAidRequestToJSON,
} from './CreateNativeAidRequest';

/**
 * 
 * @export
 * @interface CreateMultisigRequest
 */
export interface CreateMultisigRequest {
    /**
     * 
     * @type {CreateNativeAidRequest}
     * @memberof CreateMultisigRequest
     */
    multisigAid?: CreateNativeAidRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMultisigRequest
     */
    memberAIDs: Array<string>;
}

/**
 * Check if a given object implements the CreateMultisigRequest interface.
 */
export function instanceOfCreateMultisigRequest(value: object): boolean {
    if (!('memberAIDs' in value)) return false;
    return true;
}

export function CreateMultisigRequestFromJSON(json: any): CreateMultisigRequest {
    return CreateMultisigRequestFromJSONTyped(json, false);
}

export function CreateMultisigRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMultisigRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'multisigAid': json['multisigAid'] == null ? undefined : CreateNativeAidRequestFromJSON(json['multisigAid']),
        'memberAIDs': json['memberAIDs'],
    };
}

export function CreateMultisigRequestToJSON(value?: CreateMultisigRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'multisigAid': CreateNativeAidRequestToJSON(value['multisigAid']),
        'memberAIDs': value['memberAIDs'],
    };
}

