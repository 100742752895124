/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EcrRemovalResponseDto
 */
export interface EcrRemovalResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof EcrRemovalResponseDto
     */
    workflowInstanceId?: string;
    /**
     * Workflow task actor id that is used for this operation
     * @type {string}
     * @memberof EcrRemovalResponseDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * User Id
     * @type {string}
     * @memberof EcrRemovalResponseDto
     */
    requesterId?: string;
}

/**
 * Check if a given object implements the EcrRemovalResponseDto interface.
 */
export function instanceOfEcrRemovalResponseDto(value: object): boolean {
    return true;
}

export function EcrRemovalResponseDtoFromJSON(json: any): EcrRemovalResponseDto {
    return EcrRemovalResponseDtoFromJSONTyped(json, false);
}

export function EcrRemovalResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcrRemovalResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'requesterId': json['requesterId'] == null ? undefined : json['requesterId'],
    };
}

export function EcrRemovalResponseDtoToJSON(value?: EcrRemovalResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'requesterId': value['requesterId'],
    };
}

