/* tslint:disable */
/* eslint-disable */
/**
 * Localization service API
 * Service for localization Origin messages
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolicTextIdToLocalizedText
 */
export interface SymbolicTextIdToLocalizedText {
    /**
     * 
     * @type {string}
     * @memberof SymbolicTextIdToLocalizedText
     */
    createLeVleiPrerequisites?: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolicTextIdToLocalizedText
     */
    createOorVleiPrerequisites?: string;
}

/**
 * Check if a given object implements the SymbolicTextIdToLocalizedText interface.
 */
export function instanceOfSymbolicTextIdToLocalizedText(value: object): boolean {
    return true;
}

export function SymbolicTextIdToLocalizedTextFromJSON(json: any): SymbolicTextIdToLocalizedText {
    return SymbolicTextIdToLocalizedTextFromJSONTyped(json, false);
}

export function SymbolicTextIdToLocalizedTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolicTextIdToLocalizedText {
    if (json == null) {
        return json;
    }
    return {
        
        'createLeVleiPrerequisites': json['create-le-vlei-prerequisites'] == null ? undefined : json['create-le-vlei-prerequisites'],
        'createOorVleiPrerequisites': json['create-oor-vlei-prerequisites'] == null ? undefined : json['create-oor-vlei-prerequisites'],
    };
}

export function SymbolicTextIdToLocalizedTextToJSON(value?: SymbolicTextIdToLocalizedText | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'create-le-vlei-prerequisites': value['createLeVleiPrerequisites'],
        'create-oor-vlei-prerequisites': value['createOorVleiPrerequisites'],
    };
}

