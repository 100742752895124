/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowTaskActorStatus } from './WorkflowTaskActorStatus';
import {
    WorkflowTaskActorStatusFromJSON,
    WorkflowTaskActorStatusFromJSONTyped,
    WorkflowTaskActorStatusToJSON,
} from './WorkflowTaskActorStatus';

/**
 * 
 * @export
 * @interface WorkflowTaskActorDto
 */
export interface WorkflowTaskActorDto {
    /**
     * The workflow task actor Id
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    id?: string;
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {WorkflowTaskActorStatus}
     * @memberof WorkflowTaskActorDto
     */
    status?: WorkflowTaskActorStatus;
    /**
     * User Id
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    userId?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    userName?: string;
    /**
     * Task Type for Workflow participants. Set of task types is different for each workflow model
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    taskType?: string;
    /**
     * Localizable name of the item
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    symbolicTextId?: string;
}

/**
 * Check if a given object implements the WorkflowTaskActorDto interface.
 */
export function instanceOfWorkflowTaskActorDto(value: object): boolean {
    return true;
}

export function WorkflowTaskActorDtoFromJSON(json: any): WorkflowTaskActorDto {
    return WorkflowTaskActorDtoFromJSONTyped(json, false);
}

export function WorkflowTaskActorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTaskActorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'status': json['status'] == null ? undefined : WorkflowTaskActorStatusFromJSON(json['status']),
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
        'symbolicTextId': json['symbolicTextId'] == null ? undefined : json['symbolicTextId'],
    };
}

export function WorkflowTaskActorDtoToJSON(value?: WorkflowTaskActorDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'workflowInstanceId': value['workflowInstanceId'],
        'status': WorkflowTaskActorStatusToJSON(value['status']),
        'userId': value['userId'],
        'userName': value['userName'],
        'taskType': value['taskType'],
        'symbolicTextId': value['symbolicTextId'],
    };
}

