/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QviOrgIdResponseDto
 */
export interface QviOrgIdResponseDto {
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof QviOrgIdResponseDto
     */
    orgId?: string;
}

/**
 * Check if a given object implements the QviOrgIdResponseDto interface.
 */
export function instanceOfQviOrgIdResponseDto(value: object): boolean {
    return true;
}

export function QviOrgIdResponseDtoFromJSON(json: any): QviOrgIdResponseDto {
    return QviOrgIdResponseDtoFromJSONTyped(json, false);
}

export function QviOrgIdResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QviOrgIdResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
    };
}

export function QviOrgIdResponseDtoToJSON(value?: QviOrgIdResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orgId': value['orgId'],
    };
}

