/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserPhoneNumberDto } from './UserPhoneNumberDto';
import {
    UserPhoneNumberDtoFromJSON,
    UserPhoneNumberDtoFromJSONTyped,
    UserPhoneNumberDtoToJSON,
} from './UserPhoneNumberDto';

/**
 * 
 * @export
 * @interface RequesterInfoResponseDto
 */
export interface RequesterInfoResponseDto {
    /**
     * User Id
     * @type {string}
     * @memberof RequesterInfoResponseDto
     */
    userId?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof RequesterInfoResponseDto
     */
    userName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequesterInfoResponseDto
     */
    emails?: Array<string>;
    /**
     * 
     * @type {Array<UserPhoneNumberDto>}
     * @memberof RequesterInfoResponseDto
     */
    phoneNumbers?: Array<UserPhoneNumberDto>;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof RequesterInfoResponseDto
     */
    orgId?: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof RequesterInfoResponseDto
     */
    orgName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequesterInfoResponseDto
     */
    acceptedAndApprovedGrants?: Array<string>;
    /**
     * State of id verification for the specified user
     * @type {string}
     * @memberof RequesterInfoResponseDto
     */
    idVerificationStatus?: RequesterInfoResponseDtoIdVerificationStatusEnum;
}


/**
 * @export
 */
export const RequesterInfoResponseDtoIdVerificationStatusEnum = {
    PreAssigned: 'PRE_ASSIGNED',
    Assigned: 'ASSIGNED',
    InProgress: 'IN_PROGRESS',
    QarRejected: 'QAR_REJECTED',
    Rejected: 'REJECTED',
    Failed: 'FAILED',
    Complete: 'COMPLETE'
} as const;
export type RequesterInfoResponseDtoIdVerificationStatusEnum = typeof RequesterInfoResponseDtoIdVerificationStatusEnum[keyof typeof RequesterInfoResponseDtoIdVerificationStatusEnum];


/**
 * Check if a given object implements the RequesterInfoResponseDto interface.
 */
export function instanceOfRequesterInfoResponseDto(value: object): boolean {
    return true;
}

export function RequesterInfoResponseDtoFromJSON(json: any): RequesterInfoResponseDto {
    return RequesterInfoResponseDtoFromJSONTyped(json, false);
}

export function RequesterInfoResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequesterInfoResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'emails': json['emails'] == null ? undefined : json['emails'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(UserPhoneNumberDtoFromJSON)),
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'orgName': json['orgName'] == null ? undefined : json['orgName'],
        'acceptedAndApprovedGrants': json['acceptedAndApprovedGrants'] == null ? undefined : json['acceptedAndApprovedGrants'],
        'idVerificationStatus': json['idVerificationStatus'] == null ? undefined : json['idVerificationStatus'],
    };
}

export function RequesterInfoResponseDtoToJSON(value?: RequesterInfoResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'userName': value['userName'],
        'emails': value['emails'],
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(UserPhoneNumberDtoToJSON)),
        'orgId': value['orgId'],
        'orgName': value['orgName'],
        'acceptedAndApprovedGrants': value['acceptedAndApprovedGrants'],
        'idVerificationStatus': value['idVerificationStatus'],
    };
}

