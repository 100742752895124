/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EcrPrerequisitesRequestDto
 */
export interface EcrPrerequisitesRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EcrPrerequisitesRequestDto
     * @deprecated
     */
    modelId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof EcrPrerequisitesRequestDto
     */
    orgId?: string;
    /**
     * ECR Workflow mode
     * @type {string}
     * @memberof EcrPrerequisitesRequestDto
     */
    ecrWorkflowMode?: EcrPrerequisitesRequestDtoEcrWorkflowModeEnum;
}


/**
 * @export
 */
export const EcrPrerequisitesRequestDtoEcrWorkflowModeEnum = {
    QviIssued: 'QVI_ISSUED',
    SelfIssued: 'SELF_ISSUED'
} as const;
export type EcrPrerequisitesRequestDtoEcrWorkflowModeEnum = typeof EcrPrerequisitesRequestDtoEcrWorkflowModeEnum[keyof typeof EcrPrerequisitesRequestDtoEcrWorkflowModeEnum];


/**
 * Check if a given object implements the EcrPrerequisitesRequestDto interface.
 */
export function instanceOfEcrPrerequisitesRequestDto(value: object): boolean {
    return true;
}

export function EcrPrerequisitesRequestDtoFromJSON(json: any): EcrPrerequisitesRequestDto {
    return EcrPrerequisitesRequestDtoFromJSONTyped(json, false);
}

export function EcrPrerequisitesRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcrPrerequisitesRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'modelId': json['modelId'] == null ? undefined : json['modelId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'ecrWorkflowMode': json['ecrWorkflowMode'] == null ? undefined : json['ecrWorkflowMode'],
    };
}

export function EcrPrerequisitesRequestDtoToJSON(value?: EcrPrerequisitesRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modelId': value['modelId'],
        'orgId': value['orgId'],
        'ecrWorkflowMode': value['ecrWorkflowMode'],
    };
}

