/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Org onboarding state
 * @export
 */
export const OrganizationStatus = {
    Init: 'INIT',
    Complete: 'COMPLETE'
} as const;
export type OrganizationStatus = typeof OrganizationStatus[keyof typeof OrganizationStatus];


export function instanceOfOrganizationStatus(value: any): boolean {
    return Object.values(OrganizationStatus).includes(value);
}

export function OrganizationStatusFromJSON(json: any): OrganizationStatus {
    return OrganizationStatusFromJSONTyped(json, false);
}

export function OrganizationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationStatus {
    return json as OrganizationStatus;
}

export function OrganizationStatusToJSON(value?: OrganizationStatus | null): any {
    return value as any;
}

