/* tslint:disable */
/* eslint-disable */
/**
 * Localization service API
 * Service for localization Origin messages
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SymbolicTextIdToLocalizedText } from './SymbolicTextIdToLocalizedText';
import {
    SymbolicTextIdToLocalizedTextFromJSON,
    SymbolicTextIdToLocalizedTextFromJSONTyped,
    SymbolicTextIdToLocalizedTextToJSON,
} from './SymbolicTextIdToLocalizedText';

/**
 * 
 * @export
 * @interface L10nTextBatchResponseDto
 */
export interface L10nTextBatchResponseDto {
    /**
     * 
     * @type {SymbolicTextIdToLocalizedText}
     * @memberof L10nTextBatchResponseDto
     */
    localizedValues?: SymbolicTextIdToLocalizedText;
    /**
     * 
     * @type {string}
     * @memberof L10nTextBatchResponseDto
     */
    lang?: string;
}

/**
 * Check if a given object implements the L10nTextBatchResponseDto interface.
 */
export function instanceOfL10nTextBatchResponseDto(value: object): boolean {
    return true;
}

export function L10nTextBatchResponseDtoFromJSON(json: any): L10nTextBatchResponseDto {
    return L10nTextBatchResponseDtoFromJSONTyped(json, false);
}

export function L10nTextBatchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): L10nTextBatchResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'localizedValues': json['localizedValues'] == null ? undefined : SymbolicTextIdToLocalizedTextFromJSON(json['localizedValues']),
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}

export function L10nTextBatchResponseDtoToJSON(value?: L10nTextBatchResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'localizedValues': SymbolicTextIdToLocalizedTextToJSON(value['localizedValues']),
        'lang': value['lang'],
    };
}

