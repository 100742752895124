/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Meeting timeslot, defined by start and and time
 * @export
 * @interface TimeSlotDto
 */
export interface TimeSlotDto {
    /**
     * Start of timeslot
     * @type {string}
     * @memberof TimeSlotDto
     */
    start?: string;
    /**
     * End of timeslot
     * @type {string}
     * @memberof TimeSlotDto
     */
    end?: string;
}

/**
 * Check if a given object implements the TimeSlotDto interface.
 */
export function instanceOfTimeSlotDto(value: object): boolean {
    return true;
}

export function TimeSlotDtoFromJSON(json: any): TimeSlotDto {
    return TimeSlotDtoFromJSONTyped(json, false);
}

export function TimeSlotDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSlotDto {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'] == null ? undefined : json['start'],
        'end': json['end'] == null ? undefined : json['end'],
    };
}

export function TimeSlotDtoToJSON(value?: TimeSlotDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': value['start'],
        'end': value['end'],
    };
}

