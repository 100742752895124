/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserVerificationProcessDto
 */
export interface UserVerificationProcessDto {
    /**
     * State of verification transaction can be requested by this id for the current user
     * @type {string}
     * @memberof UserVerificationProcessDto
     */
    verificationTransactionId?: string;
    /**
     * User Id
     * @type {string}
     * @memberof UserVerificationProcessDto
     */
    userId?: string;
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof UserVerificationProcessDto
     */
    workflowInstanceId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof UserVerificationProcessDto
     */
    orgId?: string;
    /**
     * Status of the verification transaction
     * @type {string}
     * @memberof UserVerificationProcessDto
     */
    status?: UserVerificationProcessDtoStatusEnum;
}


/**
 * @export
 */
export const UserVerificationProcessDtoStatusEnum = {
    Pending: 'PENDING',
    InProgress: 'IN_PROGRESS',
    Submitted: 'SUBMITTED',
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    Cancelled: 'CANCELLED'
} as const;
export type UserVerificationProcessDtoStatusEnum = typeof UserVerificationProcessDtoStatusEnum[keyof typeof UserVerificationProcessDtoStatusEnum];


/**
 * Check if a given object implements the UserVerificationProcessDto interface.
 */
export function instanceOfUserVerificationProcessDto(value: object): boolean {
    return true;
}

export function UserVerificationProcessDtoFromJSON(json: any): UserVerificationProcessDto {
    return UserVerificationProcessDtoFromJSONTyped(json, false);
}

export function UserVerificationProcessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserVerificationProcessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'verificationTransactionId': json['verificationTransactionId'] == null ? undefined : json['verificationTransactionId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UserVerificationProcessDtoToJSON(value?: UserVerificationProcessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'verificationTransactionId': value['verificationTransactionId'],
        'userId': value['userId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'status': value['status'],
    };
}

