/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmPassword,
  ConfirmResponse,
  JoinOrganizationLookup,
  NewPersonaDto,
  Onboard,
  ResendConfirmationCodeRequest,
  ResendConfirmationCodeResponse,
  SetPassword,
  SetProfile,
  SuccessResponseOrganizationDto,
  SuccessResponseVoid,
  Wallet,
} from '../models/index';
import {
    ConfirmPasswordFromJSON,
    ConfirmPasswordToJSON,
    ConfirmResponseFromJSON,
    ConfirmResponseToJSON,
    JoinOrganizationLookupFromJSON,
    JoinOrganizationLookupToJSON,
    NewPersonaDtoFromJSON,
    NewPersonaDtoToJSON,
    OnboardFromJSON,
    OnboardToJSON,
    ResendConfirmationCodeRequestFromJSON,
    ResendConfirmationCodeRequestToJSON,
    ResendConfirmationCodeResponseFromJSON,
    ResendConfirmationCodeResponseToJSON,
    SetPasswordFromJSON,
    SetPasswordToJSON,
    SetProfileFromJSON,
    SetProfileToJSON,
    SuccessResponseOrganizationDtoFromJSON,
    SuccessResponseOrganizationDtoToJSON,
    SuccessResponseVoidFromJSON,
    SuccessResponseVoidToJSON,
    WalletFromJSON,
    WalletToJSON,
} from '../models/index';

export interface ConfirmRequest {
    encryptedParams: string;
}

export interface ConfirmPasswordRequest {
    confirmPassword: ConfirmPassword;
}

export interface JoinOrganizationRequest {
    joinOrganizationLookup: JoinOrganizationLookup;
}

export interface NewPersonaRequest {
    newPersonaDto: NewPersonaDto;
}

export interface OnboardRequest {
    onboard: Onboard;
}

export interface ResendConfirmationCodeOperationRequest {
    resendConfirmationCodeRequest: ResendConfirmationCodeRequest;
}

export interface SaveWalletRequest {
    wallet: Wallet;
}

export interface SendResetPasswordLinkRequest {
    joinOrganizationLookup: JoinOrganizationLookup;
}

export interface SetPasswordRequest {
    setPassword: SetPassword;
}

export interface SetProfileRequest {
    setProfile: SetProfile;
}

/**
 * 
 */
export class OnboardControllerApi extends runtime.BaseAPI {

    /**
     * Confirm invite. Called from emails
     * Confirm
     */
    async confirmRaw(requestParameters: ConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmResponse>> {
        if (requestParameters['encryptedParams'] == null) {
            throw new runtime.RequiredError(
                'encryptedParams',
                'Required parameter "encryptedParams" was null or undefined when calling confirm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/auth/confirm/{encrypted-params}`.replace(`{${"encrypted-params"}}`, encodeURIComponent(String(requestParameters['encryptedParams']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmResponseFromJSON(jsonValue));
    }

    /**
     * Confirm invite. Called from emails
     * Confirm
     */
    async confirm(requestParameters: ConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmResponse> {
        const response = await this.confirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Password confirmation
     */
    async confirmPasswordRaw(requestParameters: ConfirmPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['confirmPassword'] == null) {
            throw new runtime.RequiredError(
                'confirmPassword',
                'Required parameter "confirmPassword" was null or undefined when calling confirmPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPasswordToJSON(requestParameters['confirmPassword']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * Password confirmation
     */
    async confirmPassword(requestParameters: ConfirmPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.confirmPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Second way to onboard: join already existed organization
     * Join org
     */
    async joinOrganizationRaw(requestParameters: JoinOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseOrganizationDto>> {
        if (requestParameters['joinOrganizationLookup'] == null) {
            throw new runtime.RequiredError(
                'joinOrganizationLookup',
                'Required parameter "joinOrganizationLookup" was null or undefined when calling joinOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/join`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinOrganizationLookupToJSON(requestParameters['joinOrganizationLookup']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseOrganizationDtoFromJSON(jsonValue));
    }

    /**
     * Second way to onboard: join already existed organization
     * Join org
     */
    async joinOrganization(requestParameters: JoinOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseOrganizationDto> {
        const response = await this.joinOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create persona users for demo
     * Create new persona (for demo)
     */
    async newPersonaRaw(requestParameters: NewPersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['newPersonaDto'] == null) {
            throw new runtime.RequiredError(
                'newPersonaDto',
                'Required parameter "newPersonaDto" was null or undefined when calling newPersona().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/new-persona`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPersonaDtoToJSON(requestParameters['newPersonaDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create persona users for demo
     * Create new persona (for demo)
     */
    async newPersona(requestParameters: NewPersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.newPersonaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * First onboard request
     * Start onboarding process
     */
    async onboardRaw(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['onboard'] == null) {
            throw new runtime.RequiredError(
                'onboard',
                'Required parameter "onboard" was null or undefined when calling onboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/onboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardToJSON(requestParameters['onboard']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * First onboard request
     * Start onboarding process
     */
    async onboard(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.onboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend confirmation code to user\'s email in case if first email is lost
     * Resend confirmation code
     */
    async resendConfirmationCodeRaw(requestParameters: ResendConfirmationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResendConfirmationCodeResponse>> {
        if (requestParameters['resendConfirmationCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'resendConfirmationCodeRequest',
                'Required parameter "resendConfirmationCodeRequest" was null or undefined when calling resendConfirmationCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/resend-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendConfirmationCodeRequestToJSON(requestParameters['resendConfirmationCodeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResendConfirmationCodeResponseFromJSON(jsonValue));
    }

    /**
     * Resend confirmation code to user\'s email in case if first email is lost
     * Resend confirmation code
     */
    async resendConfirmationCode(requestParameters: ResendConfirmationCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResendConfirmationCodeResponse> {
        const response = await this.resendConfirmationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save Wallet while onboarding
     * Save Wallet
     */
    async saveWalletRaw(requestParameters: SaveWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['wallet'] == null) {
            throw new runtime.RequiredError(
                'wallet',
                'Required parameter "wallet" was null or undefined when calling saveWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WalletToJSON(requestParameters['wallet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * Save Wallet while onboarding
     * Save Wallet
     */
    async saveWallet(requestParameters: SaveWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.saveWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send reset password link
     * Password reset
     */
    async sendResetPasswordLinkRaw(requestParameters: SendResetPasswordLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['joinOrganizationLookup'] == null) {
            throw new runtime.RequiredError(
                'joinOrganizationLookup',
                'Required parameter "joinOrganizationLookup" was null or undefined when calling sendResetPasswordLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinOrganizationLookupToJSON(requestParameters['joinOrganizationLookup']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * Send reset password link
     * Password reset
     */
    async sendResetPasswordLink(requestParameters: SendResetPasswordLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.sendResetPasswordLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets user password while onboarding
     * Set password
     */
    async setPasswordRaw(requestParameters: SetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['setPassword'] == null) {
            throw new runtime.RequiredError(
                'setPassword',
                'Required parameter "setPassword" was null or undefined when calling setPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordToJSON(requestParameters['setPassword']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * Sets user password while onboarding
     * Set password
     */
    async setPassword(requestParameters: SetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.setPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set user profile basic parameters
     * Set profile
     */
    async setProfileRaw(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseVoid>> {
        if (requestParameters['setProfile'] == null) {
            throw new runtime.RequiredError(
                'setProfile',
                'Required parameter "setProfile" was null or undefined when calling setProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetProfileToJSON(requestParameters['setProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseVoidFromJSON(jsonValue));
    }

    /**
     * Set user profile basic parameters
     * Set profile
     */
    async setProfile(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseVoid> {
        const response = await this.setProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
