/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Expiration } from './Expiration';
import {
    ExpirationFromJSON,
    ExpirationFromJSONTyped,
    ExpirationToJSON,
} from './Expiration';
import type { OrganizationStatus } from './OrganizationStatus';
import {
    OrganizationStatusFromJSON,
    OrganizationStatusFromJSONTyped,
    OrganizationStatusToJSON,
} from './OrganizationStatus';
import type { QarOrgDetailResponseDto } from './QarOrgDetailResponseDto';
import {
    QarOrgDetailResponseDtoFromJSON,
    QarOrgDetailResponseDtoFromJSONTyped,
    QarOrgDetailResponseDtoToJSON,
} from './QarOrgDetailResponseDto';
import type { RequesterInfoResponseDto } from './RequesterInfoResponseDto';
import {
    RequesterInfoResponseDtoFromJSON,
    RequesterInfoResponseDtoFromJSONTyped,
    RequesterInfoResponseDtoToJSON,
} from './RequesterInfoResponseDto';
import type { UserInOrgAndGrantResponseDto } from './UserInOrgAndGrantResponseDto';
import {
    UserInOrgAndGrantResponseDtoFromJSON,
    UserInOrgAndGrantResponseDtoFromJSONTyped,
    UserInOrgAndGrantResponseDtoToJSON,
} from './UserInOrgAndGrantResponseDto';
import type { WorkflowInstanceStatus } from './WorkflowInstanceStatus';
import {
    WorkflowInstanceStatusFromJSON,
    WorkflowInstanceStatusFromJSONTyped,
    WorkflowInstanceStatusToJSON,
} from './WorkflowInstanceStatus';

/**
 * Org details for which the workflow is done
 * @export
 * @interface OrgDetailResponseDto
 */
export interface OrgDetailResponseDto {
    /**
     * 
     * @type {WorkflowInstanceStatus}
     * @memberof OrgDetailResponseDto
     */
    workflowInstanceStatus?: WorkflowInstanceStatus;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    id?: string;
    /**
     * Name of workflow model
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    workflowModelId?: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    name?: string;
    /**
     * Org emails domain
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    domain?: string;
    /**
     * Brand Id for organization branding. Several orgs can share one brand.
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    brandId?: string;
    /**
     * User Id
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    createdByUser?: string;
    /**
     * Date when the instance was created
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     * @deprecated
     */
    orgType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgDetailResponseDto
     */
    publicRecordLinks?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    legalCompanyName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    legalEntityName?: string;
    /**
     * 
     * @type {OrganizationStatus}
     * @memberof OrgDetailResponseDto
     */
    status?: OrganizationStatus;
    /**
     * Org lei
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    lei?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    dbaOrBrandName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    altBusiness?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    ein?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    stockExchange?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    stockSymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    verticalServed?: string;
    /**
     * Any other parameters
     * @type {{ [key: string]: string; }}
     * @memberof OrgDetailResponseDto
     */
    parameters?: { [key: string]: string; };
    /**
     * 
     * @type {Expiration}
     * @memberof OrgDetailResponseDto
     */
    expiration?: Expiration;
    /**
     * When LEI will be expired
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    nextRenewalDate?: string;
    /**
     * Possible state of org verification for LE vLEI Workflow.
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    qarVerified?: OrgDetailResponseDtoQarVerifiedEnum;
    /**
     * 
     * @type {string}
     * @memberof OrgDetailResponseDto
     */
    leiStatus?: string;
    /**
     * 
     * @type {RequesterInfoResponseDto}
     * @memberof OrgDetailResponseDto
     */
    requesterInfo?: RequesterInfoResponseDto;
    /**
     * 
     * @type {UserInOrgAndGrantResponseDto}
     * @memberof OrgDetailResponseDto
     */
    darInfo?: UserInOrgAndGrantResponseDto;
    /**
     * 
     * @type {Array<UserInOrgAndGrantResponseDto>}
     * @memberof OrgDetailResponseDto
     */
    larInfo?: Array<UserInOrgAndGrantResponseDto>;
    /**
     * 
     * @type {Array<UserInOrgAndGrantResponseDto>}
     * @memberof OrgDetailResponseDto
     */
    recipientInfo?: Array<UserInOrgAndGrantResponseDto>;
    /**
     * 
     * @type {Array<QarOrgDetailResponseDto>}
     * @memberof OrgDetailResponseDto
     */
    qarInfo?: Array<QarOrgDetailResponseDto>;
    /**
     * Is ceremony scheduled for the specified workflow
     * @type {boolean}
     * @memberof OrgDetailResponseDto
     */
    isCeremonyScheduled?: boolean;
}


/**
 * @export
 */
export const OrgDetailResponseDtoQarVerifiedEnum = {
    Init: 'INIT',
    OrgVerified: 'ORG_VERIFIED',
    RepresentativesVerified: 'REPRESENTATIVES_VERIFIED'
} as const;
export type OrgDetailResponseDtoQarVerifiedEnum = typeof OrgDetailResponseDtoQarVerifiedEnum[keyof typeof OrgDetailResponseDtoQarVerifiedEnum];


/**
 * Check if a given object implements the OrgDetailResponseDto interface.
 */
export function instanceOfOrgDetailResponseDto(value: object): boolean {
    return true;
}

export function OrgDetailResponseDtoFromJSON(json: any): OrgDetailResponseDto {
    return OrgDetailResponseDtoFromJSONTyped(json, false);
}

export function OrgDetailResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgDetailResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceStatus': json['workflowInstanceStatus'] == null ? undefined : WorkflowInstanceStatusFromJSON(json['workflowInstanceStatus']),
        'id': json['id'] == null ? undefined : json['id'],
        'workflowModelId': json['workflowModelId'] == null ? undefined : json['workflowModelId'],
        'name': json['name'] == null ? undefined : json['name'],
        'domain': json['domain'] == null ? undefined : json['domain'],
        'brandId': json['brandId'] == null ? undefined : json['brandId'],
        'createdByUser': json['createdByUser'] == null ? undefined : json['createdByUser'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'orgType': json['orgType'] == null ? undefined : json['orgType'],
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
        'legalCompanyName': json['legalCompanyName'] == null ? undefined : json['legalCompanyName'],
        'legalEntityName': json['legalEntityName'] == null ? undefined : json['legalEntityName'],
        'status': json['status'] == null ? undefined : OrganizationStatusFromJSON(json['status']),
        'lei': json['lei'] == null ? undefined : json['lei'],
        'dbaOrBrandName': json['dbaOrBrandName'] == null ? undefined : json['dbaOrBrandName'],
        'website': json['website'] == null ? undefined : json['website'],
        'altBusiness': json['altBusiness'] == null ? undefined : json['altBusiness'],
        'address': json['address'] == null ? undefined : json['address'],
        'country': json['country'] == null ? undefined : json['country'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'ein': json['ein'] == null ? undefined : json['ein'],
        'contactPhone': json['contactPhone'] == null ? undefined : json['contactPhone'],
        'contactEmail': json['contactEmail'] == null ? undefined : json['contactEmail'],
        'stockExchange': json['stockExchange'] == null ? undefined : json['stockExchange'],
        'stockSymbol': json['stockSymbol'] == null ? undefined : json['stockSymbol'],
        'verticalServed': json['verticalServed'] == null ? undefined : json['verticalServed'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
        'expiration': json['expiration'] == null ? undefined : ExpirationFromJSON(json['expiration']),
        'nextRenewalDate': json['nextRenewalDate'] == null ? undefined : json['nextRenewalDate'],
        'qarVerified': json['qarVerified'] == null ? undefined : json['qarVerified'],
        'leiStatus': json['leiStatus'] == null ? undefined : json['leiStatus'],
        'requesterInfo': json['requesterInfo'] == null ? undefined : RequesterInfoResponseDtoFromJSON(json['requesterInfo']),
        'darInfo': json['darInfo'] == null ? undefined : UserInOrgAndGrantResponseDtoFromJSON(json['darInfo']),
        'larInfo': json['larInfo'] == null ? undefined : ((json['larInfo'] as Array<any>).map(UserInOrgAndGrantResponseDtoFromJSON)),
        'recipientInfo': json['recipientInfo'] == null ? undefined : ((json['recipientInfo'] as Array<any>).map(UserInOrgAndGrantResponseDtoFromJSON)),
        'qarInfo': json['qarInfo'] == null ? undefined : ((json['qarInfo'] as Array<any>).map(QarOrgDetailResponseDtoFromJSON)),
        'isCeremonyScheduled': json['isCeremonyScheduled'] == null ? undefined : json['isCeremonyScheduled'],
    };
}

export function OrgDetailResponseDtoToJSON(value?: OrgDetailResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceStatus': WorkflowInstanceStatusToJSON(value['workflowInstanceStatus']),
        'id': value['id'],
        'workflowModelId': value['workflowModelId'],
        'name': value['name'],
        'domain': value['domain'],
        'brandId': value['brandId'],
        'createdByUser': value['createdByUser'],
        'createdAt': value['createdAt'],
        'orgType': value['orgType'],
        'publicRecordLinks': value['publicRecordLinks'],
        'legalCompanyName': value['legalCompanyName'],
        'legalEntityName': value['legalEntityName'],
        'status': OrganizationStatusToJSON(value['status']),
        'lei': value['lei'],
        'dbaOrBrandName': value['dbaOrBrandName'],
        'website': value['website'],
        'altBusiness': value['altBusiness'],
        'address': value['address'],
        'country': value['country'],
        'postalCode': value['postalCode'],
        'city': value['city'],
        'state': value['state'],
        'ein': value['ein'],
        'contactPhone': value['contactPhone'],
        'contactEmail': value['contactEmail'],
        'stockExchange': value['stockExchange'],
        'stockSymbol': value['stockSymbol'],
        'verticalServed': value['verticalServed'],
        'parameters': value['parameters'],
        'expiration': ExpirationToJSON(value['expiration']),
        'nextRenewalDate': value['nextRenewalDate'],
        'qarVerified': value['qarVerified'],
        'leiStatus': value['leiStatus'],
        'requesterInfo': RequesterInfoResponseDtoToJSON(value['requesterInfo']),
        'darInfo': UserInOrgAndGrantResponseDtoToJSON(value['darInfo']),
        'larInfo': value['larInfo'] == null ? undefined : ((value['larInfo'] as Array<any>).map(UserInOrgAndGrantResponseDtoToJSON)),
        'recipientInfo': value['recipientInfo'] == null ? undefined : ((value['recipientInfo'] as Array<any>).map(UserInOrgAndGrantResponseDtoToJSON)),
        'qarInfo': value['qarInfo'] == null ? undefined : ((value['qarInfo'] as Array<any>).map(QarOrgDetailResponseDtoToJSON)),
        'isCeremonyScheduled': value['isCeremonyScheduled'],
    };
}

