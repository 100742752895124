/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CredentialDetailedDto } from './CredentialDetailedDto';
import {
    CredentialDetailedDtoFromJSON,
    CredentialDetailedDtoFromJSONTyped,
    CredentialDetailedDtoToJSON,
} from './CredentialDetailedDto';
import type { TNAllocationCredentialDetailedDto } from './TNAllocationCredentialDetailedDto';
import {
    TNAllocationCredentialDetailedDtoFromJSON,
    TNAllocationCredentialDetailedDtoFromJSONTyped,
    TNAllocationCredentialDetailedDtoToJSON,
} from './TNAllocationCredentialDetailedDto';

/**
 * 
 * @export
 * @interface DossierDetailsResponseDto
 */
export interface DossierDetailsResponseDto {
    /**
     * Self-Addressing Identifier. Primary credential id
     * @type {string}
     * @memberof DossierDetailsResponseDto
     */
    dossierSaid?: string;
    /**
     * 
     * @type {CredentialDetailedDto}
     * @memberof DossierDetailsResponseDto
     */
    vettingCred?: CredentialDetailedDto;
    /**
     * 
     * @type {CredentialDetailedDto}
     * @memberof DossierDetailsResponseDto
     */
    allocCred?: CredentialDetailedDto;
    /**
     * 
     * @type {TNAllocationCredentialDetailedDto}
     * @memberof DossierDetailsResponseDto
     */
    tnAllocCred?: TNAllocationCredentialDetailedDto;
    /**
     * 
     * @type {CredentialDetailedDto}
     * @memberof DossierDetailsResponseDto
     */
    delegatedSignerCred?: CredentialDetailedDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DossierDetailsResponseDto
     */
    credentialsProof?: { [key: string]: string; };
}


/**
 * @export
 */
export const DossierDetailsResponseDtoCredentialsProofEnum = {
    Valid: 'VALID',
    Expired: 'EXPIRED',
    Missing: 'MISSING',
    Revoked: 'REVOKED'
} as const;
export type DossierDetailsResponseDtoCredentialsProofEnum = typeof DossierDetailsResponseDtoCredentialsProofEnum[keyof typeof DossierDetailsResponseDtoCredentialsProofEnum];


/**
 * Check if a given object implements the DossierDetailsResponseDto interface.
 */
export function instanceOfDossierDetailsResponseDto(value: object): boolean {
    return true;
}

export function DossierDetailsResponseDtoFromJSON(json: any): DossierDetailsResponseDto {
    return DossierDetailsResponseDtoFromJSONTyped(json, false);
}

export function DossierDetailsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DossierDetailsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dossierSaid': json['dossierSaid'] == null ? undefined : json['dossierSaid'],
        'vettingCred': json['vettingCred'] == null ? undefined : CredentialDetailedDtoFromJSON(json['vettingCred']),
        'allocCred': json['allocCred'] == null ? undefined : CredentialDetailedDtoFromJSON(json['allocCred']),
        'tnAllocCred': json['tnAllocCred'] == null ? undefined : TNAllocationCredentialDetailedDtoFromJSON(json['tnAllocCred']),
        'delegatedSignerCred': json['delegatedSignerCred'] == null ? undefined : CredentialDetailedDtoFromJSON(json['delegatedSignerCred']),
        'credentialsProof': json['credentialsProof'] == null ? undefined : json['credentialsProof'],
    };
}

export function DossierDetailsResponseDtoToJSON(value?: DossierDetailsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dossierSaid': value['dossierSaid'],
        'vettingCred': CredentialDetailedDtoToJSON(value['vettingCred']),
        'allocCred': CredentialDetailedDtoToJSON(value['allocCred']),
        'tnAllocCred': TNAllocationCredentialDetailedDtoToJSON(value['tnAllocCred']),
        'delegatedSignerCred': CredentialDetailedDtoToJSON(value['delegatedSignerCred']),
        'credentialsProof': value['credentialsProof'],
    };
}

