/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CertificateChainItem } from './CertificateChainItem';
import {
    CertificateChainItemFromJSON,
    CertificateChainItemFromJSONTyped,
    CertificateChainItemToJSON,
} from './CertificateChainItem';

/**
 * Related telephone number allocation credential
 * @export
 * @interface TNAllocationCredentialDetailedDto
 */
export interface TNAllocationCredentialDetailedDto {
    /**
     * Self-Addressing Identifier. Primary credential id
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    said?: string;
    /**
     * 
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    type?: TNAllocationCredentialDetailedDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    status?: TNAllocationCredentialDetailedDtoStatusEnum;
    /**
     * Autonomic Identifier
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    issueeAid?: string;
    /**
     * Human-readable Identifier for AID
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    issueeAlias?: string;
    /**
     * AID of issuer - who created that credential
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    issuerAid?: string;
    /**
     * Human-readable Identifier for AID
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    issuerAlias?: string;
    /**
     * When the credential was issued in format yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXXXX
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    issuedAt?: string;
    /**
     * When the credential was revoked if it was revoked. Format yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXXXX
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    revokedAt?: string;
    /**
     * 
     * @type {Array<CertificateChainItem>}
     * @memberof TNAllocationCredentialDetailedDto
     */
    certificateChain?: Array<CertificateChainItem>;
    /**
     * Start of range of certified telephone numbers in E.164 format without '+'
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    rangeStart?: string;
    /**
     * End of range of certified telephone numbers in E.164 format without '+'
     * @type {string}
     * @memberof TNAllocationCredentialDetailedDto
     */
    rangeEnd?: string;
}


/**
 * @export
 */
export const TNAllocationCredentialDetailedDtoTypeEnum = {
    Unknown: 'UNKNOWN',
    Dossier: 'DOSSIER',
    Vetting: 'VETTING',
    Alloc: 'ALLOC',
    TnAlloc: 'TN_ALLOC',
    DelSig: 'DEL_SIG',
    Qvi: 'QVI',
    Root: 'ROOT'
} as const;
export type TNAllocationCredentialDetailedDtoTypeEnum = typeof TNAllocationCredentialDetailedDtoTypeEnum[keyof typeof TNAllocationCredentialDetailedDtoTypeEnum];

/**
 * @export
 */
export const TNAllocationCredentialDetailedDtoStatusEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED',
    Revoked: 'REVOKED'
} as const;
export type TNAllocationCredentialDetailedDtoStatusEnum = typeof TNAllocationCredentialDetailedDtoStatusEnum[keyof typeof TNAllocationCredentialDetailedDtoStatusEnum];


/**
 * Check if a given object implements the TNAllocationCredentialDetailedDto interface.
 */
export function instanceOfTNAllocationCredentialDetailedDto(value: object): boolean {
    return true;
}

export function TNAllocationCredentialDetailedDtoFromJSON(json: any): TNAllocationCredentialDetailedDto {
    return TNAllocationCredentialDetailedDtoFromJSONTyped(json, false);
}

export function TNAllocationCredentialDetailedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TNAllocationCredentialDetailedDto {
    if (json == null) {
        return json;
    }
    return {
        
        'said': json['said'] == null ? undefined : json['said'],
        'type': json['type'] == null ? undefined : json['type'],
        'status': json['status'] == null ? undefined : json['status'],
        'issueeAid': json['issueeAid'] == null ? undefined : json['issueeAid'],
        'issueeAlias': json['issueeAlias'] == null ? undefined : json['issueeAlias'],
        'issuerAid': json['issuerAid'] == null ? undefined : json['issuerAid'],
        'issuerAlias': json['issuerAlias'] == null ? undefined : json['issuerAlias'],
        'issuedAt': json['issuedAt'] == null ? undefined : json['issuedAt'],
        'revokedAt': json['revokedAt'] == null ? undefined : json['revokedAt'],
        'certificateChain': json['certificateChain'] == null ? undefined : ((json['certificateChain'] as Array<any>).map(CertificateChainItemFromJSON)),
        'rangeStart': json['rangeStart'] == null ? undefined : json['rangeStart'],
        'rangeEnd': json['rangeEnd'] == null ? undefined : json['rangeEnd'],
    };
}

export function TNAllocationCredentialDetailedDtoToJSON(value?: TNAllocationCredentialDetailedDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'said': value['said'],
        'type': value['type'],
        'status': value['status'],
        'issueeAid': value['issueeAid'],
        'issueeAlias': value['issueeAlias'],
        'issuerAid': value['issuerAid'],
        'issuerAlias': value['issuerAlias'],
        'issuedAt': value['issuedAt'],
        'revokedAt': value['revokedAt'],
        'certificateChain': value['certificateChain'] == null ? undefined : ((value['certificateChain'] as Array<any>).map(CertificateChainItemToJSON)),
        'rangeStart': value['rangeStart'],
        'rangeEnd': value['rangeEnd'],
    };
}

