/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationDto } from './OrganizationDto';
import {
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';

/**
 * 
 * @export
 * @interface SuccessResponseOrganizationDto
 */
export interface SuccessResponseOrganizationDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseOrganizationDto
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseOrganizationDto
     */
    timeStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseOrganizationDto
     */
    message?: SuccessResponseOrganizationDtoMessageEnum;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof SuccessResponseOrganizationDto
     */
    data?: OrganizationDto;
}


/**
 * @export
 */
export const SuccessResponseOrganizationDtoMessageEnum = {
    UserSignedUp: 'USER_SIGNED_UP',
    UserConfirmed: 'USER_CONFIRMED',
    UserComplete: 'USER_COMPLETE',
    WalletSaved: 'WALLET_SAVED',
    AlreadyConfirmed: 'ALREADY_CONFIRMED',
    AlreadyOnboarded: 'ALREADY_ONBOARDED',
    ResentConfirmationCode: 'RESENT_CONFIRMATION_CODE',
    Ok: 'OK'
} as const;
export type SuccessResponseOrganizationDtoMessageEnum = typeof SuccessResponseOrganizationDtoMessageEnum[keyof typeof SuccessResponseOrganizationDtoMessageEnum];


/**
 * Check if a given object implements the SuccessResponseOrganizationDto interface.
 */
export function instanceOfSuccessResponseOrganizationDto(value: object): boolean {
    return true;
}

export function SuccessResponseOrganizationDtoFromJSON(json: any): SuccessResponseOrganizationDto {
    return SuccessResponseOrganizationDtoFromJSONTyped(json, false);
}

export function SuccessResponseOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponseOrganizationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'timeStamp': json['timeStamp'] == null ? undefined : json['timeStamp'],
        'message': json['message'] == null ? undefined : json['message'],
        'data': json['data'] == null ? undefined : OrganizationDtoFromJSON(json['data']),
    };
}

export function SuccessResponseOrganizationDtoToJSON(value?: SuccessResponseOrganizationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'timeStamp': value['timeStamp'],
        'message': value['message'],
        'data': OrganizationDtoToJSON(value['data']),
    };
}

