/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WorkflowModelDto,
  WorkflowTaskDto,
} from '../models/index';
import {
    WorkflowModelDtoFromJSON,
    WorkflowModelDtoToJSON,
    WorkflowTaskDtoFromJSON,
    WorkflowTaskDtoToJSON,
} from '../models/index';

export interface FindWorkflowByIdRequest {
    modelId: string;
}

export interface FindWorkflowsByModelIdRequest {
    modelId: string;
}

export interface GetTasksRequest {
    modelId: string;
}

/**
 * 
 */
export class WorkflowModelControllerApi extends runtime.BaseAPI {

    /**
     * Endpoint used to get a list of all workflows
     * Get all workflow models supported by origin platform
     */
    async findAllWorkflowsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowModelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-model`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowModelDtoFromJSON));
    }

    /**
     * Endpoint used to get a list of all workflows
     * Get all workflow models supported by origin platform
     */
    async findAllWorkflows(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowModelDto> | null | undefined > {
        const response = await this.findAllWorkflowsRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Endpoint used to get a specific workflow
     * Get workflow model by Id
     */
    async findWorkflowByIdRaw(requestParameters: FindWorkflowByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowModelDto>> {
        if (requestParameters['modelId'] == null) {
            throw new runtime.RequiredError(
                'modelId',
                'Required parameter "modelId" was null or undefined when calling findWorkflowById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-model/{model-id}`.replace(`{${"model-id"}}`, encodeURIComponent(String(requestParameters['modelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowModelDtoFromJSON(jsonValue));
    }

    /**
     * Endpoint used to get a specific workflow
     * Get workflow model by Id
     */
    async findWorkflowById(requestParameters: FindWorkflowByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowModelDto | null | undefined > {
        const response = await this.findWorkflowByIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get workflow models by model_Id for all versions
     */
    async findWorkflowsByModelIdRaw(requestParameters: FindWorkflowsByModelIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowModelDto>>> {
        if (requestParameters['modelId'] == null) {
            throw new runtime.RequiredError(
                'modelId',
                'Required parameter "modelId" was null or undefined when calling findWorkflowsByModelId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-model/list/{model-id}`.replace(`{${"model-id"}}`, encodeURIComponent(String(requestParameters['modelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowModelDtoFromJSON));
    }

    /**
     * Get workflow models by model_Id for all versions
     */
    async findWorkflowsByModelId(requestParameters: FindWorkflowsByModelIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowModelDto> | null | undefined > {
        const response = await this.findWorkflowsByModelIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get workflow model tasks
     */
    async getTasksRaw(requestParameters: GetTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowTaskDto>>> {
        if (requestParameters['modelId'] == null) {
            throw new runtime.RequiredError(
                'modelId',
                'Required parameter "modelId" was null or undefined when calling getTasks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-model/{model-id}/task`.replace(`{${"model-id"}}`, encodeURIComponent(String(requestParameters['modelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowTaskDtoFromJSON));
    }

    /**
     * Get workflow model tasks
     */
    async getTasks(requestParameters: GetTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowTaskDto> | null | undefined > {
        const response = await this.getTasksRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
