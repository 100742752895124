/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * State of the current workflow - is it in the progress or completed
 * @export
 */
export const WorkflowInstanceStatus = {
    Started: 'STARTED',
    Canceled: 'CANCELED',
    Terminated: 'TERMINATED',
    Completed: 'COMPLETED'
} as const;
export type WorkflowInstanceStatus = typeof WorkflowInstanceStatus[keyof typeof WorkflowInstanceStatus];


export function instanceOfWorkflowInstanceStatus(value: any): boolean {
    return Object.values(WorkflowInstanceStatus).includes(value);
}

export function WorkflowInstanceStatusFromJSON(json: any): WorkflowInstanceStatus {
    return WorkflowInstanceStatusFromJSONTyped(json, false);
}

export function WorkflowInstanceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowInstanceStatus {
    return json as WorkflowInstanceStatus;
}

export function WorkflowInstanceStatusToJSON(value?: WorkflowInstanceStatus | null): any {
    return value as any;
}

