/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalNamesDto
 */
export interface LegalNamesDto {
    /**
     * User Id
     * @type {string}
     * @memberof LegalNamesDto
     */
    userId: string;
    /**
     * Personal names returned by some legal service like ID Verse
     * @type {string}
     * @memberof LegalNamesDto
     */
    legalPersonalNames: string;
    /**
     * Family names returned by some legal service like ID Verse
     * @type {string}
     * @memberof LegalNamesDto
     */
    legalFamilyNames: string;
}

/**
 * Check if a given object implements the LegalNamesDto interface.
 */
export function instanceOfLegalNamesDto(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('legalPersonalNames' in value)) return false;
    if (!('legalFamilyNames' in value)) return false;
    return true;
}

export function LegalNamesDtoFromJSON(json: any): LegalNamesDto {
    return LegalNamesDtoFromJSONTyped(json, false);
}

export function LegalNamesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalNamesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'legalPersonalNames': json['legalPersonalNames'],
        'legalFamilyNames': json['legalFamilyNames'],
    };
}

export function LegalNamesDtoToJSON(value?: LegalNamesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'legalPersonalNames': value['legalPersonalNames'],
        'legalFamilyNames': value['legalFamilyNames'],
    };
}

