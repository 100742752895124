/* tslint:disable */
/* eslint-disable */
/**
 * Localization service API
 * Service for localization Origin messages
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface L10nTextResponseDto
 */
export interface L10nTextResponseDto {
    /**
     * 
     * @type {string}
     * @memberof L10nTextResponseDto
     */
    symbolicTextId?: string;
    /**
     * 
     * @type {string}
     * @memberof L10nTextResponseDto
     */
    lang?: string;
    /**
     * Localized value
     * @type {string}
     * @memberof L10nTextResponseDto
     */
    value?: string;
}

/**
 * Check if a given object implements the L10nTextResponseDto interface.
 */
export function instanceOfL10nTextResponseDto(value: object): boolean {
    return true;
}

export function L10nTextResponseDtoFromJSON(json: any): L10nTextResponseDto {
    return L10nTextResponseDtoFromJSONTyped(json, false);
}

export function L10nTextResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): L10nTextResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'symbolicTextId': json['symbolicTextId'] == null ? undefined : json['symbolicTextId'],
        'lang': json['lang'] == null ? undefined : json['lang'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function L10nTextResponseDtoToJSON(value?: L10nTextResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'symbolicTextId': value['symbolicTextId'],
        'lang': value['lang'],
        'value': value['value'],
    };
}

