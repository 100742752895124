/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrantDto
 */
export interface GrantDto {
    /**
     * User's role in the org
     * @type {string}
     * @memberof GrantDto
     */
    grant?: string;
    /**
     * User Id
     * @type {string}
     * @memberof GrantDto
     */
    userId?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof GrantDto
     */
    orgId?: string;
    /**
     * Grand can be accepted rejected or in progress (null value)
     * @type {boolean}
     * @memberof GrantDto
     */
    accepted?: boolean;
    /**
     * User Id
     * @type {string}
     * @memberof GrantDto
     */
    proposedByUser?: string;
    /**
     * User Id
     * @type {string}
     * @memberof GrantDto
     */
    approvedByUser?: string;
    /**
     * When the grant was proposed
     * @type {string}
     * @memberof GrantDto
     */
    proposedDate?: string;
}

/**
 * Check if a given object implements the GrantDto interface.
 */
export function instanceOfGrantDto(value: object): boolean {
    return true;
}

export function GrantDtoFromJSON(json: any): GrantDto {
    return GrantDtoFromJSONTyped(json, false);
}

export function GrantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrantDto {
    if (json == null) {
        return json;
    }
    return {
        
        'grant': json['grant'] == null ? undefined : json['grant'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'accepted': json['accepted'] == null ? undefined : json['accepted'],
        'proposedByUser': json['proposedByUser'] == null ? undefined : json['proposedByUser'],
        'approvedByUser': json['approvedByUser'] == null ? undefined : json['approvedByUser'],
        'proposedDate': json['proposedDate'] == null ? undefined : json['proposedDate'],
    };
}

export function GrantDtoToJSON(value?: GrantDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grant': value['grant'],
        'userId': value['userId'],
        'orgId': value['orgId'],
        'accepted': value['accepted'],
        'proposedByUser': value['proposedByUser'],
        'approvedByUser': value['approvedByUser'],
        'proposedDate': value['proposedDate'],
    };
}

