/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponseVoid
 */
export interface SuccessResponseVoid {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseVoid
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseVoid
     */
    timeStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseVoid
     */
    message?: SuccessResponseVoidMessageEnum;
    /**
     * 
     * @type {object}
     * @memberof SuccessResponseVoid
     */
    data?: object;
}


/**
 * @export
 */
export const SuccessResponseVoidMessageEnum = {
    UserSignedUp: 'USER_SIGNED_UP',
    UserConfirmed: 'USER_CONFIRMED',
    UserComplete: 'USER_COMPLETE',
    WalletSaved: 'WALLET_SAVED',
    AlreadyConfirmed: 'ALREADY_CONFIRMED',
    AlreadyOnboarded: 'ALREADY_ONBOARDED',
    ResentConfirmationCode: 'RESENT_CONFIRMATION_CODE',
    Ok: 'OK'
} as const;
export type SuccessResponseVoidMessageEnum = typeof SuccessResponseVoidMessageEnum[keyof typeof SuccessResponseVoidMessageEnum];


/**
 * Check if a given object implements the SuccessResponseVoid interface.
 */
export function instanceOfSuccessResponseVoid(value: object): boolean {
    return true;
}

export function SuccessResponseVoidFromJSON(json: any): SuccessResponseVoid {
    return SuccessResponseVoidFromJSONTyped(json, false);
}

export function SuccessResponseVoidFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponseVoid {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'timeStamp': json['timeStamp'] == null ? undefined : json['timeStamp'],
        'message': json['message'] == null ? undefined : json['message'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function SuccessResponseVoidToJSON(value?: SuccessResponseVoid | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'timeStamp': value['timeStamp'],
        'message': value['message'],
        'data': value['data'],
    };
}

