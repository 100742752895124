/* tslint:disable */
/* eslint-disable */
/**
 * Localization service API
 * Service for localization Origin messages
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SymbolicTextIdToLocalizedText } from './SymbolicTextIdToLocalizedText';
import {
    SymbolicTextIdToLocalizedTextFromJSON,
    SymbolicTextIdToLocalizedTextFromJSONTyped,
    SymbolicTextIdToLocalizedTextToJSON,
} from './SymbolicTextIdToLocalizedText';

/**
 * 
 * @export
 * @interface ListOfLocalizedTextsResponseDto
 */
export interface ListOfLocalizedTextsResponseDto {
    /**
     * 
     * @type {SymbolicTextIdToLocalizedText}
     * @memberof ListOfLocalizedTextsResponseDto
     */
    symbolicTextIdToLocalizedText?: SymbolicTextIdToLocalizedText;
    /**
     * 
     * @type {string}
     * @memberof ListOfLocalizedTextsResponseDto
     */
    lang?: string;
}

/**
 * Check if a given object implements the ListOfLocalizedTextsResponseDto interface.
 */
export function instanceOfListOfLocalizedTextsResponseDto(value: object): boolean {
    return true;
}

export function ListOfLocalizedTextsResponseDtoFromJSON(json: any): ListOfLocalizedTextsResponseDto {
    return ListOfLocalizedTextsResponseDtoFromJSONTyped(json, false);
}

export function ListOfLocalizedTextsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfLocalizedTextsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'symbolicTextIdToLocalizedText': json['symbolicTextIdToLocalizedText'] == null ? undefined : SymbolicTextIdToLocalizedTextFromJSON(json['symbolicTextIdToLocalizedText']),
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}

export function ListOfLocalizedTextsResponseDtoToJSON(value?: ListOfLocalizedTextsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'symbolicTextIdToLocalizedText': SymbolicTextIdToLocalizedTextToJSON(value['symbolicTextIdToLocalizedText']),
        'lang': value['lang'],
    };
}

