/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleResponseDto
 */
export interface RoleResponseDto {
    /**
     * User's role in the org
     * @type {string}
     * @memberof RoleResponseDto
     */
    role?: string;
    /**
     * User Id
     * @type {string}
     * @memberof RoleResponseDto
     */
    user?: string;
    /**
     * When the grant was proposed
     * @type {string}
     * @memberof RoleResponseDto
     */
    proposedDate?: string;
    /**
     * When the grant was approved
     * @type {string}
     * @memberof RoleResponseDto
     */
    grantDate?: string;
    /**
     * Grand can be accepted rejected or in progress (null value)
     * @type {boolean}
     * @memberof RoleResponseDto
     */
    accepted?: boolean;
    /**
     * User Id
     * @type {string}
     * @memberof RoleResponseDto
     */
    approvedBy?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof RoleResponseDto
     */
    orgId?: string;
}

/**
 * Check if a given object implements the RoleResponseDto interface.
 */
export function instanceOfRoleResponseDto(value: object): boolean {
    return true;
}

export function RoleResponseDtoFromJSON(json: any): RoleResponseDto {
    return RoleResponseDtoFromJSONTyped(json, false);
}

export function RoleResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'role': json['role'] == null ? undefined : json['role'],
        'user': json['user'] == null ? undefined : json['user'],
        'proposedDate': json['proposedDate'] == null ? undefined : json['proposedDate'],
        'grantDate': json['grantDate'] == null ? undefined : json['grantDate'],
        'accepted': json['accepted'] == null ? undefined : json['accepted'],
        'approvedBy': json['approvedBy'] == null ? undefined : json['approvedBy'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
    };
}

export function RoleResponseDtoToJSON(value?: RoleResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'role': value['role'],
        'user': value['user'],
        'proposedDate': value['proposedDate'],
        'grantDate': value['grantDate'],
        'accepted': value['accepted'],
        'approvedBy': value['approvedBy'],
        'orgId': value['orgId'],
    };
}

