/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CandidateAidCreatedRequest,
  CandidateResponseDto,
  CandidateRoleAcceptanceResponseDto,
  CommitteeCandidateElectionRequest,
  CommitteeParamsDto,
  InviteCandidateRequest,
  RegisterAcceptanceFromCandidateRequest,
  RegisterElectionRequest,
  RejectCandidateRequest,
  ResponseDto,
  StartCertificationRequest,
  StartCommitteeWorkflowRequestDto,
  SupervisorSigningRequest,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    CandidateAidCreatedRequestFromJSON,
    CandidateAidCreatedRequestToJSON,
    CandidateResponseDtoFromJSON,
    CandidateResponseDtoToJSON,
    CandidateRoleAcceptanceResponseDtoFromJSON,
    CandidateRoleAcceptanceResponseDtoToJSON,
    CommitteeCandidateElectionRequestFromJSON,
    CommitteeCandidateElectionRequestToJSON,
    CommitteeParamsDtoFromJSON,
    CommitteeParamsDtoToJSON,
    InviteCandidateRequestFromJSON,
    InviteCandidateRequestToJSON,
    RegisterAcceptanceFromCandidateRequestFromJSON,
    RegisterAcceptanceFromCandidateRequestToJSON,
    RegisterElectionRequestFromJSON,
    RegisterElectionRequestToJSON,
    RejectCandidateRequestFromJSON,
    RejectCandidateRequestToJSON,
    ResponseDtoFromJSON,
    ResponseDtoToJSON,
    StartCertificationRequestFromJSON,
    StartCertificationRequestToJSON,
    StartCommitteeWorkflowRequestDtoFromJSON,
    StartCommitteeWorkflowRequestDtoToJSON,
    SupervisorSigningRequestFromJSON,
    SupervisorSigningRequestToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface AcceptanceForCandidateRequest {
    encryptedParams: string;
}

export interface CancelInstance2Request {
    workflowInstanceId: string;
}

export interface CandidateAidCreatedOperationRequest {
    candidateAidCreatedRequest: CandidateAidCreatedRequest;
}

export interface CompleteInstance2Request {
    workflowInstanceId: string;
}

export interface ElectionRequest {
    committeeCandidateElectionRequest: CommitteeCandidateElectionRequest;
}

export interface GetCandidatesRequest {
    workflow?: string;
}

export interface GetCommitteeParamsRequest {
    workflow?: string;
}

export interface InviteCandidateOperationRequest {
    inviteCandidateRequest: InviteCandidateRequest;
}

export interface RegisterAcceptanceFromCandidateOperationRequest {
    registerAcceptanceFromCandidateRequest: RegisterAcceptanceFromCandidateRequest;
}

export interface RegisterElectionOperationRequest {
    registerElectionRequest: RegisterElectionRequest;
}

export interface RejectCandidateOperationRequest {
    rejectCandidateRequest: RejectCandidateRequest;
}

export interface Start5Request {
    startCommitteeWorkflowRequestDto: StartCommitteeWorkflowRequestDto;
}

export interface StartCertificationOperationRequest {
    startCertificationRequest: StartCertificationRequest;
}

export interface SupervisorSigningOperationRequest {
    supervisorSigningRequest: SupervisorSigningRequest;
}

/**
 * 
 */
export class CommitteeControllerApi extends runtime.BaseAPI {

    /**
     * Should be used without security, params are encrypted. Used in email link
     * Accept or reject from candidate
     */
    async acceptanceForCandidateRaw(requestParameters: AcceptanceForCandidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidateRoleAcceptanceResponseDto>> {
        if (requestParameters['encryptedParams'] == null) {
            throw new runtime.RequiredError(
                'encryptedParams',
                'Required parameter "encryptedParams" was null or undefined when calling acceptanceForCandidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/committee/acceptance/{encrypted-params}`.replace(`{${"encrypted-params"}}`, encodeURIComponent(String(requestParameters['encryptedParams']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateRoleAcceptanceResponseDtoFromJSON(jsonValue));
    }

    /**
     * Should be used without security, params are encrypted. Used in email link
     * Accept or reject from candidate
     */
    async acceptanceForCandidate(requestParameters: AcceptanceForCandidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidateRoleAcceptanceResponseDto> {
        const response = await this.acceptanceForCandidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel Committee workflow instance
     */
    async cancelInstance2Raw(requestParameters: CancelInstance2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling cancelInstance2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/committee/{workflow_instance_id}/cancel`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Cancel Committee workflow instance
     */
    async cancelInstance2(requestParameters: CancelInstance2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.cancelInstance2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Notify workflow and state machine that candidate aid was created
     * Notify BE that candidate aid was created
     */
    async candidateAidCreatedRaw(requestParameters: CandidateAidCreatedOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['candidateAidCreatedRequest'] == null) {
            throw new runtime.RequiredError(
                'candidateAidCreatedRequest',
                'Required parameter "candidateAidCreatedRequest" was null or undefined when calling candidateAidCreated().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/candidate-aid-created`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CandidateAidCreatedRequestToJSON(requestParameters['candidateAidCreatedRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Notify workflow and state machine that candidate aid was created
     * Notify BE that candidate aid was created
     */
    async candidateAidCreated(requestParameters: CandidateAidCreatedOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.candidateAidCreatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete Committee workflow instance
     */
    async completeInstance2Raw(requestParameters: CompleteInstance2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling completeInstance2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/committee/{workflow_instance_id}/completion`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Complete Committee workflow instance
     */
    async completeInstance2(requestParameters: CompleteInstance2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.completeInstance2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Called from UI to register supervisor vote
     * Vote action
     */
    async electionRaw(requestParameters: ElectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['committeeCandidateElectionRequest'] == null) {
            throw new runtime.RequiredError(
                'committeeCandidateElectionRequest',
                'Required parameter "committeeCandidateElectionRequest" was null or undefined when calling election().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/election`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommitteeCandidateElectionRequestToJSON(requestParameters['committeeCandidateElectionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Called from UI to register supervisor vote
     * Vote action
     */
    async election(requestParameters: ElectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.electionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of proposed candidates for the specified Committee Workflow
     */
    async getCandidatesRaw(requestParameters: GetCandidatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidateResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/committee/candidates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Return list of proposed candidates for the specified Committee Workflow
     */
    async getCandidates(requestParameters: GetCandidatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidateResponseDto> {
        const response = await this.getCandidatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return parameters of the specified Committee workflow
     */
    async getCommitteeParamsRaw(requestParameters: GetCommitteeParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommitteeParamsDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/committee/parameters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommitteeParamsDtoFromJSON(jsonValue));
    }

    /**
     * Return parameters of the specified Committee workflow
     */
    async getCommitteeParams(requestParameters: GetCommitteeParamsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommitteeParamsDto | null | undefined > {
        const response = await this.getCommitteeParamsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Called from State Machine service to invite candidate when needed
     * Send notification to invite a candidate
     */
    async inviteCandidateRaw(requestParameters: InviteCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['inviteCandidateRequest'] == null) {
            throw new runtime.RequiredError(
                'inviteCandidateRequest',
                'Required parameter "inviteCandidateRequest" was null or undefined when calling inviteCandidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/candidate/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteCandidateRequestToJSON(requestParameters['inviteCandidateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Called from State Machine service to invite candidate when needed
     * Send notification to invite a candidate
     */
    async inviteCandidate(requestParameters: InviteCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.inviteCandidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register acceptance from the candidate in workflow service. Called from State Machine service
     * Register candidate acceptance
     */
    async registerAcceptanceFromCandidateRaw(requestParameters: RegisterAcceptanceFromCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['registerAcceptanceFromCandidateRequest'] == null) {
            throw new runtime.RequiredError(
                'registerAcceptanceFromCandidateRequest',
                'Required parameter "registerAcceptanceFromCandidateRequest" was null or undefined when calling registerAcceptanceFromCandidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/register-acceptance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterAcceptanceFromCandidateRequestToJSON(requestParameters['registerAcceptanceFromCandidateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Register acceptance from the candidate in workflow service. Called from State Machine service
     * Register candidate acceptance
     */
    async registerAcceptanceFromCandidate(requestParameters: RegisterAcceptanceFromCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.registerAcceptanceFromCandidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Called from State Machine service to register the vote
     * Register vote action
     */
    async registerElectionRaw(requestParameters: RegisterElectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['registerElectionRequest'] == null) {
            throw new runtime.RequiredError(
                'registerElectionRequest',
                'Required parameter "registerElectionRequest" was null or undefined when calling registerElection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/register-election`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterElectionRequestToJSON(requestParameters['registerElectionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Called from State Machine service to register the vote
     * Register vote action
     */
    async registerElection(requestParameters: RegisterElectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.registerElectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rejects candidate due to workflow conditions. Called from State Machine service
     * Reject candidate
     */
    async rejectCandidateRaw(requestParameters: RejectCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['rejectCandidateRequest'] == null) {
            throw new runtime.RequiredError(
                'rejectCandidateRequest',
                'Required parameter "rejectCandidateRequest" was null or undefined when calling rejectCandidate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/reject-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectCandidateRequestToJSON(requestParameters['rejectCandidateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Rejects candidate due to workflow conditions. Called from State Machine service
     * Reject candidate
     */
    async rejectCandidate(requestParameters: RejectCandidateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.rejectCandidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a new Committee Workflow
     */
    async start5Raw(requestParameters: Start5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['startCommitteeWorkflowRequestDto'] == null) {
            throw new runtime.RequiredError(
                'startCommitteeWorkflowRequestDto',
                'Required parameter "startCommitteeWorkflowRequestDto" was null or undefined when calling start5().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartCommitteeWorkflowRequestDtoToJSON(requestParameters['startCommitteeWorkflowRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Start a new Committee Workflow
     */
    async start5(requestParameters: Start5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.start5Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update phase of the workflow: start to collect certifications. Called from State Machine service
     * Start certification
     */
    async startCertificationRaw(requestParameters: StartCertificationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['startCertificationRequest'] == null) {
            throw new runtime.RequiredError(
                'startCertificationRequest',
                'Required parameter "startCertificationRequest" was null or undefined when calling startCertification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/start-certification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartCertificationRequestToJSON(requestParameters['startCertificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update phase of the workflow: start to collect certifications. Called from State Machine service
     * Start certification
     */
    async startCertification(requestParameters: StartCertificationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.startCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supervisor certifies the list of candidates
     * Supervisor signing candidates
     */
    async supervisorSigningRaw(requestParameters: SupervisorSigningOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['supervisorSigningRequest'] == null) {
            throw new runtime.RequiredError(
                'supervisorSigningRequest',
                'Required parameter "supervisorSigningRequest" was null or undefined when calling supervisorSigning().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/committee/supervisor-signing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SupervisorSigningRequestToJSON(requestParameters['supervisorSigningRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Supervisor certifies the list of candidates
     * Supervisor signing candidates
     */
    async supervisorSigning(requestParameters: SupervisorSigningOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.supervisorSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
