/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateRoleProposalRequestDto,
  CreateRoleRequestDto,
  GrantDto,
  JobTitleDto,
  PendingRoleUpdateApprovalRequestDto,
  PendingRoleUpdateRequestDto,
  ProposalRoleResponseDto,
  RoleResponseDto,
  UserInfoDto,
} from '../models/index';
import {
    CreateRoleProposalRequestDtoFromJSON,
    CreateRoleProposalRequestDtoToJSON,
    CreateRoleRequestDtoFromJSON,
    CreateRoleRequestDtoToJSON,
    GrantDtoFromJSON,
    GrantDtoToJSON,
    JobTitleDtoFromJSON,
    JobTitleDtoToJSON,
    PendingRoleUpdateApprovalRequestDtoFromJSON,
    PendingRoleUpdateApprovalRequestDtoToJSON,
    PendingRoleUpdateRequestDtoFromJSON,
    PendingRoleUpdateRequestDtoToJSON,
    ProposalRoleResponseDtoFromJSON,
    ProposalRoleResponseDtoToJSON,
    RoleResponseDtoFromJSON,
    RoleResponseDtoToJSON,
    UserInfoDtoFromJSON,
    UserInfoDtoToJSON,
} from '../models/index';

export interface CreateGrantRequest {
    createRoleRequestDto: CreateRoleRequestDto;
}

export interface DeleteUserRoleRequest {
    userId?: string;
    role?: string;
    org?: string;
}

export interface ExistAcceptedOrInProgressByOrgIdAndGrantRequest {
    org?: string;
    role?: string;
}

export interface FindAllByOrgIdAndGrantAndAcceptedRequest {
    org?: string;
    role?: string;
}

export interface GetAllJobTitlesByTypeAndOrgWithStatusRequest {
    type: string;
    org?: string;
    status?: string;
}

export interface GetCompletedGrantsByUserIdAndOrgIdRequest {
    userId?: string;
    org?: string;
}

export interface GetJobTitleRequest {
    title: string;
    userId?: string;
    org?: string;
}

export interface GetJobTitlesByUserIdAndOrgIdRequest {
    userId?: string;
    org?: string;
}

export interface GetUserRolesByRoleAndUserIdRequest {
    role?: string;
    userId?: string;
}

export interface GetUserRolesInfoRequest {
    role?: string;
    userId?: string;
    org?: string;
}

export interface ProposeRoleRequest {
    createRoleProposalRequestDto: CreateRoleProposalRequestDto;
}

export interface RoleProposalRequest {
    userId?: string;
}

export interface UpdateRoleAcceptanceRequest {
    pendingRoleUpdateRequestDto: PendingRoleUpdateRequestDto;
}

export interface UpdateRoleApprovalRequest {
    pendingRoleUpdateApprovalRequestDto: PendingRoleUpdateApprovalRequestDto;
}

export interface UserInfo1Request {
    role?: string;
    org?: string;
}

export interface UserRoleCompletedRequest {
    userId?: string;
    role?: string;
    org?: string;
}

export interface UserRoleExistsRequest {
    userId?: string;
    role?: string;
    org?: string;
}

export interface UserRolesRequest {
    userId?: string;
    all?: boolean;
}

/**
 * 
 */
export class UserRoleControllerApi extends runtime.BaseAPI {

    /**
     * Creates new role
     * New role
     */
    async createGrantRaw(requestParameters: CreateGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createRoleRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createRoleRequestDto',
                'Required parameter "createRoleRequestDto" was null or undefined when calling createGrant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleRequestDtoToJSON(requestParameters['createRoleRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Creates new role
     * New role
     */
    async createGrant(requestParameters: CreateGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createGrantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes user\'s role
     * Delete role
     */
    async deleteUserRoleRaw(requestParameters: DeleteUserRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user\'s role
     * Delete role
     */
    async deleteUserRole(requestParameters: DeleteUserRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Returns true if role exists, accepted or in progress
     * Role accepted or in progress
     */
    async existAcceptedOrInProgressByOrgIdAndGrantRaw(requestParameters: ExistAcceptedOrInProgressByOrgIdAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<boolean>>> {
        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/existence/accepted-or-in-progress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns true if role exists, accepted or in progress
     * Role accepted or in progress
     */
    async existAcceptedOrInProgressByOrgIdAndGrant(requestParameters: ExistAcceptedOrInProgressByOrgIdAndGrantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<boolean>> {
        const response = await this.existAcceptedOrInProgressByOrgIdAndGrantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all users with accepted roles for the specified parameters
     * Accepted roles
     */
    async findAllByOrgIdAndGrantAndAcceptedRaw(requestParameters: FindAllByOrgIdAndGrantAndAcceptedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/acceptance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     * Returns all users with accepted roles for the specified parameters
     * Accepted roles
     */
    async findAllByOrgIdAndGrantAndAccepted(requestParameters: FindAllByOrgIdAndGrantAndAcceptedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.findAllByOrgIdAndGrantAndAcceptedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns Job titles for the specified status
     * Job titles with specified status
     */
    async getAllJobTitlesByTypeAndOrgWithStatusRaw(requestParameters: GetAllJobTitlesByTypeAndOrgWithStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProposalRoleResponseDto>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getAllJobTitlesByTypeAndOrgWithStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalRoleResponseDtoFromJSON));
    }

    /**
     * Returns Job titles for the specified status
     * Job titles with specified status
     */
    async getAllJobTitlesByTypeAndOrgWithStatus(requestParameters: GetAllJobTitlesByTypeAndOrgWithStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProposalRoleResponseDto>> {
        const response = await this.getAllJobTitlesByTypeAndOrgWithStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns completed grants for specified parameters
     * Completed roles
     */
    async getCompletedGrantsByUserIdAndOrgIdRaw(requestParameters: GetCompletedGrantsByUserIdAndOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/completed/list/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns completed grants for specified parameters
     * Completed roles
     */
    async getCompletedGrantsByUserIdAndOrgId(requestParameters: GetCompletedGrantsByUserIdAndOrgIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getCompletedGrantsByUserIdAndOrgIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns OOR job title for the user
     * Job Title
     */
    async getJobTitleRaw(requestParameters: GetJobTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobTitleDto>>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling getJobTitle().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/job-title/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobTitleDtoFromJSON));
    }

    /**
     * Returns OOR job title for the user
     * Job Title
     */
    async getJobTitle(requestParameters: GetJobTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobTitleDto>> {
        const response = await this.getJobTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns list of OOR job titles for the user
     * User job titles
     */
    async getJobTitlesByUserIdAndOrgIdRaw(requestParameters: GetJobTitlesByUserIdAndOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobTitleDto>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/job-title/list/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobTitleDtoFromJSON));
    }

    /**
     * Returns list of OOR job titles for the user
     * User job titles
     */
    async getJobTitlesByUserIdAndOrgId(requestParameters: GetJobTitlesByUserIdAndOrgIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobTitleDto>> {
        const response = await this.getJobTitlesByUserIdAndOrgIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * One user can have the same role in several orgs
     * Get role infos for role and user
     */
    async getUserRolesByRoleAndUserIdRaw(requestParameters: GetUserRolesByRoleAndUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/list/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     * One user can have the same role in several orgs
     * Get role infos for role and user
     */
    async getUserRolesByRoleAndUserId(requestParameters: GetUserRolesByRoleAndUserIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.getUserRolesByRoleAndUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find role info by role, user id and org
     * Get role info
     */
    async getUserRolesInfoRaw(requestParameters: GetUserRolesInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     * Find role info by role, user id and org
     * Get role info
     */
    async getUserRolesInfo(requestParameters: GetUserRolesInfoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.getUserRolesInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Proposes role for the user
     * Role proposal
     */
    async proposeRoleRaw(requestParameters: ProposeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['createRoleProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createRoleProposalRequestDto',
                'Required parameter "createRoleProposalRequestDto" was null or undefined when calling proposeRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleProposalRequestDtoToJSON(requestParameters['createRoleProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     * Proposes role for the user
     * Role proposal
     */
    async proposeRole(requestParameters: ProposeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.proposeRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all proposed roles for user
     * Get proposed roles
     */
    async roleProposalRaw(requestParameters: RoleProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProposalRoleResponseDto>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/proposal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalRoleResponseDtoFromJSON));
    }

    /**
     * Get all proposed roles for user
     * Get proposed roles
     */
    async roleProposal(requestParameters: RoleProposalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProposalRoleResponseDto>> {
        const response = await this.roleProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update role acceptance state
     * Role acceptance
     */
    async updateRoleAcceptanceRaw(requestParameters: UpdateRoleAcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['pendingRoleUpdateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'pendingRoleUpdateRequestDto',
                'Required parameter "pendingRoleUpdateRequestDto" was null or undefined when calling updateRoleAcceptance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/acceptance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PendingRoleUpdateRequestDtoToJSON(requestParameters['pendingRoleUpdateRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update role acceptance state
     * Role acceptance
     */
    async updateRoleAcceptance(requestParameters: UpdateRoleAcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.updateRoleAcceptanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update role approval state
     * Role approval
     */
    async updateRoleApprovalRaw(requestParameters: UpdateRoleApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['pendingRoleUpdateApprovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'pendingRoleUpdateApprovalRequestDto',
                'Required parameter "pendingRoleUpdateApprovalRequestDto" was null or undefined when calling updateRoleApproval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/approval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PendingRoleUpdateApprovalRequestDtoToJSON(requestParameters['pendingRoleUpdateApprovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     * Update role approval state
     * Role approval
     */
    async updateRoleApproval(requestParameters: UpdateRoleApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.updateRoleApprovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user infos by role for org
     */
    async userInfo1Raw(requestParameters: UserInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserInfoDto>>> {
        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/user/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserInfoDtoFromJSON));
    }

    /**
     * Get user infos by role for org
     */
    async userInfo1(requestParameters: UserInfo1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserInfoDto>> {
        const response = await this.userInfo1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return true if Grant is approved and accepted
     * Is role completed
     */
    async userRoleCompletedRaw(requestParameters: UserRoleCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/completed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Return true if Grant is approved and accepted
     * Is role completed
     */
    async userRoleCompleted(requestParameters: UserRoleCompletedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.userRoleCompletedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if specified role exists
     * User role exists
     */
    async userRoleExistsRaw(requestParameters: UserRoleExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/existence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Checks if specified role exists
     * User role exists
     */
    async userRoleExists(requestParameters: UserRoleExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.userRoleExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finds all user roles
     * User Roles
     */
    async userRolesRaw(requestParameters: UserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleResponseDto>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['all'] != null) {
            queryParameters['all'] = requestParameters['all'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/for-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseDtoFromJSON));
    }

    /**
     * Finds all user roles
     * User Roles
     */
    async userRoles(requestParameters: UserRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleResponseDto>> {
        const response = await this.userRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
