/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Candidate } from './Candidate';
import {
    CandidateFromJSON,
    CandidateFromJSONTyped,
    CandidateToJSON,
} from './Candidate';

/**
 * 
 * @export
 * @interface CandidateResponseDto
 */
export interface CandidateResponseDto {
    /**
     * 
     * @type {Array<Candidate>}
     * @memberof CandidateResponseDto
     */
    candidates?: Array<Candidate>;
}

/**
 * Check if a given object implements the CandidateResponseDto interface.
 */
export function instanceOfCandidateResponseDto(value: object): boolean {
    return true;
}

export function CandidateResponseDtoFromJSON(json: any): CandidateResponseDto {
    return CandidateResponseDtoFromJSONTyped(json, false);
}

export function CandidateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'candidates': json['candidates'] == null ? undefined : ((json['candidates'] as Array<any>).map(CandidateFromJSON)),
    };
}

export function CandidateResponseDtoToJSON(value?: CandidateResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'candidates': value['candidates'] == null ? undefined : ((value['candidates'] as Array<any>).map(CandidateToJSON)),
    };
}

