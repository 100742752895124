/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSlotDto } from './TimeSlotDto';
import {
    TimeSlotDtoFromJSON,
    TimeSlotDtoFromJSONTyped,
    TimeSlotDtoToJSON,
} from './TimeSlotDto';

/**
 * 
 * @export
 * @interface ScheduleCeremonyResponseDto
 */
export interface ScheduleCeremonyResponseDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof ScheduleCeremonyResponseDto
     */
    workflowInstanceId?: string;
    /**
     * Id of the scheduled meeting
     * @type {string}
     * @memberof ScheduleCeremonyResponseDto
     */
    meetingId?: string;
    /**
     * Title of the scheduled ceremony
     * @type {string}
     * @memberof ScheduleCeremonyResponseDto
     */
    title?: string;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof ScheduleCeremonyResponseDto
     */
    timeSlot?: TimeSlotDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleCeremonyResponseDto
     */
    participantIds?: Array<string>;
}

/**
 * Check if a given object implements the ScheduleCeremonyResponseDto interface.
 */
export function instanceOfScheduleCeremonyResponseDto(value: object): boolean {
    return true;
}

export function ScheduleCeremonyResponseDtoFromJSON(json: any): ScheduleCeremonyResponseDto {
    return ScheduleCeremonyResponseDtoFromJSONTyped(json, false);
}

export function ScheduleCeremonyResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleCeremonyResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'meetingId': json['meetingId'] == null ? undefined : json['meetingId'],
        'title': json['title'] == null ? undefined : json['title'],
        'timeSlot': json['timeSlot'] == null ? undefined : TimeSlotDtoFromJSON(json['timeSlot']),
        'participantIds': json['participantIds'] == null ? undefined : json['participantIds'],
    };
}

export function ScheduleCeremonyResponseDtoToJSON(value?: ScheduleCeremonyResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'meetingId': value['meetingId'],
        'title': value['title'],
        'timeSlot': TimeSlotDtoToJSON(value['timeSlot']),
        'participantIds': value['participantIds'],
    };
}

