/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPublicRecordsRequestDto,
  CancelCeremonyRequestDto,
  CommonResponseDto,
  OorPrerequisitesRequestDto,
  OorProposalRequestDto,
  OorProposalResponseDto,
  OorRemovalRequestDto,
  OorRemovalResponseDto,
  OorResentNotificationRequestDto,
  OorResentNotificationResponseDto,
  PossibleOorRecipientsResponseDto,
  QarAcceptsOrgRequestDto,
  QarOorVerificationRequestDto,
  ScheduleCeremonyResponseDto,
  ScheduleOorCeremonyRequestDto,
  UpdatePublicRecordsRequestDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    AddPublicRecordsRequestDtoFromJSON,
    AddPublicRecordsRequestDtoToJSON,
    CancelCeremonyRequestDtoFromJSON,
    CancelCeremonyRequestDtoToJSON,
    CommonResponseDtoFromJSON,
    CommonResponseDtoToJSON,
    OorPrerequisitesRequestDtoFromJSON,
    OorPrerequisitesRequestDtoToJSON,
    OorProposalRequestDtoFromJSON,
    OorProposalRequestDtoToJSON,
    OorProposalResponseDtoFromJSON,
    OorProposalResponseDtoToJSON,
    OorRemovalRequestDtoFromJSON,
    OorRemovalRequestDtoToJSON,
    OorRemovalResponseDtoFromJSON,
    OorRemovalResponseDtoToJSON,
    OorResentNotificationRequestDtoFromJSON,
    OorResentNotificationRequestDtoToJSON,
    OorResentNotificationResponseDtoFromJSON,
    OorResentNotificationResponseDtoToJSON,
    PossibleOorRecipientsResponseDtoFromJSON,
    PossibleOorRecipientsResponseDtoToJSON,
    QarAcceptsOrgRequestDtoFromJSON,
    QarAcceptsOrgRequestDtoToJSON,
    QarOorVerificationRequestDtoFromJSON,
    QarOorVerificationRequestDtoToJSON,
    ScheduleCeremonyResponseDtoFromJSON,
    ScheduleCeremonyResponseDtoToJSON,
    ScheduleOorCeremonyRequestDtoFromJSON,
    ScheduleOorCeremonyRequestDtoToJSON,
    UpdatePublicRecordsRequestDtoFromJSON,
    UpdatePublicRecordsRequestDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface AddPublicRecordsRequest {
    addPublicRecordsRequestDto: AddPublicRecordsRequestDto;
}

export interface CancelCeremonyRequest {
    cancelCeremonyRequestDto: CancelCeremonyRequestDto;
}

export interface FindCeremonyParticipantsRequest {
    workflow?: string;
}

export interface OorProposalRequest {
    oorProposalRequestDto: OorProposalRequestDto;
}

export interface OorRemovalRequest {
    oorRemovalRequestDto: OorRemovalRequestDto;
}

export interface ProposedOORsRequest {
    workflow?: string;
    meeting?: string;
}

export interface QarVerifiesOorRequest {
    qarOorVerificationRequestDto: QarOorVerificationRequestDto;
}

export interface QarVerifiesOrgRequest {
    qarAcceptsOrgRequestDto: QarAcceptsOrgRequestDto;
}

export interface ResendNotificationToOorRecipientRequest {
    oorResentNotificationRequestDto: OorResentNotificationRequestDto;
}

export interface ScheduleCeremonyRequest {
    scheduleOorCeremonyRequestDto: ScheduleOorCeremonyRequestDto;
}

export interface Start1Request {
    oorPrerequisitesRequestDto: OorPrerequisitesRequestDto;
}

export interface UpdatePublicRecordsRequest {
    updatePublicRecordsRequestDto: UpdatePublicRecordsRequestDto;
}

/**
 * 
 */
export class OORPrerequisitesControllerApi extends runtime.BaseAPI {

    /**
     * Adds more public records to describe organization better
     * Add public records
     */
    async addPublicRecordsRaw(requestParameters: AddPublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddPublicRecordsRequestDto>> {
        if (requestParameters['addPublicRecordsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'addPublicRecordsRequestDto',
                'Required parameter "addPublicRecordsRequestDto" was null or undefined when calling addPublicRecords().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/additional-public-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPublicRecordsRequestDtoToJSON(requestParameters['addPublicRecordsRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddPublicRecordsRequestDtoFromJSON(jsonValue));
    }

    /**
     * Adds more public records to describe organization better
     * Add public records
     */
    async addPublicRecords(requestParameters: AddPublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddPublicRecordsRequestDto> {
        const response = await this.addPublicRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel scheduled ceremony
     */
    async cancelCeremonyRaw(requestParameters: CancelCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommonResponseDto>> {
        if (requestParameters['cancelCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'cancelCeremonyRequestDto',
                'Required parameter "cancelCeremonyRequestDto" was null or undefined when calling cancelCeremony().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/cancel-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelCeremonyRequestDtoToJSON(requestParameters['cancelCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommonResponseDtoFromJSON(jsonValue));
    }

    /**
     * Cancel scheduled ceremony
     */
    async cancelCeremony(requestParameters: CancelCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommonResponseDto> {
        const response = await this.cancelCeremonyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The list includes requester, OORs and LARs. The list does not include QARs, because they depends on the chosen timeslot
     * Return list of possible recipients
     */
    async findCeremonyParticipantsRaw(requestParameters: FindCeremonyParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/ceremony-participants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * The list includes requester, OORs and LARs. The list does not include QARs, because they depends on the chosen timeslot
     * Return list of possible recipients
     */
    async findCeremonyParticipants(requestParameters: FindCeremonyParticipantsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.findCeremonyParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit list of recipients
     */
    async oorProposalRaw(requestParameters: OorProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorProposalResponseDto>> {
        if (requestParameters['oorProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorProposalRequestDto',
                'Required parameter "oorProposalRequestDto" was null or undefined when calling oorProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorProposalRequestDtoToJSON(requestParameters['oorProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorProposalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Submit list of recipients
     */
    async oorProposal(requestParameters: OorProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorProposalResponseDto> {
        const response = await this.oorProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove one of the recipients
     * Remove recipient
     */
    async oorRemovalRaw(requestParameters: OorRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorRemovalResponseDto>> {
        if (requestParameters['oorRemovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorRemovalRequestDto',
                'Required parameter "oorRemovalRequestDto" was null or undefined when calling oorRemoval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-removal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorRemovalRequestDtoToJSON(requestParameters['oorRemovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorRemovalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove one of the recipients
     * Remove recipient
     */
    async oorRemoval(requestParameters: OorRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorRemovalResponseDto> {
        const response = await this.oorRemovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of proposed OORs
     * Proposed OORs
     */
    async proposedOORsRaw(requestParameters: ProposedOORsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['meeting'] != null) {
            queryParameters['meeting'] = requestParameters['meeting'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/proposed-recipients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Return list of proposed OORs
     * Proposed OORs
     */
    async proposedOORs(requestParameters: ProposedOORsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.proposedOORsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR verifies OOR proposal (approve or reject)
     * OOR verification
     */
    async qarVerifiesOorRaw(requestParameters: QarVerifiesOorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QarOorVerificationRequestDto>> {
        if (requestParameters['qarOorVerificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarOorVerificationRequestDto',
                'Required parameter "qarOorVerificationRequestDto" was null or undefined when calling qarVerifiesOor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarOorVerificationRequestDtoToJSON(requestParameters['qarOorVerificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QarOorVerificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR verifies OOR proposal (approve or reject)
     * OOR verification
     */
    async qarVerifiesOor(requestParameters: QarVerifiesOorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QarOorVerificationRequestDto> {
        const response = await this.qarVerifiesOorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR verifies org
     * Org verification
     */
    async qarVerifiesOrgRaw(requestParameters: QarVerifiesOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QarOorVerificationRequestDto>> {
        if (requestParameters['qarAcceptsOrgRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarAcceptsOrgRequestDto',
                'Required parameter "qarAcceptsOrgRequestDto" was null or undefined when calling qarVerifiesOrg().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/org-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarAcceptsOrgRequestDtoToJSON(requestParameters['qarAcceptsOrgRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QarOorVerificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR verifies org
     * Org verification
     */
    async qarVerifiesOrg(requestParameters: QarVerifiesOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QarOorVerificationRequestDto> {
        const response = await this.qarVerifiesOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the actual notification to oor recipient. Notification depends on the recipient state in the Workflow
     * Resend notification
     */
    async resendNotificationToOorRecipientRaw(requestParameters: ResendNotificationToOorRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorResentNotificationResponseDto>> {
        if (requestParameters['oorResentNotificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorResentNotificationRequestDto',
                'Required parameter "oorResentNotificationRequestDto" was null or undefined when calling resendNotificationToOorRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/resent-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorResentNotificationRequestDtoToJSON(requestParameters['oorResentNotificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorResentNotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Resend the actual notification to oor recipient. Notification depends on the recipient state in the Workflow
     * Resend notification
     */
    async resendNotificationToOorRecipient(requestParameters: ResendNotificationToOorRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorResentNotificationResponseDto> {
        const response = await this.resendNotificationToOorRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Schedule OOR vLEI issuance ceremony as per selected time slot
     * Schedule meeting
     */
    async scheduleCeremonyRaw(requestParameters: ScheduleCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleCeremonyResponseDto>> {
        if (requestParameters['scheduleOorCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'scheduleOorCeremonyRequestDto',
                'Required parameter "scheduleOorCeremonyRequestDto" was null or undefined when calling scheduleCeremony().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/scheduling-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleOorCeremonyRequestDtoToJSON(requestParameters['scheduleOorCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleCeremonyResponseDtoFromJSON(jsonValue));
    }

    /**
     * Schedule OOR vLEI issuance ceremony as per selected time slot
     * Schedule meeting
     */
    async scheduleCeremony(requestParameters: ScheduleCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleCeremonyResponseDto> {
        const response = await this.scheduleCeremonyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and start a new OOR Prerequisites Workflow Instance
     */
    async start1Raw(requestParameters: Start1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['oorPrerequisitesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorPrerequisitesRequestDto',
                'Required parameter "oorPrerequisitesRequestDto" was null or undefined when calling start1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorPrerequisitesRequestDtoToJSON(requestParameters['oorPrerequisitesRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create and start a new OOR Prerequisites Workflow Instance
     */
    async start1(requestParameters: Start1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.start1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Replaces list of the org public records
     * Update public records
     */
    async updatePublicRecordsRaw(requestParameters: UpdatePublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePublicRecordsRequestDto>> {
        if (requestParameters['updatePublicRecordsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'updatePublicRecordsRequestDto',
                'Required parameter "updatePublicRecordsRequestDto" was null or undefined when calling updatePublicRecords().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/updated-public-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePublicRecordsRequestDtoToJSON(requestParameters['updatePublicRecordsRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePublicRecordsRequestDtoFromJSON(jsonValue));
    }

    /**
     * Replaces list of the org public records
     * Update public records
     */
    async updatePublicRecords(requestParameters: UpdatePublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePublicRecordsRequestDto> {
        const response = await this.updatePublicRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
