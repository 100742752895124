/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortingItem
 */
export interface SortingItem {
    /**
     * 
     * @type {string}
     * @memberof SortingItem
     */
    instanceParameter?: SortingItemInstanceParameterEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SortingItem
     */
    desc?: boolean;
}


/**
 * @export
 */
export const SortingItemInstanceParameterEnum = {
    WorkflowInstanceId: 'WORKFLOW_INSTANCE_ID',
    DateRequested: 'DATE_REQUESTED',
    InProgress: 'IN_PROGRESS',
    Credentials: 'CREDENTIALS',
    OrgId: 'ORG_ID',
    Requestor: 'REQUESTOR',
    Status: 'STATUS'
} as const;
export type SortingItemInstanceParameterEnum = typeof SortingItemInstanceParameterEnum[keyof typeof SortingItemInstanceParameterEnum];


/**
 * Check if a given object implements the SortingItem interface.
 */
export function instanceOfSortingItem(value: object): boolean {
    return true;
}

export function SortingItemFromJSON(json: any): SortingItem {
    return SortingItemFromJSONTyped(json, false);
}

export function SortingItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortingItem {
    if (json == null) {
        return json;
    }
    return {
        
        'instanceParameter': json['instanceParameter'] == null ? undefined : json['instanceParameter'],
        'desc': json['desc'] == null ? undefined : json['desc'],
    };
}

export function SortingItemToJSON(value?: SortingItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'instanceParameter': value['instanceParameter'],
        'desc': value['desc'],
    };
}

