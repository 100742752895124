/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowTaskActorStatus } from './WorkflowTaskActorStatus';
import {
    WorkflowTaskActorStatusFromJSON,
    WorkflowTaskActorStatusFromJSONTyped,
    WorkflowTaskActorStatusToJSON,
} from './WorkflowTaskActorStatus';

/**
 * 
 * @export
 * @interface CreateTaskActorDto
 */
export interface CreateTaskActorDto {
    /**
     * User Id
     * @type {string}
     * @memberof CreateTaskActorDto
     */
    userId?: string;
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof CreateTaskActorDto
     */
    workflowInstanceId?: string;
    /**
     * Task Type for Workflow participants. Set of task types is different for each workflow model
     * @type {string}
     * @memberof CreateTaskActorDto
     */
    taskType?: string;
    /**
     * 
     * @type {WorkflowTaskActorStatus}
     * @memberof CreateTaskActorDto
     */
    workflowTaskActorStatus?: WorkflowTaskActorStatus;
}

/**
 * Check if a given object implements the CreateTaskActorDto interface.
 */
export function instanceOfCreateTaskActorDto(value: object): boolean {
    return true;
}

export function CreateTaskActorDtoFromJSON(json: any): CreateTaskActorDto {
    return CreateTaskActorDtoFromJSONTyped(json, false);
}

export function CreateTaskActorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaskActorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
        'workflowTaskActorStatus': json['workflowTaskActorStatus'] == null ? undefined : WorkflowTaskActorStatusFromJSON(json['workflowTaskActorStatus']),
    };
}

export function CreateTaskActorDtoToJSON(value?: CreateTaskActorDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'taskType': value['taskType'],
        'workflowTaskActorStatus': WorkflowTaskActorStatusToJSON(value['workflowTaskActorStatus']),
    };
}

