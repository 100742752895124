/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetProfile
 */
export interface SetProfile {
    /**
     * User Id
     * @type {string}
     * @memberof SetProfile
     */
    id: string;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof SetProfile
     */
    firstName: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof SetProfile
     */
    lastName: string;
    /**
     * User's email.
     * @type {string}
     * @memberof SetProfile
     */
    email: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof SetProfile
     */
    role?: string;
}

/**
 * Check if a given object implements the SetProfile interface.
 */
export function instanceOfSetProfile(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('lastName' in value)) return false;
    if (!('email' in value)) return false;
    return true;
}

export function SetProfileFromJSON(json: any): SetProfile {
    return SetProfileFromJSONTyped(json, false);
}

export function SetProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function SetProfileToJSON(value?: SetProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'role': value['role'],
    };
}

