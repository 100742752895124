import {
  UserInOrgAndGrantResponseDto,
  WorkflowTaskActorStatus
} from '@/api/origin-workflow-svc'
import Pill from '@/components/base/Pill'
import Shield from '@/components/icons/Shield'
import constants from '@/config/constants'
import { difference, intersection, isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import tailwindConfig from 'tailwind.config'

export const QAR_READ_ONLY_LINK = '/apps/qvi-toolkit/qar/view'
export const QAR_VERIFICATION_LINK = '/apps/qvi-toolkit/qar/verification'

export const organizationLink = (workflowInstanceId: string) => {
  return `${QAR_VERIFICATION_LINK}?workflowInstanceId=${workflowInstanceId}`
}

export const readOnlyOrganizationLink = (workflowInstanceId: string) => {
  return `${QAR_READ_ONLY_LINK}?workflowInstanceId=${workflowInstanceId}`
}

export const readOnlyRequesterLink = (workflowInstanceId: string) => {
  return `${QAR_READ_ONLY_LINK}/requester?workflowInstanceId=${workflowInstanceId}`
}

export const representativesLink = (workflowInstanceId: string) => {
  return `${QAR_VERIFICATION_LINK}/representative?workflowInstanceId=${workflowInstanceId}`
}

export const readOnlyRepresentativesLink = (workflowInstanceId: string) => {
  return `${QAR_READ_ONLY_LINK}/representative?workflowInstanceId=${workflowInstanceId}`
}

export const recipientsLink = (workflowInstanceId: string) => {
  return `${QAR_VERIFICATION_LINK}/recipients?workflowInstanceId=${workflowInstanceId}`
}

export const readOnlyRecipientsLink = (workflowInstanceId: string) => {
  return `${QAR_READ_ONLY_LINK}/recipients?workflowInstanceId=${workflowInstanceId}`
}

export const readOnlyCeremonyLink = (workflowInstanceId: string) => {
  return `${QAR_READ_ONLY_LINK}/ceremony?workflowInstanceId=${workflowInstanceId}`
}

export const getWorkflowNameIntl = (workflowModelId: string) => {
  switch (workflowModelId) {
    case constants.WORKFLOW_MODEL_IDS.LE:
      return 'vLEI'
    case constants.WORKFLOW_MODEL_IDS.OOR:
      return 'oor'
    case constants.WORKFLOW_MODEL_IDS.ECR:
      return 'ecr'
    default:
      return 'workflow.name.unknown'
  }
}

export const prepareUserInfo = (
  data: UserInOrgAndGrantResponseDto,
  hideInfo: (keyof UserInOrgAndGrantResponseDto)[] = []
) => {
  const info = []
  if (isEmpty(data)) return info

  if (!hideInfo.includes('userName'))
    info.push({
      info: (
        <div className="grid grid-cols-5 gap-4 text-justify">
          <div className="col-span-2 text-neutralsAdditional800">
            <FormattedMessage id="form.workflow.gettingStarted.label.name" />
          </div>
          <div className="col-span-3 font-medium text-base">
            {data.userName}
          </div>
        </div>
      )
    })

  if (!hideInfo.includes('emails'))
    info.push({
      info: (
        <div className="grid grid-cols-5 gap-4 text-justify">
          <div className="col-span-2 text-neutralsAdditional800">
            <FormattedMessage id="input.defineRepresentatives.email" />
          </div>
          <div className="col-span-3 font-medium text-base">
            {data.emails.map((email, key) => (
              <span key={key} className="pr-2">
                {email}
              </span>
            ))}
          </div>
        </div>
      )
    })

  if (
    difference(hideInfo, ['idVerificationStatus', 'isAccepted']).length != 0
  ) {
    const hasHidableField =
      intersection(hideInfo, ['idVerificationStatus', 'isAccepted']).length > 0
    info.push({
      info: (
        <div
          className={`grid ${
            hasHidableField ? 'grid-cols-3' : 'grid-cols-6'
          } gap-4 text-justify`}
        >
          {!hideInfo.includes('idVerificationStatus') && (
            <div className="grid grid-cols-5 col-span-3 gap-4 text-justify border-r">
              <div className="col-span-2 text-neutralsAdditional800">
                <FormattedMessage id="vlei.heading.identityVerification" />
              </div>
              <div className="col-span-3 font-medium text-base">
                {data.idVerificationStatus ===
                WorkflowTaskActorStatus.Complete ? (
                  <Pill
                    type="success"
                    label={
                      <>
                        <Shield />
                        <FormattedMessage id="qar.verification.recipient.verified" />
                      </>
                    }
                  />
                ) : (
                  <Pill
                    type="rejected"
                    label={
                      <>
                        <Shield
                          color={tailwindConfig.theme.extend.colors.pressedRed}
                        />
                        <FormattedMessage id="qar.verification.recipient.unverified" />
                      </>
                    }
                  />
                )}
              </div>
            </div>
          )}

          {!hideInfo.includes('isAccepted') && (
            <div className="grid grid-cols-5 col-span-3 gap-4 text-justify">
              <div className="col-span-2 text-neutralsAdditional800">
                <FormattedMessage id="qar.verification.recipient.ceremonyRole" />
              </div>
              <div className="col-span-3 font-medium text-base">
                {data.isAccepted ? (
                  <Pill
                    type="success"
                    label={<FormattedMessage id="label.accepted" />}
                  />
                ) : (
                  <Pill
                    type="rejected"
                    label={<FormattedMessage id="label.notAccepted" />}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  if (!hideInfo.includes('orgName'))
    info.push({
      info: (
        <div className="grid grid-cols-5 gap-4 text-justify">
          <div className="col-span-2 text-neutralsAdditional800">
            <FormattedMessage id="qar.verification.recipient.orgName" />
          </div>
          <div className="col-span-3 font-medium text-base">{data.orgName}</div>
        </div>
      )
    })

  if (!hideInfo.includes('phoneNumbers'))
    info.push({
      info: (
        <div className="grid grid-cols-5 gap-4 text-justify">
          <div className="col-span-2 text-neutralsAdditional800">
            <FormattedMessage id="input.defineRepresentatives.phoneNumber" />
          </div>
          <div className="col-span-3 font-medium text-base">
            {data.phoneNumbers.map((phoneNumber, key) => (
              <span key={key} className="pr-2">
                {phoneNumber.value}
              </span>
            ))}
          </div>
        </div>
      )
    })

  if (!hideInfo.includes('grant'))
    info.push({
      info: (
        <div className="grid grid-cols-5 gap-4 text-justify">
          <div className="col-span-2 text-neutralsAdditional800">
            <FormattedMessage id="qar.verification.recipient.role" />
          </div>
          <div className="col-span-3 font-medium text-base">{data.grant}</div>
        </div>
      )
    })

  return info
}
