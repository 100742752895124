import { userRoleService, userService, utilService } from '@/utils/apis'

// we c
export async function getUserDetails() {
  return await userService.userInfo()
}

export async function getLarResponse(encryptedParams: string) {
  return await utilService.acceptanceForGrant({ encryptedParams })
}

export async function getUsersByRole(role: string) {
  return await userRoleService.userInfo1({ role })
}

export async function getUserJobTitles(userId: string, org: string) {
  return await userRoleService.getJobTitlesByUserIdAndOrgId({ org, userId })
}
