/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetAllUserIdsByOrgRequest {
    org?: string;
}

/**
 * 
 */
export class UserIdControllerApi extends runtime.BaseAPI {

    /**
     * Find all users for org
     */
    async getAllUserIdsByOrgRaw(requestParameters: GetAllUserIdsByOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user-id/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Find all users for org
     */
    async getAllUserIdsByOrg(requestParameters: GetAllUserIdsByOrgRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAllUserIdsByOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
