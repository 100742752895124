/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgDto
 */
export interface OrgDto {
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof OrgDto
     */
    id?: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof OrgDto
     */
    name?: string;
    /**
     * Org emails domain
     * @type {string}
     * @memberof OrgDto
     */
    domain?: string;
    /**
     * Org lei
     * @type {string}
     * @memberof OrgDto
     */
    lei?: string;
    /**
     * Org onboarding state
     * @type {string}
     * @memberof OrgDto
     */
    status?: string;
    /**
     * User Id
     * @type {string}
     * @memberof OrgDto
     */
    createdByUser?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof OrgDto
     */
    createdByUserName?: string;
}

/**
 * Check if a given object implements the OrgDto interface.
 */
export function instanceOfOrgDto(value: object): boolean {
    return true;
}

export function OrgDtoFromJSON(json: any): OrgDto {
    return OrgDtoFromJSONTyped(json, false);
}

export function OrgDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'domain': json['domain'] == null ? undefined : json['domain'],
        'lei': json['lei'] == null ? undefined : json['lei'],
        'status': json['status'] == null ? undefined : json['status'],
        'createdByUser': json['createdByUser'] == null ? undefined : json['createdByUser'],
        'createdByUserName': json['createdByUserName'] == null ? undefined : json['createdByUserName'],
    };
}

export function OrgDtoToJSON(value?: OrgDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'domain': value['domain'],
        'lei': value['lei'],
        'status': value['status'],
        'createdByUser': value['createdByUser'],
        'createdByUserName': value['createdByUserName'],
    };
}

