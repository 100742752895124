/* tslint:disable */
/* eslint-disable */
/**
 * Agent service API
 * Service for credentials management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AidDto,
  CreateNativeAidRequest,
  UpdateAidOobiRequest,
} from '../models/index';
import {
    AidDtoFromJSON,
    AidDtoToJSON,
    CreateNativeAidRequestFromJSON,
    CreateNativeAidRequestToJSON,
    UpdateAidOobiRequestFromJSON,
    UpdateAidOobiRequestToJSON,
} from '../models/index';

export interface FindAllNativeAidsRequest {
    userId?: string;
    orgId?: string;
    aidType?: string;
}

export interface FindForeignAidRequest {
    aid: string;
}

export interface FindNativeAidRequest {
    aid: string;
}

export interface SaveNativeAid1Request {
    createNativeAidRequest: CreateNativeAidRequest;
}

export interface SearchAidRequest {
    aid: string;
}

export interface UpdateNativeAidOobiRequest {
    aid: string;
    updateAidOobiRequest: UpdateAidOobiRequest;
}

/**
 * 
 */
export class AidControllerApi extends runtime.BaseAPI {

    /**
     * Get all native AIDs
     */
    async findAllNativeAidsRaw(requestParameters: FindAllNativeAidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AidDto>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user_id'] = requestParameters['userId'];
        }

        if (requestParameters['orgId'] != null) {
            queryParameters['org_id'] = requestParameters['orgId'];
        }

        if (requestParameters['aidType'] != null) {
            queryParameters['aidType'] = requestParameters['aidType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/aid/native`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AidDtoFromJSON));
    }

    /**
     * Get all native AIDs
     */
    async findAllNativeAids(requestParameters: FindAllNativeAidsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AidDto>> {
        const response = await this.findAllNativeAidsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get external AID detail
     */
    async findForeignAidRaw(requestParameters: FindForeignAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidDto>> {
        if (requestParameters['aid'] == null) {
            throw new runtime.RequiredError(
                'aid',
                'Required parameter "aid" was null or undefined when calling findForeignAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/aid/external/{aid}`.replace(`{${"aid"}}`, encodeURIComponent(String(requestParameters['aid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidDtoFromJSON(jsonValue));
    }

    /**
     * Get external AID detail
     */
    async findForeignAid(requestParameters: FindForeignAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidDto> {
        const response = await this.findForeignAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get native AID detail
     */
    async findNativeAidRaw(requestParameters: FindNativeAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidDto>> {
        if (requestParameters['aid'] == null) {
            throw new runtime.RequiredError(
                'aid',
                'Required parameter "aid" was null or undefined when calling findNativeAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/aid/native/{aid}`.replace(`{${"aid"}}`, encodeURIComponent(String(requestParameters['aid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidDtoFromJSON(jsonValue));
    }

    /**
     * Get native AID detail
     */
    async findNativeAid(requestParameters: FindNativeAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidDto> {
        const response = await this.findNativeAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save native AID
     */
    async saveNativeAid1Raw(requestParameters: SaveNativeAid1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidDto>> {
        if (requestParameters['createNativeAidRequest'] == null) {
            throw new runtime.RequiredError(
                'createNativeAidRequest',
                'Required parameter "createNativeAidRequest" was null or undefined when calling saveNativeAid1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/aid/native/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNativeAidRequestToJSON(requestParameters['createNativeAidRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidDtoFromJSON(jsonValue));
    }

    /**
     * Save native AID
     */
    async saveNativeAid1(requestParameters: SaveNativeAid1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidDto> {
        const response = await this.saveNativeAid1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search AID in both native and foreign AIDs
     */
    async searchAidRaw(requestParameters: SearchAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidDto>> {
        if (requestParameters['aid'] == null) {
            throw new runtime.RequiredError(
                'aid',
                'Required parameter "aid" was null or undefined when calling searchAid().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/agent/aid/search/{aid}`.replace(`{${"aid"}}`, encodeURIComponent(String(requestParameters['aid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidDtoFromJSON(jsonValue));
    }

    /**
     * Search AID in both native and foreign AIDs
     */
    async searchAid(requestParameters: SearchAidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidDto> {
        const response = await this.searchAidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update native AID OOBI
     */
    async updateNativeAidOobiRaw(requestParameters: UpdateNativeAidOobiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AidDto>> {
        if (requestParameters['aid'] == null) {
            throw new runtime.RequiredError(
                'aid',
                'Required parameter "aid" was null or undefined when calling updateNativeAidOobi().'
            );
        }

        if (requestParameters['updateAidOobiRequest'] == null) {
            throw new runtime.RequiredError(
                'updateAidOobiRequest',
                'Required parameter "updateAidOobiRequest" was null or undefined when calling updateNativeAidOobi().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/agent/aid/native/{aid}/oobi`.replace(`{${"aid"}}`, encodeURIComponent(String(requestParameters['aid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAidOobiRequestToJSON(requestParameters['updateAidOobiRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AidDtoFromJSON(jsonValue));
    }

    /**
     * Update native AID OOBI
     */
    async updateNativeAidOobi(requestParameters: UpdateNativeAidOobiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AidDto> {
        const response = await this.updateNativeAidOobiRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
