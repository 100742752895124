/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttendeeDto } from './AttendeeDto';
import {
    AttendeeDtoFromJSON,
    AttendeeDtoFromJSONTyped,
    AttendeeDtoToJSON,
} from './AttendeeDto';
import type { TimeSlotDto } from './TimeSlotDto';
import {
    TimeSlotDtoFromJSON,
    TimeSlotDtoFromJSONTyped,
    TimeSlotDtoToJSON,
} from './TimeSlotDto';

/**
 * 
 * @export
 * @interface FindScheduledMeetingResponseDto
 */
export interface FindScheduledMeetingResponseDto {
    /**
     * 
     * @type {Array<AttendeeDto>}
     * @memberof FindScheduledMeetingResponseDto
     */
    attendees?: Array<AttendeeDto>;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof FindScheduledMeetingResponseDto
     */
    ceremonyTime?: TimeSlotDto;
    /**
     * Title of the scheduled ceremony
     * @type {string}
     * @memberof FindScheduledMeetingResponseDto
     */
    title?: string;
    /**
     * Meeting URL of the scheduled ceremony
     * @type {string}
     * @memberof FindScheduledMeetingResponseDto
     */
    meetingUrl?: string;
}

/**
 * Check if a given object implements the FindScheduledMeetingResponseDto interface.
 */
export function instanceOfFindScheduledMeetingResponseDto(value: object): boolean {
    return true;
}

export function FindScheduledMeetingResponseDtoFromJSON(json: any): FindScheduledMeetingResponseDto {
    return FindScheduledMeetingResponseDtoFromJSONTyped(json, false);
}

export function FindScheduledMeetingResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindScheduledMeetingResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'attendees': json['attendees'] == null ? undefined : ((json['attendees'] as Array<any>).map(AttendeeDtoFromJSON)),
        'ceremonyTime': json['ceremonyTime'] == null ? undefined : TimeSlotDtoFromJSON(json['ceremonyTime']),
        'title': json['title'] == null ? undefined : json['title'],
        'meetingUrl': json['meetingUrl'] == null ? undefined : json['meetingUrl'],
    };
}

export function FindScheduledMeetingResponseDtoToJSON(value?: FindScheduledMeetingResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attendees': value['attendees'] == null ? undefined : ((value['attendees'] as Array<any>).map(AttendeeDtoToJSON)),
        'ceremonyTime': TimeSlotDtoToJSON(value['ceremonyTime']),
        'title': value['title'],
        'meetingUrl': value['meetingUrl'],
    };
}

