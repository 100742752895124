/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalPublicRecordsRequestDto
 */
export interface AdditionalPublicRecordsRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof AdditionalPublicRecordsRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalPublicRecordsRequestDto
     */
    publicRecordLinks?: Array<string>;
}

/**
 * Check if a given object implements the AdditionalPublicRecordsRequestDto interface.
 */
export function instanceOfAdditionalPublicRecordsRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    return true;
}

export function AdditionalPublicRecordsRequestDtoFromJSON(json: any): AdditionalPublicRecordsRequestDto {
    return AdditionalPublicRecordsRequestDtoFromJSONTyped(json, false);
}

export function AdditionalPublicRecordsRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalPublicRecordsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
    };
}

export function AdditionalPublicRecordsRequestDtoToJSON(value?: AdditionalPublicRecordsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'publicRecordLinks': value['publicRecordLinks'],
    };
}

