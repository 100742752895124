/* tslint:disable */
/* eslint-disable */
/**
 * Organization service API
 * Service for Organizations management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceProviderRequest
 */
export interface ServiceProviderRequest {
    /**
     * A time when the service provider did or will begin offering the service
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    startDate?: string;
    /**
     * A time when the service provider did or will stop offering the service
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    endDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProviderRequest
     */
    geos?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceProviderRequest
     */
    langs?: Array<string>;
    /**
     * Whether this org is the default provider of the specified service type in the specified cell
     * @type {boolean}
     * @memberof ServiceProviderRequest
     */
    isCellDefault?: boolean;
    /**
     * Cell name
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    cellName?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    orgId: string;
    /**
     * Brand which is in use by organization or service provider
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    brandId?: string;
    /**
     * Service provider type
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    serviceType: string;
    /**
     * Email for group notifications for the whole organization
     * @type {string}
     * @memberof ServiceProviderRequest
     */
    notificationEmail?: string;
}

/**
 * Check if a given object implements the ServiceProviderRequest interface.
 */
export function instanceOfServiceProviderRequest(value: object): boolean {
    if (!('orgId' in value)) return false;
    if (!('serviceType' in value)) return false;
    return true;
}

export function ServiceProviderRequestFromJSON(json: any): ServiceProviderRequest {
    return ServiceProviderRequestFromJSONTyped(json, false);
}

export function ServiceProviderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProviderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'geos': json['geos'] == null ? undefined : json['geos'],
        'langs': json['langs'] == null ? undefined : json['langs'],
        'isCellDefault': json['isCellDefault'] == null ? undefined : json['isCellDefault'],
        'cellName': json['cellName'] == null ? undefined : json['cellName'],
        'orgId': json['orgId'],
        'brandId': json['brandId'] == null ? undefined : json['brandId'],
        'serviceType': json['serviceType'],
        'notificationEmail': json['notificationEmail'] == null ? undefined : json['notificationEmail'],
    };
}

export function ServiceProviderRequestToJSON(value?: ServiceProviderRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'geos': value['geos'],
        'langs': value['langs'],
        'isCellDefault': value['isCellDefault'],
        'cellName': value['cellName'],
        'orgId': value['orgId'],
        'brandId': value['brandId'],
        'serviceType': value['serviceType'],
        'notificationEmail': value['notificationEmail'],
    };
}

