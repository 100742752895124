/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecipientDto
 */
export interface RecipientDto {
    /**
     * User Id
     * @type {string}
     * @memberof RecipientDto
     */
    userId?: string;
    /**
     * Personal names of a user. Usually it is a first name
     * @type {string}
     * @memberof RecipientDto
     */
    firstName?: string;
    /**
     * Family names of a user. Usually it is a last name
     * @type {string}
     * @memberof RecipientDto
     */
    lastName?: string;
    /**
     * User's email. Used to define users, send notifications and invite to onboard and take part in the Workflow
     * @type {string}
     * @memberof RecipientDto
     */
    email: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof RecipientDto
     */
    orgName?: string;
    /**
     * Full official name of the role
     * @type {string}
     * @memberof RecipientDto
     */
    oorTitle: string;
    /**
     * Short version of official name of the role
     * @type {string}
     * @memberof RecipientDto
     */
    shortTitle?: string;
    /**
     * Entity Legal Form code of the official role. List of roles is defined by GLEIF
     * @type {string}
     * @memberof RecipientDto
     */
    elfCode?: string;
}

/**
 * Check if a given object implements the RecipientDto interface.
 */
export function instanceOfRecipientDto(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('oorTitle' in value)) return false;
    return true;
}

export function RecipientDtoFromJSON(json: any): RecipientDto {
    return RecipientDtoFromJSONTyped(json, false);
}

export function RecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'],
        'orgName': json['orgName'] == null ? undefined : json['orgName'],
        'oorTitle': json['oorTitle'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'elfCode': json['elfCode'] == null ? undefined : json['elfCode'],
    };
}

export function RecipientDtoToJSON(value?: RecipientDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'orgName': value['orgName'],
        'oorTitle': value['oorTitle'],
        'shortTitle': value['shortTitle'],
        'elfCode': value['elfCode'],
    };
}

