/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskTypeResponseDto } from './TaskTypeResponseDto';
import {
    TaskTypeResponseDtoFromJSON,
    TaskTypeResponseDtoFromJSONTyped,
    TaskTypeResponseDtoToJSON,
} from './TaskTypeResponseDto';
import type { WorkflowInstanceStatus } from './WorkflowInstanceStatus';
import {
    WorkflowInstanceStatusFromJSON,
    WorkflowInstanceStatusFromJSONTyped,
    WorkflowInstanceStatusToJSON,
} from './WorkflowInstanceStatus';

/**
 * 
 * @export
 * @interface WorkflowInstanceDto
 */
export interface WorkflowInstanceDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    id?: string;
    /**
     * Workflow instance name. The same as workflow model id or its localized version
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    name?: string;
    /**
     * Name of workflow model
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    workflowModelId?: string;
    /**
     * Version of workflow model. There can be several versions for one model, all of them should be supported by system
     * @type {number}
     * @memberof WorkflowInstanceDto
     */
    workflowModelVersion?: number;
    /**
     * 
     * @type {WorkflowInstanceStatus}
     * @memberof WorkflowInstanceDto
     */
    status?: WorkflowInstanceStatus;
    /**
     * User Id
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    requesterId?: string;
    /**
     * Name of a user. Usually it is a personalNames + ' ' + familyNames
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    requesterName?: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    orgId?: string;
    /**
     * Name of an org
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    orgName?: string;
    /**
     * Additional workflow args like required number of QARs, number of issued credentials, etc
     * @type {{ [key: string]: string; }}
     * @memberof WorkflowInstanceDto
     */
    args?: { [key: string]: string; };
    /**
     * Date when the instance was started
     * @type {string}
     * @memberof WorkflowInstanceDto
     */
    startedAt?: string;
    /**
     * 
     * @type {Array<TaskTypeResponseDto>}
     * @memberof WorkflowInstanceDto
     */
    taskTypes?: Array<TaskTypeResponseDto>;
}

/**
 * Check if a given object implements the WorkflowInstanceDto interface.
 */
export function instanceOfWorkflowInstanceDto(value: object): boolean {
    return true;
}

export function WorkflowInstanceDtoFromJSON(json: any): WorkflowInstanceDto {
    return WorkflowInstanceDtoFromJSONTyped(json, false);
}

export function WorkflowInstanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowInstanceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'workflowModelId': json['workflowModelId'] == null ? undefined : json['workflowModelId'],
        'workflowModelVersion': json['workflowModelVersion'] == null ? undefined : json['workflowModelVersion'],
        'status': json['status'] == null ? undefined : WorkflowInstanceStatusFromJSON(json['status']),
        'requesterId': json['requesterId'] == null ? undefined : json['requesterId'],
        'requesterName': json['requesterName'] == null ? undefined : json['requesterName'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'orgName': json['orgName'] == null ? undefined : json['orgName'],
        'args': json['args'] == null ? undefined : json['args'],
        'startedAt': json['startedAt'] == null ? undefined : json['startedAt'],
        'taskTypes': json['taskTypes'] == null ? undefined : ((json['taskTypes'] as Array<any>).map(TaskTypeResponseDtoFromJSON)),
    };
}

export function WorkflowInstanceDtoToJSON(value?: WorkflowInstanceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'workflowModelId': value['workflowModelId'],
        'workflowModelVersion': value['workflowModelVersion'],
        'status': WorkflowInstanceStatusToJSON(value['status']),
        'requesterId': value['requesterId'],
        'requesterName': value['requesterName'],
        'orgId': value['orgId'],
        'orgName': value['orgName'],
        'args': value['args'],
        'startedAt': value['startedAt'],
        'taskTypes': value['taskTypes'] == null ? undefined : ((value['taskTypes'] as Array<any>).map(TaskTypeResponseDtoToJSON)),
    };
}

