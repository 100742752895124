/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRoleProposalRequestDto
 */
export interface CreateRoleProposalRequestDto {
    /**
     * User Id
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    userId: string;
    /**
     * User Id
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    proposedById: string;
    /**
     * User's role in the org
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    role: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    orgId: string;
    /**
     * Short version of official name of the role
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    shortTitle?: string;
    /**
     * Entity Legal Forme code of the official role. List of roles is defined by GLEIF
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    elfCode?: string;
    /**
     * oor or ecr type in case it is role of that types
     * @type {string}
     * @memberof CreateRoleProposalRequestDto
     */
    jobTitleType?: string;
}

/**
 * Check if a given object implements the CreateRoleProposalRequestDto interface.
 */
export function instanceOfCreateRoleProposalRequestDto(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('proposedById' in value)) return false;
    if (!('role' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function CreateRoleProposalRequestDtoFromJSON(json: any): CreateRoleProposalRequestDto {
    return CreateRoleProposalRequestDtoFromJSONTyped(json, false);
}

export function CreateRoleProposalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRoleProposalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'proposedById': json['proposedById'],
        'role': json['role'],
        'orgId': json['orgId'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'elfCode': json['elfCode'] == null ? undefined : json['elfCode'],
        'jobTitleType': json['jobTitleType'] == null ? undefined : json['jobTitleType'],
    };
}

export function CreateRoleProposalRequestDtoToJSON(value?: CreateRoleProposalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'proposedById': value['proposedById'],
        'role': value['role'],
        'orgId': value['orgId'],
        'shortTitle': value['shortTitle'],
        'elfCode': value['elfCode'],
        'jobTitleType': value['jobTitleType'],
    };
}

