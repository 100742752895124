/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OriginAidDto
 */
export interface OriginAidDto {
    /**
     * Personal names returned by some legal service like ID Verse
     * @type {string}
     * @memberof OriginAidDto
     */
    aid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OriginAidDto
     */
    oobi: Array<string>;
}

/**
 * Check if a given object implements the OriginAidDto interface.
 */
export function instanceOfOriginAidDto(value: object): boolean {
    if (!('aid' in value)) return false;
    if (!('oobi' in value)) return false;
    return true;
}

export function OriginAidDtoFromJSON(json: any): OriginAidDto {
    return OriginAidDtoFromJSONTyped(json, false);
}

export function OriginAidDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OriginAidDto {
    if (json == null) {
        return json;
    }
    return {
        
        'aid': json['aid'],
        'oobi': json['oobi'],
    };
}

export function OriginAidDtoToJSON(value?: OriginAidDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aid': value['aid'],
        'oobi': value['oobi'],
    };
}

