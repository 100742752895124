import {
  AddPublicRecordsRequest,
  type AddPublicRecordsRequestDto,
  FindCeremonyParticipantsRequest,
  FindScheduledMeetingRequest,
  OorPrerequisitesRequestDto,
  OorProposalRequest,
  type OorProposalRequestDto,
  OorRemovalRequest,
  type OorRemovalRequestDto,
  type OorResentNotificationRequestDto,
  ProposedOORsRequest,
  type QarOorVerificationRequestDto,
  QarVerifiesOorRequest,
  ResendNotificationToOorRecipientRequest,
  ScheduleCeremonyRequest,
  Start1Request,
  UpdatePublicRecordsRequest,
  type UpdatePublicRecordsRequestDto
} from '@/api/origin-workflow-svc'
import {
  commonWorkflowService,
  oorPrerequisitesService,
  oorTitleService
} from '@/utils/apis'

export async function startWorkflow(
  oorPrerequisitesRequestDto: OorPrerequisitesRequestDto
) {
  return await oorPrerequisitesService.start1({
    oorPrerequisitesRequestDto
  })
}

export async function proposeRecipients(
  oorProposalRequestDto: OorProposalRequestDto
) {
  return await oorPrerequisitesService.oorProposal({
    oorProposalRequestDto
  })
}

export async function getProposedOorRecipientsByWorkflowInstanceId(
  workflow: string
) {
  return await oorPrerequisitesService.proposedOORs({
    workflow
  })
}

export async function getProposedOorRecipientsByMeetingId(meeting: string) {
  return await oorPrerequisitesService.proposedOORs({
    meeting
  })
}

export async function addPublicRecords(
  addPublicRecordsRequestDto: AddPublicRecordsRequestDto
) {
  return await oorPrerequisitesService.addPublicRecords({
    addPublicRecordsRequestDto
  })
}

export async function qarApproveRecipients(
  qarOorVerificationRequestDto: QarOorVerificationRequestDto
) {
  return await oorPrerequisitesService.qarVerifiesOor({
    qarOorVerificationRequestDto
  })
}

export async function scheduleCeremony(
  scheduleCeremonyRequestDto: ScheduleCeremonyRequest
) {
  return oorPrerequisitesService.scheduleCeremony(scheduleCeremonyRequestDto)
}

export async function getCeremonyParticipants(workflow: string) {
  return await oorPrerequisitesService.findCeremonyParticipants({
    workflow
  })
}

export async function getCeremonyTime(workflow: string) {
  return commonWorkflowService.findScheduledMeeting({
    workflow
  })
}

export async function getOorTitles(country: string) {
  return await oorTitleService.findTitles({ country, elfCode: '0000' })
}

export async function updatePublicRecords(
  updatePublicRecordsRequestDto: UpdatePublicRecordsRequestDto
) {
  return oorPrerequisitesService.updatePublicRecords({
    updatePublicRecordsRequestDto
  })
}

export async function removeOORRecipient(
  oorRemovalRequestDto: OorRemovalRequestDto
) {
  return await oorPrerequisitesService.oorRemoval({
    oorRemovalRequestDto
  })
}

export async function resendOORInvitation(
  oorResentNotificationRequestDto: OorResentNotificationRequestDto
) {
  return await oorPrerequisitesService.resendNotificationToOorRecipient({
    oorResentNotificationRequestDto
  })
}
