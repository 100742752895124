/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecipientDto } from './RecipientDto';
import {
    RecipientDtoFromJSON,
    RecipientDtoFromJSONTyped,
    RecipientDtoToJSON,
} from './RecipientDto';

/**
 * 
 * @export
 * @interface OorProposalRequestDto
 */
export interface OorProposalRequestDto {
    /**
     * Created or processed Workflow instance Id
     * @type {string}
     * @memberof OorProposalRequestDto
     */
    workflowInstanceId: string;
    /**
     * Workflow task actor id that is used for this operation
     * @type {string}
     * @memberof OorProposalRequestDto
     */
    workflowInstanceAssignedTaskId: string;
    /**
     * Organization Id for whose benefit the workflow is performed or the user works
     * @type {string}
     * @memberof OorProposalRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {Array<RecipientDto>}
     * @memberof OorProposalRequestDto
     */
    proposedUsers: Array<RecipientDto>;
}

/**
 * Check if a given object implements the OorProposalRequestDto interface.
 */
export function instanceOfOorProposalRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('workflowInstanceAssignedTaskId' in value)) return false;
    if (!('orgId' in value)) return false;
    if (!('proposedUsers' in value)) return false;
    return true;
}

export function OorProposalRequestDtoFromJSON(json: any): OorProposalRequestDto {
    return OorProposalRequestDtoFromJSONTyped(json, false);
}

export function OorProposalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorProposalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'proposedUsers': ((json['proposedUsers'] as Array<any>).map(RecipientDtoFromJSON)),
    };
}

export function OorProposalRequestDtoToJSON(value?: OorProposalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'proposedUsers': ((value['proposedUsers'] as Array<any>).map(RecipientDtoToJSON)),
    };
}

