/* tslint:disable */
/* eslint-disable */
/**
 * Workflow service API
 * Service for workflow management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Expiration
 */
export interface Expiration {
    /**
     * 
     * @type {string}
     * @memberof Expiration
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Expiration
     */
    reason?: string;
}

/**
 * Check if a given object implements the Expiration interface.
 */
export function instanceOfExpiration(value: object): boolean {
    return true;
}

export function ExpirationFromJSON(json: any): Expiration {
    return ExpirationFromJSONTyped(json, false);
}

export function ExpirationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Expiration {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : json['date'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function ExpirationToJSON(value?: Expiration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': value['date'],
        'reason': value['reason'],
    };
}

