/* tslint:disable */
/* eslint-disable */
/**
 * User service API
 * Service for user management
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AidResponseDto
 */
export interface AidResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AidResponseDto
     */
    aid?: string;
}

/**
 * Check if a given object implements the AidResponseDto interface.
 */
export function instanceOfAidResponseDto(value: object): boolean {
    return true;
}

export function AidResponseDtoFromJSON(json: any): AidResponseDto {
    return AidResponseDtoFromJSONTyped(json, false);
}

export function AidResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AidResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'aid': json['aid'] == null ? undefined : json['aid'],
    };
}

export function AidResponseDtoToJSON(value?: AidResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aid': value['aid'],
    };
}

